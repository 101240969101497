import { createReducer } from "reduxsauce";
import { DirectApplyTypes as Types, UserTypes } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.directApplyPrograms;

export const clearPrograms = (state, action) => ({
  ...state,
  data: [],
});

export const getProgramsFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getProgramsRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getProgramsSuccess = (state, action) => {
  const { programs, matched, total, params } = action;

  return {
    ...state,
    data:
      params.page_no === 1 ? programs : [...state.data, ...programs],
    matched,
    total,
    params: { ...state.params, ...params },
    loading: false,
  };
};

export const getProgramsListDownloadUrlFailure = (state, action) => ({ ...state, programDownloadUrl: null });
export const getProgramsListDownloadUrlRequest = (state, action) => ({ ...state, programDownloadUrl: null });
export const getProgramsListDownloadUrlSuccess = (state, action) => ({...state, programDownloadUrl: action.url });

const logout = (state, action) => INITIAL_STATE;

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.GET_PROGRAMS_FAILURE]: getProgramsFailure,
  [Types.GET_PROGRAMS_REQUEST]: getProgramsRequest,
  [Types.GET_PROGRAMS_SUCCESS]: getProgramsSuccess,

  [Types.GET_PROGRAMS_LIST_DOWNLOAD_URL_FAILURE]: getProgramsListDownloadUrlFailure,
  [Types.GET_PROGRAMS_LIST_DOWNLOAD_URL_REQUEST]: getProgramsListDownloadUrlRequest,
  [Types.GET_PROGRAMS_LIST_DOWNLOAD_URL_SUCCESS]: getProgramsListDownloadUrlSuccess,

  [Types.CLEAR_PROGRAMS]: clearPrograms,

  [UserTypes.LOGOUT]: logout,
};

export default createReducer(INITIAL_STATE, HANDLERS);
