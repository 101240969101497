import map from "lodash/map";
import { createReducer } from "reduxsauce";
import { WebinarTypes as Types } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.webinar;
export const clearWebinarEvents = (state, action) => ({
  ...state,
  data: [],
});
export const setRegistrationParams = (state, action) => {
  const { params } = action;

  return {
    ...state,
    webinarCreatePartner: params,
  };
};

export const setEventCategorySearchParams = (state, action) => {
  const { params } = action;

  return {
    ...state,
    categorySearchParams: params,
  };
};

export const sendEventResetPasswordMailFailure = (state, action) =>
  state;
export const sendEventResetPasswordMailRequest = (state, action) =>
  state;
export const sendEventResetPasswordMailSuccess = (state, action) =>
  state;

export const resetEventPasswordFailure = (state, action) => state;
export const resetEventPasswordRequest = (state, action) => state;
export const resetEventPasswordSuccess = (state, action) => state;

export const registerUserFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const registerUserRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const registerUserSuccess = (state, action) => ({
  ...state,
  loading: false,
});

export const updateWebinarUserFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const updateWebinarUserRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const updateWebinarUserSuccess = (state, action) => ({
  ...state,
  data: { ...state.data, ...action.user },
  loading: false,
});

export const updateWebinarUserPasswordFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const updateWebinarUserPasswordRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const updateWebinarUserPasswordSuccess = (state, action) => ({
  ...state,
  loading: false,
});

export const authenticationWebinarFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const authenticationWebinarRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const authenticationWebinarSuccess = (state, action) => {
  const { response } = action;

  return {
    ...state,
    data: response,
    authToken: response.authentication_token,
    loading: false,
  };
};

export const getWebinarListFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getWebinarListRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getWebinarListSuccess = (state, action) => {
  const { response } = action;
  return {
    ...state,
    data: response,
    loading: false,
  };
};

export const getTrendingEventsFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getTrendingEventsRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getTrendingEventsSuccess = (state, action) => {
  const { response } = action;
  return {
    ...state,
    trending: response,
    loading: false,
  };
};

export const clearTrendingEvents = (state, action) => ({
  ...state,
  trending: [],
});

export const getAllSessionsFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getAllSessionsRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getAllSessionsSuccess = (state, action) => {
  const { response } = action;
  return {
    ...state,
    sessions: response,
    loading: false,
  };
};

export const registerUserToWebinarEventsFailure = (
  state,
  action,
) => ({
  ...state,
  loading: false,
});
export const registerUserToWebinarEventsRequest = (
  state,
  action,
) => ({
  ...state,
  loading: true,
});
export const registerUserToWebinarEventsSuccess = (state, action) => {
  const { response } = action;
  const trending_hash = {};
  map(state.trending, (events, category) =>
    category.includes(response.webinat_category_title)
      ? (trending_hash[category] = map(events, event =>
          event.id === response.webinar_id
            ? { ...event, ...response }
            : event,
        ))
      : (trending_hash[category] = events),
  );
  return {
    ...state,
    trending: trending_hash,
    loading: false,
  };
};

export const registerAllSessionsUserToWebinarEventsFailure = (
  state,
  action,
) => ({
  ...state,
  loading: false,
});
export const registerAllSessionsUserToWebinarEventsRequest = (
  state,
  action,
) => ({
  ...state,
  loading: true,
});
export const registerAllSessionsUserToWebinarEventsSuccess = (
  state,
  action,
) => {
  const { response } = action;
  const trendingHash = {};
  const dataHash = [];
  map(state.sessions, (events, category) =>
    category === response.webinar_featured_type ||
    category === "RECOMMENDED SESSIONS"
      ? (trendingHash[category] = map(events, event =>
          event.id === response.webinar_id
            ? { ...event, ...response }
            : event,
        ))
      : (trendingHash[category] = events),
  );
  map(state.data, event =>
    event.id == response.webinar_id
      ? dataHash.push({ ...event, ...response })
      : dataHash.push(event),
  );

  return {
    ...state,
    sessions: trendingHash,
    data: dataHash,
    loading: false,
    eventProfile: {
      ...state.eventProfile,
      webinars: map(state.eventProfile?.webinars, session =>
        session.id === response.webinar_id
          ? { ...session, ...response }
          : session,
      ),
    },
  };
};

export const getWebinarEventsFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getWebinarEventsRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getWebinarEventsSuccess = (state, action) => {
  const {
    eventsData: { webinars },
    params,
  } = action;
  return {
    ...state,
    data: webinars,
    params: { ...state.params, ...params },
    loading: false,
  };
};

export const getEventsFiltersFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getEventsFiltersRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getEventsFiltersSuccess = (state, action) => {
  const { response } = action;
  return {
    ...state,
    filters: response,
    loading: false,
  };
};

export const getMySessionsFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getMySessionsRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getMySessionsSuccess = (state, action) => {
  const { response } = action;
  return {
    ...state,
    mySessions: response,
    loading: false,
  };
};

export const cancelMySessionFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const cancelMySessionRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const cancelMySessionSuccess = (state, action) => {
  const { response } = action;
  const sessionArray = [];
  map(state.mySessions["webinars"], session =>
    session["id"] != response["id"]
      ? sessionArray.push(session)
      : null,
  );
  state.mySessions["webinars"] = sessionArray;
  return {
    ...state,
    mySessions: state.mySessions,
    loading: false,
  };
};

export const getWebinarEventFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getWebinarEventRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getWebinarEventSuccess = (state, action) => {
  const { response } = action;
  return {
    ...state,
    eventProfile: response,
    loading: false,
  };
};

export const registerOnDemandVideoClickFailure = (state, action) => ({
  ...state,
});
export const registerOnDemandVideoClickRequest = (state, action) => ({
  ...state,
});
export const registerOnDemandVideoClickSuccess = (state, action) => ({
  ...state,
});

export const HANDLERS = {
  [Types.SET_REGISTRATION_PARAMS]: setRegistrationParams,
  [Types.SET_EVENT_CATEGORY_SEARCH_PARAMS]:
    setEventCategorySearchParams,

  [Types.REGISTER_USER_FAILURE]: registerUserFailure,
  [Types.REGISTER_USER_REQUEST]: registerUserRequest,
  [Types.REGISTER_USER_SUCCESS]: registerUserSuccess,

  [Types.AUTHENTICATION_WEBINAR_FAILURE]:
    authenticationWebinarFailure,
  [Types.AUTHENTICATION_WEBINAR_REQUEST]:
    authenticationWebinarRequest,
  [Types.AUTHENTICATION_WEBINAR_SUCCESS]:
    authenticationWebinarSuccess,

  [Types.UPDATE_WEBINAR_USER_FAILURE]: updateWebinarUserFailure,
  [Types.UPDATE_WEBINAR_USER_REQUEST]: updateWebinarUserRequest,
  [Types.UPDATE_WEBINAR_USER_SUCCESS]: updateWebinarUserSuccess,

  [Types.UPDATE_WEBINAR_USER_PASSWORD_FAILURE]:
    updateWebinarUserPasswordFailure,
  [Types.UPDATE_WEBINAR_USER_PASSWORD_REQUEST]:
    updateWebinarUserPasswordRequest,
  [Types.UPDATE_WEBINAR_USER_PASSWORD_SUCCESS]:
    updateWebinarUserPasswordSuccess,

  [Types.GET_WEBINAR_LIST_FAILURE]: getWebinarListFailure,
  [Types.GET_WEBINAR_LIST_REQUEST]: getWebinarListRequest,
  [Types.GET_WEBINAR_LIST_SUCCESS]: getWebinarListSuccess,

  [Types.GET_TRENDING_EVENTS_FAILURE]: getTrendingEventsFailure,
  [Types.GET_TRENDING_EVENTS_REQUEST]: getTrendingEventsRequest,
  [Types.GET_TRENDING_EVENTS_SUCCESS]: getTrendingEventsSuccess,

  [Types.CLEAR_TRENDING_EVENTS]: clearTrendingEvents,

  [Types.GET_ALL_SESSIONS_FAILURE]: getAllSessionsFailure,
  [Types.GET_ALL_SESSIONS_REQUEST]: getAllSessionsRequest,
  [Types.GET_ALL_SESSIONS_SUCCESS]: getAllSessionsSuccess,

  [Types.GET_WEBINAR_EVENTS_FAILURE]: getWebinarEventsFailure,
  [Types.GET_WEBINAR_EVENTS_REQUEST]: getWebinarEventsRequest,
  [Types.GET_WEBINAR_EVENTS_SUCCESS]: getWebinarEventsSuccess,

  [Types.REGISTER_USER_TO_WEBINAR_EVENTS_FAILURE]:
    registerUserToWebinarEventsFailure,
  [Types.REGISTER_USER_TO_WEBINAR_EVENTS_REQUEST]:
    registerUserToWebinarEventsRequest,
  [Types.REGISTER_USER_TO_WEBINAR_EVENTS_SUCCESS]:
    registerUserToWebinarEventsSuccess,

  [Types.REGISTER_ALL_SESSIONS_USER_TO_WEBINAR_EVENTS_FAILURE]:
    registerAllSessionsUserToWebinarEventsFailure,
  [Types.REGISTER_ALL_SESSIONS_USER_TO_WEBINAR_EVENTS_REQUEST]:
    registerAllSessionsUserToWebinarEventsRequest,
  [Types.REGISTER_ALL_SESSIONS_USER_TO_WEBINAR_EVENTS_SUCCESS]:
    registerAllSessionsUserToWebinarEventsSuccess,

  [Types.SEND_EVENT_RESET_PASSWORD_MAIL_FAILURE]:
    sendEventResetPasswordMailFailure,
  [Types.SEND_EVENT_RESET_PASSWORD_MAIL_REQUEST]:
    sendEventResetPasswordMailRequest,
  [Types.SEND_EVENT_RESET_PASSWORD_MAIL_SUCCESS]:
    sendEventResetPasswordMailSuccess,

  [Types.RESET_EVENT_PASSWORD_FAILURE]: resetEventPasswordFailure,
  [Types.RESET_EVENT_PASSWORD_REQUEST]: resetEventPasswordRequest,
  [Types.RESET_EVENT_PASSWORD_SUCCESS]: resetEventPasswordSuccess,

  [Types.GET_EVENTS_FILTERS_FAILURE]: getEventsFiltersFailure,
  [Types.GET_EVENTS_FILTERS_REQUEST]: getEventsFiltersRequest,
  [Types.GET_EVENTS_FILTERS_SUCCESS]: getEventsFiltersSuccess,

  [Types.GET_MY_SESSIONS_FAILURE]: getMySessionsFailure,
  [Types.GET_MY_SESSIONS_REQUEST]: getMySessionsRequest,
  [Types.GET_MY_SESSIONS_SUCCESS]: getMySessionsSuccess,

  [Types.CANCEL_MY_SESSION_FAILURE]: cancelMySessionFailure,
  [Types.CANCEL_MY_SESSION_REQUEST]: cancelMySessionRequest,
  [Types.CANCEL_MY_SESSION_SUCCESS]: cancelMySessionSuccess,

  [Types.GET_WEBINAR_EVENT_FAILURE]: getWebinarEventFailure,
  [Types.GET_WEBINAR_EVENT_REQUEST]: getWebinarEventRequest,
  [Types.GET_WEBINAR_EVENT_SUCCESS]: getWebinarEventSuccess,

  [Types.REGISTER_ON_DEMAND_VIDEO_CLICK_FAILURE]:
    registerOnDemandVideoClickFailure,
  [Types.REGISTER_ON_DEMAND_VIDEO_CLICK_REQUEST]:
    registerOnDemandVideoClickRequest,
  [Types.REGISTER_ON_DEMAND_VIDEO_CLICK_SUCCESS]:
    registerOnDemandVideoClickSuccess,
};

export default createReducer(INITIAL_STATE, HANDLERS);
