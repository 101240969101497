/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-undef */
/* eslint-disable prefer-rest-params */
/* eslint-disable react/prop-types */
import React, { Suspense, Component, useEffect } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Switch,
  useLocation,
  Route,
} from "react-router-dom";
import Loader from "react-loader-spinner";
import { connect } from "react-redux";
import EventBus from "eventing-bus";
import { ROUTES } from "src/config/user-authorization";
import { BroadcastChannel } from "broadcast-channel";
import { Utility } from "src/helpers/utility";
import { bootIntercom } from "src/helpers/intercom";
import { ErrorBoundary } from "@appsignal/react";
import Appsignal from "@appsignal/javascript";
import Analytics from "src/helpers/analytics";
import RouteAuthWrapper from "src/routes/route-auth-wrapper";
import i18n from "./i18n";
import { MiscCreators } from "./redux/actions/misc.actions";
import { UserCreators } from "./redux/actions/user.actions";
import { ConnectionsCreators } from "./redux/actions/connections.actions";
import { SEGMENT_KEY, APP_SIGNAL_KEY } from "./config";
import { getCountryCode } from "./web-services/user";

const AuthenticatedRoutes = React.lazy(() =>
  import("./routes/authenticated-routes"),
);
const RouteWebinarWrapper = React.lazy(() =>
  import("./routes/route-webinars-wrapper"),
);
const RoutePublicWrapper = React.lazy(() =>
  import("./routes/route-public-wrapper"),
);
const EventCancelScreen = React.lazy(() =>
  import("./screens/event-cancel"),
);
const SSOScreen = React.lazy(() => import("./screens/sso"));

const appsignal = new Appsignal({
  key: APP_SIGNAL_KEY,
});

class App extends Component {
  constructor(props) {
    super(props);

    const { locale } = props;

    i18n.changeLanguage(locale);
  }

  componentWillMount() {
    this.sessionListener = EventBus.on(
      "session_expired",
      this.props.signOut,
    );

    if (
      !window.location.href.includes("/sso") &&
      this.props.authToken
    )
      this.props.getMiscData();

    document.body.classList.add(getOS());

    if (this.props.authToken) {
      const { user } = this.props;
      bootIntercom({ isAuthenticated: true, user });
      Analytics.shouldTrackUser(user);
    } else {
      bootIntercom({ isAuthenticated: false });
      this._getCountryCode();
    }
  }

  componentDidMount() {
    if (!this.channel) {
      this.channel = new BroadcastChannel("logout");
      this.channel.onmessage = msg => this.handleChannel(msg);
    }
    this.loadSegment();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;
    if (prevProps.locale !== this.props.locale)
      i18n.changeLanguage(this.props.locale);

    if (prevProps.authToken && !this.props.authToken) {
      window?.analytics?.reset();
      bootIntercom({ isAuthenticated: false });
      this._getCountryCode();
    } else if (!prevProps.authToken && this.props.authToken) {
      this.props.getMiscData();
      bootIntercom({ isAuthenticated: true, user });
      Analytics.shouldTrackUser(user);
    }
  }

  loadSegment = () => {
    window.addEventListener("load", function () {
      const segmentScript = document.getElementById("segment-script");

      if (segmentScript) {
        segmentScript.addEventListener("load", function () {
          if (window.analytics) {
            Analytics.load(SEGMENT_KEY);
          }
          if (this.props?.authToken) {
            const { user } = this.props;
            bootIntercom({ isAuthenticated: true, user });
          } else {
            bootIntercom({ isAuthenticated: false });
          }
        });
      }
    });
  };

  handleChannel = msg => {
    const { signOut } = this.props;
    if (msg === "logoutTabs" && document.hidden) {
      signOut();
    }
  };

  _getCountryCode = () => {
    const { updateAppLocale } = this.props;
    getCountryCode()
      .then(response => {
        updateAppLocale(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  render() {
    return (
      <ErrorBoundary instance={appsignal}>
        <Suspense
          fallback={
            <div className="flex justify-center items-center z-20 absolute inset-0">
              <Loader
                type="Oval"
                color="grey"
                height={50}
                width={50}
              />
            </div>
          }
        >
          <Router>
            <ModalSwitch {...this.props} />
          </Router>
        </Suspense>
      </ErrorBoundary>
    );
  }
}

function getOS() {
  const { userAgent } = window.navigator;
  const { platform } = window.navigator;
  const macosPlatforms = [
    "Macintosh",
    "MacIntel",
    "MacPPC",
    "Mac68K",
  ];
  const windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"];
  const iosPlatforms = ["iPhone", "iPad", "iPod"];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "macos";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "ios";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "windows";
  } else if (/Android/.test(userAgent)) {
    os = "android";
  } else if (!os && /Linux/.test(platform)) {
    os = "linux";
  }

  return os;
}

function ModalSwitch(props) {
  const location = useLocation();
  const background = location.state && location.state.background;
  const currentTab = location.state && location.state.currentTab;
  const includePastEvents =
    location.state && location.state.includePastEvents;

  const { authToken, partner } = props;
  const getNewConnectionCount = () => {
    const { getConnectionCounts } = props;
    const params = { user_type: "all" };
    return new Promise((resolve, reject) => {
      getConnectionCounts(params, resolve, reject);
    }).catch(e => {
      Utility.promiseCatch(e);
    });
  };

  useEffect(() => {
    const isLoggedInUni =
      authToken && partner && partner.institute_type === "University";
    if (location.pathname !== ROUTES.CONNECTIONS && isLoggedInUni) {
      getNewConnectionCount();
    }
  }, [location, partner, authToken]);

  return (
    <div className="h-full relative">
      <Switch location={background || location}>
        <Route exact path={ROUTES.SSO} component={SSOScreen} />
        <Route
          exact
          path={[
            "/",
            ROUTES.ZOOM,
            ROUTES.TUITION_FEE,
            ROUTES.PRIVACY_POLICY,
            ROUTES.COOKIES_POLICY,
            ROUTES.PAYMENT_DETAIL,
            ROUTES.MOBILE_PAYMENT_REDIRECT,
            ROUTES.SCHEDULE_APPOINTMENT,
            ROUTES.EMAIL_VALIDATED,
            ROUTES.SEARCH_UNIVERSITY,
            ROUTES.REGISTER_UNIVERSITY,
          ]}
          component={RoutePublicWrapper}
        />
        <Route
          exact
          path={[
            ROUTES.WEBINARS,
            ROUTES.WEBINARS_CATEGORY_DETAIL,
            ROUTES.WEBINARS_CATEGORY_SEARCH,
            ROUTES.WEBINARS_CATEGORY_EVENT_DETAIL,
            ROUTES.WEBINARS_FAQ,
            ROUTES.WEBINARS_ABOUT,
            ROUTES.WEBINARS_SEARCH_SCHOOL,
            ROUTES.WEBINARS_REGISTER,
          ]}
          component={RouteWebinarWrapper}
        />

        {authToken ? <AuthenticatedRoutes /> : null}

        <Route
          exact
          path={[
            ROUTES.SIGN_IN,
            ROUTES.FORGOT_PASSWORD,
            ROUTES.RESET_PASSWORD,
            ROUTES.WEBINARS_SIGN_IN,
            ROUTES.WEBINARS_FORGOT_PASSWORD,
            ROUTES.WEBINARS_RESET_PASSWORD,
          ]}
          component={RouteAuthWrapper}
        />
        <Redirect
          from={ROUTES.WEBINARS_USER_UPDATE}
          exact
          to={{ pathname: ROUTES.WEBINARS, search: location.search }}
        />
        <Redirect
          from={ROUTES.WEBINARS_MY_SESSIONS}
          exact
          to={{ pathname: ROUTES.WEBINARS, search: location.search }}
        />
        <Redirect
          from="*"
          to={{ pathname: ROUTES.SIGN_IN, search: location.search }}
        />
      </Switch>
      {background && (
        <Route path={ROUTES.CANCEL_EVENT}>
          <EventCancelScreen
            currentTab={currentTab}
            includePastEvents={includePastEvents}
          />
        </Route>
      )}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    authToken: state.user.authToken,
    user: state.user.data,
    partner: state.partner.data,
    locale: state.user.data
      ? state.user.data.locale
      : state.user.locale,
    hasPageLoadTransactionEnded:
      state.misc.hasPageLoadTransactionEnded,
  };
}

const mapDispatchToProps = {
  getMiscData: MiscCreators.getMiscDataRequest,
  signOut: () => UserCreators.logout(),
  updateAppLocale: () => UserCreators.updateAppLocale(),
  getConnectionCounts: ConnectionsCreators.hideCountsRequest,
  setHasPageLoadTransactionEnded:
    MiscCreators.setHasPageLoadTransactionEnded,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
