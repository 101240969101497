import { call, put, takeEvery, select } from "redux-saga/effects";
import { SessionsCreators, SessionsTypes } from "../actions";
import {
  getSessions,
  getSession,
  updateSession,
} from "src/web-services";
const authTokenSelector = state => state.user.authToken;

export function* watchSessionsRequests() {
  yield takeEvery(
    SessionsTypes.GET_SESSIONS_REQUEST,
    requestGetSessions,
  );
  yield takeEvery(
    SessionsTypes.GET_SESSION_REQUEST,
    requestGetSession,
  );
  yield takeEvery(
    SessionsTypes.UPDATE_SESSION_REQUEST,
    requestUpdateSession,
  );
}

function* requestGetSessions(action) {
  try {
    const { filter } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getSessions, authToken, filter);

    yield put(
      SessionsCreators.getSessionsSuccess(response.data.webinars),
    );
  } catch (error) {
    yield put(SessionsCreators.getSessionsFailure());
  }
}

function* requestGetSession(action) {
  try {
    const { id } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getSession, authToken, id);

    yield put(SessionsCreators.getSessionSuccess(response.data));
  } catch (error) {
    yield put(SessionsCreators.getSessionFailure());
  }
}

function* requestUpdateSession(action) {
  try {
    const { id, params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(updateSession, authToken, id, params);

    yield put(SessionsCreators.updateSessionSuccess(response.data));
  } catch (error) {
    yield put(SessionsCreators.updateSessionFailure());
  }
}
