import React from "react";
import { Switch } from "react-router-dom";
import { ROUTES } from "src/config/user-authorization";
import UnAuthenticated from "src/routes/un-authenticated";
import SignInScreen from "src/screens/sign-in";
import EventsSignInScreen from "src/screens/webinars/events-sign-in";

const ForgotPasswordScreen = React.lazy(() =>
  import("src/screens/forgot-password"),
);
const ResetPasswordScreen = React.lazy(() =>
  import("src/screens/reset-password"),
);
const EventsForgotPasswordScreen = React.lazy(() =>
  import("src/screens/webinars/events-forgot-password"),
);
const EventsResetPasswordScreen = React.lazy(() =>
  import("src/screens/webinars/events-reset-password"),
);

const RouteAuthWrapper = () => {
  return (
    <Switch>
      <UnAuthenticated
        exact
        path={ROUTES.SIGN_IN}
        component={SignInScreen}
      />
      <UnAuthenticated
        exact
        path={ROUTES.FORGOT_PASSWORD}
        component={ForgotPasswordScreen}
      />
      <UnAuthenticated
        exact
        path={ROUTES.RESET_PASSWORD}
        component={ResetPasswordScreen}
      />
      <UnAuthenticated
        exact
        path={ROUTES.WEBINARS_SIGN_IN}
        component={EventsSignInScreen}
      />
      <UnAuthenticated
        exact
        path={ROUTES.WEBINARS_FORGOT_PASSWORD}
        component={EventsForgotPasswordScreen}
      />
      <UnAuthenticated
        exact
        path={ROUTES.WEBINARS_RESET_PASSWORD}
        component={EventsResetPasswordScreen}
      />
    </Switch>
  );
};

export default RouteAuthWrapper;
