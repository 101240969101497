const core = new URLSearchParams(window.location.search).get("core");

export const SERVER_URL = process.env.REACT_APP_SERVER_URL;
export const APP_TOKEN = process.env.REACT_APP_APP_TOKEN;
export const TUITION_FEE_APP_TOKEN =
  process.env.REACT_APP_TUITION_FEE_APP_TOKEN;
export const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
export const PUBNUT_SUBSCRIBE_KEY =
  process.env.REACT_APP_PUBNUT_SUBSCRIBE_KEY;
export const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;
export const GOOGLE_CLIENT_ID =
  process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const SEGMENT_KEY =
  core === "true"
    ? process.env.REACT_APP_CORE_SEGMENT_KEY
    : process.env.REACT_APP_SEGMENT_KEY;
export const CIALFO_SUPPORT_EMAIL =
  process.env.REACT_APP_CIALFO_SUPPORT_EMAIL;
export const CONTENTFUL_TOKEN =
  process.env.REACT_APP_CONTENTFUL_TOKEN;
export const CONTENTFUL_SPACE =
  process.env.REACT_APP_CONTENTFUL_SPACE;
export const CONTENTFUL_ENVIRONMENT =
  process.env.REACT_APP_CONTENTFUL_ENVIRONMENT;
export const SISENSE_URL = process.env.REACT_APP_SISENSE_URL;
export const APP_SIGNAL_KEY = process.env.REACT_APP_SIGNAL_KEY;
