import { createReducer } from "reduxsauce";
import pull from "lodash/pull";
import { WebinarTypes, UserTypes } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.loadingState;

export const registerUserToWebinarEventsFailure = (
  state,
  action,
) => ({
  ...state,
  registeringSessionIds: [
    ...pull(state.registeringSessionIds, action.webinar_id),
  ],
});
export const registerUserToWebinarEventsRequest = (
  state,
  action,
) => ({
  ...state,
  registeringSessionIds: [
    ...state.registeringSessionIds,
    action.params.id,
  ],
});
export const registerUserToWebinarEventsSuccess = (state, action) => {
  return {
    ...state,
    registeringSessionIds: [
      ...pull(
        state.registeringSessionIds,
        action.response.webinar_id,
      ),
    ],
  };
};

export const registerAllSessionsUserToWebinarEventsFailure = (
  state,
  action,
) => ({
  ...state,
  registeringSessionIds: [
    ...pull(state.registeringSessionIds, action.webinarId),
  ],
});
export const registerAllSessionsUserToWebinarEventsRequest = (
  state,
  action,
) => ({
  ...state,
  registeringSessionIds: [
    ...state.registeringSessionIds,
    action.params.id,
  ],
});
export const registerAllSessionsUserToWebinarEventsSuccess = (
  state,
  action,
) => {
  return {
    ...state,
    registeringSessionIds: [
      ...pull(
        state.registeringSessionIds,
        action.response.webinar_id,
      ),
    ],
  };
};

const logout = (state, action) => INITIAL_STATE;

// map our action types to our reducer functions
export const HANDLERS = {
  [WebinarTypes.REGISTER_USER_TO_WEBINAR_EVENTS_FAILURE]:
    registerUserToWebinarEventsFailure,
  [WebinarTypes.REGISTER_USER_TO_WEBINAR_EVENTS_REQUEST]:
    registerUserToWebinarEventsRequest,
  [WebinarTypes.REGISTER_USER_TO_WEBINAR_EVENTS_SUCCESS]:
    registerUserToWebinarEventsSuccess,

  [WebinarTypes.REGISTER_ALL_SESSIONS_USER_TO_WEBINAR_EVENTS_FAILURE]:
    registerAllSessionsUserToWebinarEventsFailure,
  [WebinarTypes.REGISTER_ALL_SESSIONS_USER_TO_WEBINAR_EVENTS_REQUEST]:
    registerAllSessionsUserToWebinarEventsRequest,
  [WebinarTypes.REGISTER_ALL_SESSIONS_USER_TO_WEBINAR_EVENTS_SUCCESS]:
    registerAllSessionsUserToWebinarEventsSuccess,

  [UserTypes.LOGOUT]: logout,
};

export default createReducer(INITIAL_STATE, HANDLERS);
