import { createReducer } from "reduxsauce";
import { PartnerTypes as Types, UserTypes } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.partner;

export const getPartnerFailure = (state, action) => ({
  ...state,
  loadingPartner: false,
});
export const getPartnerRequest = (state, action) => ({
  ...state,
  loadingPartner: true,
});
export const getPartnerSuccess = (state, action) => ({
  ...state,
  data: action.partner,
  loadingPartner: false,
});

export const getAvailableCalendarsFailure = (state, action) => ({
  ...state,
});
export const getAvailableCalendarsRequest = (state, action) => ({
  ...state,
});
export const getAvailableCalendarsSuccess = (state, action) => ({
  ...state,
  availableCalendars: action.calendars,
});

export const getSyncedCalendarsFailure = (state, action) => ({
  ...state,
});
export const getSyncedCalendarsRequest = (state, action) => ({
  ...state,
});
export const getSyncedCalendarsSuccess = (state, action) => ({
  ...state,
  syncedCalendars: action.calendars,
});

export const sendInvitationFailure = (state, action) => ({
  ...state,
});
export const sendInvitationRequest = (state, action) => ({
  ...state,
});
export const sendInvitationSuccess = (state, action) => ({
  ...state,
});

export const syncCalendarsFailure = (state, action) => ({ ...state });
export const syncCalendarsRequest = (state, action) => ({ ...state });
export const syncCalendarsSuccess = (state, action) => ({
  ...state,
  syncedCalendars: action.calendars,
});

export const updatePartnerFailure = (state, action) => ({
  ...state,
  updatingPartner: false,
});
export const updatePartnerRequest = (state, action) => ({
  ...state,
  updatingPartner: true,
});
export const updatePartnerSuccess = (state, action) => ({
  ...state,
  data: action.partner,
  updatingPartner: false,
});

export const updatePartnerTimezoneFailure = (state, action) => ({
  ...state,
  updatingTimezone: false,
});
export const updatePartnerTimezoneRequest = (state, action) => ({
  ...state,
  updatingTimezone: true,
});
export const updatePartnerTimezoneSuccess = (state, action) => ({
  ...state,
  data: action.partner,
  updatingTimezone: false,
});

const logout = (state, action) => INITIAL_STATE;

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.GET_PARTNER_FAILURE]: getPartnerFailure,
  [Types.GET_PARTNER_REQUEST]: getPartnerRequest,
  [Types.GET_PARTNER_SUCCESS]: getPartnerSuccess,

  [Types.GET_AVAILABLE_CALENDARS_FAILURE]:
    getAvailableCalendarsFailure,
  [Types.GET_AVAILABLE_CALENDARS_REQUEST]:
    getAvailableCalendarsRequest,
  [Types.GET_AVAILABLE_CALENDARS_SUCCESS]:
    getAvailableCalendarsSuccess,

  [Types.GET_SYNCED_CALENDARS_FAILURE]: getSyncedCalendarsFailure,
  [Types.GET_SYNCED_CALENDARS_REQUEST]: getSyncedCalendarsRequest,
  [Types.GET_SYNCED_CALENDARS_SUCCESS]: getSyncedCalendarsSuccess,

  [Types.SEND_INVITATION_FAILURE]: sendInvitationFailure,
  [Types.SEND_INVITATION_REQUEST]: sendInvitationRequest,
  [Types.SEND_INVITATION_SUCCESS]: sendInvitationSuccess,

  [Types.SYNC_CALENDARS_FAILURE]: syncCalendarsFailure,
  [Types.SYNC_CALENDARS_REQUEST]: syncCalendarsRequest,
  [Types.SYNC_CALENDARS_SUCCESS]: syncCalendarsSuccess,

  [Types.UPDATE_PARTNER_FAILURE]: updatePartnerFailure,
  [Types.UPDATE_PARTNER_REQUEST]: updatePartnerRequest,
  [Types.UPDATE_PARTNER_SUCCESS]: updatePartnerSuccess,

  [Types.UPDATE_PARTNER_TIMEZONE_FAILURE]:
    updatePartnerTimezoneFailure,
  [Types.UPDATE_PARTNER_TIMEZONE_REQUEST]:
    updatePartnerTimezoneRequest,
  [Types.UPDATE_PARTNER_TIMEZONE_SUCCESS]:
    updatePartnerTimezoneSuccess,

  [UserTypes.LOGOUT]: logout,
};

export default createReducer(INITIAL_STATE, HANDLERS);
