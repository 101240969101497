import { API } from "../helpers";
import { SERVER_URL } from "../config";

export const registerWebinarUser = data => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/webinars/registrations`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data,
  });
};

export const webinarSignIn = data => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/webinars/sessions`,
    data: data,
  });
};

export const updateWebinarUser = (authToken, data) => {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/webinars/users/update_user`,
    headers: {
      "Content-Type": "multipart/form-data",
      authentication_token: authToken,
    },
    data: data,
  });
};

export const updateWebinarUserPassword = (authToken, data) => {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/webinars/users/update_password`,
    headers: {
      "Content-Type": "multipart/form-data",
      authentication_token: authToken,
    },
    data: data,
  });
};

export function getWebinarList(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/webinars/categories`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getPartnerWebinarList(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/webinars`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getTrengingEvents(params, authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/webinars/categories/trending_session_hash`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getAllSessions(id, authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/webinars/categories/${id}/feature_sessions?webinar_category_id=${id}`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getWebinarEvents(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/webinars/categories/${params["id"]}/events`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export const registerUserToWebinarEvents = (authToken, params) => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/webinars/events/${params["id"]}/register`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
};

export const sendEventResetPasswordMail = data => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/passwords/forgot_password`,
    data: data,
  });
};

export const resetEventPassword = data => {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/passwords/reset_password`,
    data: data,
  });
};

export function getEventsFilters(params, authToken) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/webinars/categories/filter_list`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getMySessions(params, authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/webinars/events/user_session`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function cancelMySession(params, authToken) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/webinars/events/${params["id"]}/cancel_registration`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getWebinarEvent(params, authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/webinars/categories/${params["category_id"]}/events/${params["event_id"]}`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function searchWebinarSchools(params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/webinars/schools`,
    params,
  });
}
