import React, { Component } from "react";
import Loader from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";

class Button extends Component {
  _activityIndicatorColor = () => {
    if (this.props.hollowBlue) {
      return "#007BE2";
    }

    return "#fff";
  };

  render() {
    const {
      text,
      isLoading,
      icon,
      hollowBlue,
      disabled,
      className = "",
      noBorder = false,
      ...rest
    } = this.props;
    const solidBlueButton = " bg-blue-700 text-white";
    const disabledSolidButton =
      " bg-gray-500 text-white cursor-not-allowed";
    const hollowBlueButton =
      " bg-white border border-blue-700 text-blue-700";
    let style =
      "text-sm font-bold py-2 px-2 rounded focus:outline-none cursor-pointer flex";
    const noBorderButton = "text-blue-700 text-sm font-bold";

    if (disabled) {
      style = style + disabledSolidButton;
    } else if (noBorder) {
      style = noBorderButton;
    } else if (hollowBlue) {
      style = style + hollowBlueButton;
    } else {
      style = style + solidBlueButton;
    }
    style = `${style} ${className}`;

    return (
      <button {...rest} className={style} disabled={disabled}>
        {isLoading ? (
          <Loader
            type="ThreeDots"
            color={this._activityIndicatorColor()}
            height={20}
            width={20}
          />
        ) : (
          <div className="flex flex-row items-center">
            {icon ? (
              <FontAwesomeIcon icon={icon} className="mr-2" />
            ) : null}

            {text}
          </div>
        )}
      </button>
    );
  }
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

export default Button;
