import moment from "moment";
import i18n from "i18next";

class CialfoEvents {
  static formatSessionTime(startTime, isLive) {
    let format = "";
    if (isLive) {
      format = `LIVE NOW hh:mm A`;
    } else {
      format = "MMM D, YYYY [AT] hh:mm A";
    }
    return moment.parseZone(startTime).format(format).toUpperCase();
  }

  static isSessionLive(session) {
    return moment().isBetween(
      moment(session.start_time),
      moment(session.end_time),
    );
  }

  static isSessionPast(session) {
    return moment().isAfter(moment(session.end_time));
  }

  static isSessionUpcoming(session) {
    return moment().isBefore(moment(session.start_time));
  }

  static formatEventDateRange(event) {
    const isOneDay = moment
      .parseZone(event.start_time)
      .isSame(event.end_time, "day");

    if (isOneDay) {
      return moment.parseZone(event.start_time).format("MMM D, YYYY");
    }

    return `${moment
      .parseZone(event.start_time)
      .format("MMM D")
      .toUpperCase()} - ${moment
      .parseZone(event.end_time)
      .format("MMM D, YYYY")
      .toUpperCase()}`;
  }

  static getActionButtonText(session, user, isTable) {
    const parseTime = user ? moment.parseZone : moment;
    const today = parseTime();
    const startTime = parseTime(session.start_time);
    const endTime = parseTime(session.end_time);
    const recordingAvailable = parseTime(session.end_time).add(
      7,
      "days",
    );

    if (
      today.isBetween(parseTime(session.end_time), recordingAvailable)
    ) {
      return i18n.t("Watch Now");
    }

    if (today.isBetween(startTime, endTime)) {
      return i18n.t("Join Now");
    }

    if (
      user &&
      session.is_attending &&
      !today.isBetween(startTime, endTime) &&
      !today.isAfter(endTime)
    ) {
      return isTable && session.is_slido_embed
        ? i18n.t("Ask Question")
        : i18n.t("Join Live");
    }

    if (today.isAfter(recordingAvailable)) {
      return i18n.t("Watch");
    }

    return i18n.t("Register");
  }
}

export { CialfoEvents };
