/* eslint-disable global-require */
import React, { Component } from "react";
import Loader from "react-loader-spinner";
import { withTranslation } from "react-i18next";
import { WebinarTypes } from "src/redux/actions/webinar.actions";
import EventBus from "eventing-bus";
import ContentfulScreen from "src/screens/contentful";
import Link from "react-router-dom/Link";
import PropTypes from "prop-types";
import { Utility } from "src/helpers/utility";
import SignInForm from "./events-sign-in-form";

class EventsSignInScreen extends Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    this.authenticationRequestSubscription = EventBus.on(
      WebinarTypes.AUTHENTICATION_WEBINAR_REQUEST,
      () => this.setState({ loading: true }),
    );
    this.authenticationSuccessSubscription = EventBus.on(
      WebinarTypes.AUTHENTICATION_WEBINAR_SUCCESS,
      () => this.setState({ loading: false }),
    );
    this.authenticationFailureSubscription = EventBus.on(
      WebinarTypes.AUTHENTICATION_WEBINAR_FAILURE,
      () => this.setState({ loading: false }),
    );
  }

  componentWillUnmount() {
    this._unsubscribeEvents();
  }

  _unsubscribeEvents = () => {
    this.authenticationRequestSubscription();
    this.authenticationSuccessSubscription();
    this.authenticationFailureSubscription();
  };

  _onSubmit = values => {
    const { email, password } = values;
    const { webinarSignIn } = this.props;
    const params = {
      email,
      password,
    };
    return new Promise(function (resolve, reject) {
      webinarSignIn(params, resolve, reject);
    }).catch(e => {
      Utility.promiseCatch(e);
    });
  };

  render() {
    const { t, updateAppLocale } = this.props;
    const { loading } = this.state;

    return (
      <div className="h-screen flex flex-row justify-start items-center">
        <div className="h-full p-8 bg-white rounded-lg shadow-lg w-full lg:w-477px max-w-lg flex flex-col justify-between overflow-y-auto z-10">
          <div className="flex flex-col">
            <div className="flex flex-row justify-center mb-4 mt-8">
              <img
                className="w-112px h-38px"
                src={require("src/assets/cialfo-logo.png").default}
                alt="logo"
              />
            </div>

            <div className="text-center text-gray-800 font-normal font-roboto text-base mt-4">
              {t("webinars_sign_in.text_primary")}
            </div>

            <div className="flex flex-row flex-nowrap mb-6 mt-6">
              <div className="h-px flex-1 bg-gray-300 my-3" />
            </div>

            <SignInForm onSubmit={this._onSubmit} />

            <div className="h-px bg-gray-300 my-4" />

            <Link
              to="/webinars/search-school"
              className="text-sm font-bold py-2 px-4 rounded focus:outline-none cursor-pointer border-gray-500 border self-start"
            >
              {t("webinars.buttons.create_account")}
            </Link>
          </div>

          <div className="mt-8 flex flex-row items-center justify-center">
            <button
              onClick={() => {
                updateAppLocale("en-US");
              }}
              className="text-sm text-blue-700 font-bold focus:outline-none mr-2"
            >
              English
            </button>
            <button
              onClick={() => {
                updateAppLocale("zh-CN");
              }}
              className="text-sm text-blue-700 font-bold focus:outline-none"
            >
              中文
            </button>
          </div>
        </div>
        <ContentfulScreen />
        {loading ? (
          <div className="flex justify-center items-center z-20 absolute inset-0">
            <Loader type="Oval" color="grey" height={50} width={50} />
          </div>
        ) : null}
      </div>
    );
  }
}

EventsSignInScreen.propTypes = {
  t: PropTypes.func.isRequired,
  updateAppLocale: PropTypes.func.isRequired,
  webinarSignIn: PropTypes.func.isRequired,
};

export default withTranslation()(EventsSignInScreen);
