import React, { Component } from "react";
import { InputField } from "src/components/input-field";
import { Button } from "src/components/button";
import Field from "redux-form/es/Field";
import reduxForm from "redux-form/es/reduxForm";
import Link from "react-router-dom/Link";
import isEmail from "validator/lib/isEmail";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

class SignInForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { t, handleSubmit, onSubmit } = this.props;

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-4">
          <label className="font-normal font-roboto text-black text-sm text-grey-darker block mb-2">
            {t("webinars.labels.email")}
          </label>
          <Field
            name="email"
            component={InputField}
            type="text"
            placeholder={t("common.placeholders.email")}
          />
        </div>

        <div className="mb-4">
          <label className="font-normal font-roboto text-black text-sm text-grey-darker block mb-2">
            {t("webinars.labels.password")}
          </label>
          <Field
            name="password"
            component={InputField}
            type="password"
            placeholder={t("common.placeholders.password")}
          />
        </div>

        <div className="flex flex-col md:flex-row items-center justify-between mt-6">
          <Button
            type="submit"
            text={t("webinars.buttons.sign_in_to")}
            className="lg:w-2/3 md:w-1/2 w-full justify-center mb-2 sm:mb-0"
          />

          <Link to="/webinars/forgot-password">
            <span
              className="font-bold text-sm text-blue-700"
              href="#"
            >
              {t("common.buttons.forgot_password")}
            </span>
          </Link>
        </div>
      </form>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  const { email, password } = values;
  const { t } = props;

  if (!email)
    errors.email = `${t("webinars.labels.email")} ${t(
      "validation.error.required",
    )}`;
  else if (!isEmail(email))
    errors.email = t("validation.error.email_invalid");

  if (!password)
    errors.password = `${t("webinars.labels.password")} ${t(
      "validation.error.required",
    )}`;

  return errors;
};

SignInForm.propTypes = {
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default withTranslation()(
  reduxForm({
    form: "signInForm",
    validate,
  })(SignInForm),
);
