/* eslint-disable camelcase */
import { createReducer } from "reduxsauce";
import { SchoolsTypes as Types, UserTypes } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.schools;

export const clearSchools = (state, action) => ({
  ...state,
  data: [],
});

export const getSchoolsFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getSchoolsRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getSchoolsSuccess = (state, action) => {
  const {
    schoolsData: { schools, matched, total, is_slot_status_visible },
    params,
  } = action;

  return {
    ...state,
    data:
      params.page_no === 1 ? schools : [...state.data, ...schools],
    is_slot_status_visible,
    matched,
    total,
    params: { ...state.params, ...params },
    loading: false,
  };
};

export const getSchoolFailure = (state, action) => ({
  ...state,
  loadingSelected: false,
  selected: {
    ...state.selected,
    partner: null,
  },
});
export const getSchoolRequest = (state, action) => ({
  ...state,
  loadingSelected: true,
});
export const getSchoolSuccess = (state, action) => ({
  ...state,
  loadingSelected: false,
  selected: {
    ...state.selected,
    partner: action.partner,
  },
});

export const getAvailabilitiesFailure = (state, action) => ({
  ...state,
  loadingSelected: false,
  selected: {
    ...state.selected,
    availabilities: {},
  },
});
export const getAvailabilitiesRequest = (state, action) => ({
  ...state,
  loadingSelected: true,
});
export const getAvailabilitiesSuccess = (state, action) => ({
  ...state,
  loadingSelected: false,
  selected: {
    ...state.selected,
    availabilities: action.availabilities,
  },
});

export const setSlotStateFailure = (state, action) => ({
  ...state,
});
export const setSlotStateRequest = (state, action) => ({
  ...state,
  loadingSelected: true,
});
export const setSlotStateSuccess = (state, action) => ({
  ...state,
});

export const getPendingFeedbacksFailure = state => ({
  ...state,
  pending_feedbacks: {
    ...state.pending_feedbacks,
    loading: false,
  },
});
export const getPendingFeedbacksRequest = state => ({
  ...state,
  pending_feedbacks: {
    ...state.pending_feedbacks,
    loading: true,
  },
});
export const getPendingFeedbacksSuccess = (state, action) => {
  const {
    pendingFeedbacks: { events, total_feedbacks },
  } = action;

  return {
    ...state,
    pending_feedbacks: {
      events,
      total_feedbacks,
      loading: false,
    },
  };
};

export const addFeedbackFailure = state => ({
  ...state,
  pending_feedbacks: {
    ...state.pending_feedbacks,
    addFeedbackloading: false,
  },
});
export const addFeedbackRequest = state => ({
  ...state,
  pending_feedbacks: {
    ...state.pending_feedbacks,
    addFeedbackloading: true,
  },
});
export const addFeedbackSuccess = state => ({
  ...state,
  pending_feedbacks: {
    ...state.pending_feedbacks,
    addFeedbackloading: false,
  },
});

const logout = (state, action) => INITIAL_STATE;

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.CLEAR_SCHOOLS]: clearSchools,
  [Types.GET_SCHOOLS_FAILURE]: getSchoolsFailure,
  [Types.GET_SCHOOLS_REQUEST]: getSchoolsRequest,
  [Types.GET_SCHOOLS_SUCCESS]: getSchoolsSuccess,

  [Types.GET_SCHOOL_FAILURE]: getSchoolFailure,
  [Types.GET_SCHOOL_REQUEST]: getSchoolRequest,
  [Types.GET_SCHOOL_SUCCESS]: getSchoolSuccess,

  [Types.GET_AVAILABILITIES_FAILURE]: getAvailabilitiesFailure,
  [Types.GET_AVAILABILITIES_REQUEST]: getAvailabilitiesRequest,
  [Types.GET_AVAILABILITIES_SUCCESS]: getAvailabilitiesSuccess,

  [Types.SET_SLOT_STATE_FAILURE]: setSlotStateFailure,
  [Types.SET_SLOT_STATE_REQUEST]: setSlotStateRequest,
  [Types.SET_SLOT_STATE_SUCCESS]: setSlotStateSuccess,

  [Types.GET_PENDING_FEEDBACKS_FAILURE]: getPendingFeedbacksFailure,
  [Types.GET_PENDING_FEEDBACKS_REQUEST]: getPendingFeedbacksRequest,
  [Types.GET_PENDING_FEEDBACKS_SUCCESS]: getPendingFeedbacksSuccess,

  [Types.ADD_FEEDBACK_FAILURE]: addFeedbackFailure,
  [Types.ADD_FEEDBACK_REQUEST]: addFeedbackRequest,
  [Types.ADD_FEEDBACK_SUCCESS]: addFeedbackSuccess,

  [UserTypes.LOGOUT]: logout,
};

export default createReducer(INITIAL_STATE, HANDLERS);
