import { call, put, takeEvery, select } from 'redux-saga/effects';
import { DirectApplyProgramCreators, DirectApplyProgramTypes } from '../actions';
import {
  getProgram,
  updateProgram,
  deleteProgram,
  createProgramCopy,
  getProgramsTypes,
  getProgramsTypeContent,
  getCurrencies,
  getPartnerProgramUniversities,
  getBrochures,
  getDocumentTypes,
  getBanners,
  getIntakeRounds,
  createProgramNew
} from 'src/web-services';
import { PROGRAM_STATUS } from "src/constants";
const authTokenSelector = (state) => state.user.authToken;

export function* watchDirectApplyProgramRequests() {
  yield takeEvery(DirectApplyProgramTypes.GET_PROGRAM_REQUEST, requestGetProgram);
  yield takeEvery(DirectApplyProgramTypes.UPDATE_PROGRAM_REQUEST, requestUpdateProgram);
  yield takeEvery(DirectApplyProgramTypes.DELETE_PROGRAM_REQUEST, requestDeleteProgram);
  yield takeEvery(DirectApplyProgramTypes.CREATE_PROGRAM_COPY_REQUEST, requestCreateProgramCopy);
  yield takeEvery(DirectApplyProgramTypes.SAVE_PROGRAM_DRAFT_REQUEST, requestSaveProgramDraft);
  yield takeEvery(DirectApplyProgramTypes.ACTIVATE_PROGRAM_REQUEST, requestActivateProgram);
  yield takeEvery(DirectApplyProgramTypes.DEACTIVATE_PROGRAM_REQUEST, requestDeactivateProgram);
  yield takeEvery(DirectApplyProgramTypes.GET_PROGRAM_TYPES_OPTIONS_REQUEST, requestGetProgramTypes);
  yield takeEvery(DirectApplyProgramTypes.GET_PROGRAM_TYPE_CONTENT_REQUEST, requestGetProgramTypeContent);
  yield takeEvery(DirectApplyProgramTypes.GET_CURRENCY_OPTIONS_REQUEST, requestGetCurrencies);
  yield takeEvery(DirectApplyProgramTypes.GET_UNIVERSITY_OPTIONS_REQUEST, requestGetUniversities);
  yield takeEvery(DirectApplyProgramTypes.GET_BROCHURES_REQUEST, requestGetBrochures);
  yield takeEvery(DirectApplyProgramTypes.GET_DOCUMENT_TYPES_REQUEST, requestGetDocumentTypes);
  yield takeEvery(DirectApplyProgramTypes.GET_BANNERS_REQUEST, requestGetBanners);
  yield takeEvery(DirectApplyProgramTypes.GET_INTAKE_ROUNDS_REQUEST, requestGetIntakeRounds);
  yield takeEvery(DirectApplyProgramTypes.CREATE_PROGRAM_NEW_REQUEST, requestCreateProgramNew);
}

function* requestGetProgram(action) {
  try {
    const { id } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getProgram, authToken, id);

    yield put(DirectApplyProgramCreators.getProgramSuccess(response.data));
  } catch (error) {
    const { reject } = action;
    reject && reject();
    yield put(DirectApplyProgramCreators.getProgramFailure());
  }
}

function* requestUpdateProgram(action) {
  try {
    const { id, params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(updateProgram, authToken, id, params);

    yield put(DirectApplyProgramCreators.updateProgramSuccess(response.data));
  } catch (error) {
    yield put(DirectApplyProgramCreators.updateProgramFailure());
  }
}

function* requestDeleteProgram(action) {
  try {
    const { id } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(deleteProgram, authToken, id);

    yield put(DirectApplyProgramCreators.deleteProgramSuccess(response.data.success));
  } catch (error) {
    yield put(DirectApplyProgramCreators.deleteProgramFailure());
  }
}

function* requestCreateProgramCopy(action) {
  try {
    const { id } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(createProgramCopy, authToken, id);

    yield put(DirectApplyProgramCreators.createProgramCopySuccess(response.data));
  } catch (error) {
    yield put(DirectApplyProgramCreators.createProgramCopyFailure());
  }
}

function* requestCreateProgramNew(action) {
  try {
    const authToken = yield select(authTokenSelector);
    const response = yield call(createProgramNew, authToken);
    yield put(DirectApplyProgramCreators.createProgramNewSuccess(response.data));
  } catch (error) {
    yield put(DirectApplyProgramCreators.createProgramNewFailure());
  }
}

function* requestSaveProgramDraft(action) {
  try {
    const { id, params } = action;
    const authToken = yield select(authTokenSelector);

    const response = yield call(updateProgram, authToken, id, params);

    yield put(DirectApplyProgramCreators.saveProgramDraftSuccess(response.data));
  } catch (error) {
    yield put(DirectApplyProgramCreators.saveProgramDraftFailure());
  }
}

function* requestActivateProgram(action) {
  try {
    const { id } = action;
    const authToken = yield select(authTokenSelector);
    const params = new FormData();
    params.append("partner_program[status]", PROGRAM_STATUS.ACTIVE);
    const response = yield call(updateProgram, authToken, id, params);

    yield put(DirectApplyProgramCreators.activateProgramSuccess(response.data));
  } catch (error) {
    yield put(DirectApplyProgramCreators.activateProgramFailure());
  }
}

function* requestDeactivateProgram(action) {
  try {
    const { id } = action;
    const authToken = yield select(authTokenSelector);
    const params = new FormData();
    params.append("partner_program[status]", PROGRAM_STATUS.INACTIVE);
    const response = yield call(updateProgram, authToken, id, params);

    yield put(DirectApplyProgramCreators.deactivateProgramSuccess(response.data));
  } catch (error) {
    yield put(DirectApplyProgramCreators.deactivateProgramFailure());
  }
}

function* requestGetProgramTypes(action) {
  try {
    const authToken = yield select(authTokenSelector);
    const response = yield call(getProgramsTypes, authToken);

    yield put(DirectApplyProgramCreators.getProgramTypesOptionsSuccess(response.data.program_type));
  } catch (error) {
    yield put(DirectApplyProgramCreators.getProgramTypesOptionsFailure());
  }
}

function* requestGetProgramTypeContent(action) {
  try {
    const { programType } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getProgramsTypeContent, authToken, programType);

    yield put(DirectApplyProgramCreators.getProgramTypeContentSuccess(response.data.content));
  } catch (error) {
    yield put(DirectApplyProgramCreators.getProgramTypeContentFailure());
  }
}

function* requestGetCurrencies(action) {
  try {
    const authToken = yield select(authTokenSelector);
    const response = yield call(getCurrencies, authToken);

    yield put(DirectApplyProgramCreators.getCurrencyOptionsSuccess(response.data.currencies));
  } catch (error) {
    yield put(DirectApplyProgramCreators.getCurrencyOptionsFailure());
  }
}

function* requestGetUniversities(action) {
  try {
    const authToken = yield select(authTokenSelector);
    const response = yield call(getPartnerProgramUniversities, authToken);

    yield put(DirectApplyProgramCreators.getUniversityOptionsSuccess(response.data.universities));
  } catch (error) {
    yield put(DirectApplyProgramCreators.getUniversityOptionsFailure());
  }
}

function* requestGetBrochures(action) {
  try {
    const authToken = yield select(authTokenSelector);
    const response = yield call(getBrochures, authToken);

    yield put(DirectApplyProgramCreators.getBrochuresSuccess(response.data.brochures));
  } catch (error) {
    yield put(DirectApplyProgramCreators.getBrochuresFailure());
  }
}

function* requestGetDocumentTypes(action) {
  try {
    const authToken = yield select(authTokenSelector);
    const response = yield call(getDocumentTypes, authToken);

    yield put(DirectApplyProgramCreators.getDocumentTypesSuccess(response.data.documents));
  } catch (error) {
    yield put(DirectApplyProgramCreators.getDocumentTypesFailure());
  }
}

function* requestGetBanners(action) {
  try {
    const authToken = yield select(authTokenSelector);
    const response = yield call(getBanners, authToken);

    yield put(DirectApplyProgramCreators.getBannersSuccess(response.data.banners));
  } catch (error) {
    yield put(DirectApplyProgramCreators.getBannersFailure());
  }
}

function* requestGetIntakeRounds(action) {
  try {
    const authToken = yield select(authTokenSelector);
    const response = yield call(getIntakeRounds, authToken);

    yield put(DirectApplyProgramCreators.getIntakeRoundsSuccess(response.data.rounds));
  } catch (error) {
    yield put(DirectApplyProgramCreators.getIntakeRoundsFailure());
  }
}
