/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React from "react";
import { isMobile, isTablet } from "react-device-detect";
import { Redirect, useHistory, Route } from "react-router-dom";
import { ROUTES } from "src/config/user-authorization";
import { PARTNERS } from "src/constants";
import { useSelector } from "react-redux";
import queryString from "query-string";

const findPathName = (user, pathname) => {
  const {
    data: {
      required_fields_filled: requiredFieldsFilled,
      institute_type: instituteType,
    },
  } = user;

  if (isMobile || isTablet) {
    return ROUTES.EVENTS_LIST;
  }
  if (pathname.includes("web")) {
    if (requiredFieldsFilled) {
      return ROUTES.WEBINARS;
    }
    return ROUTES.WEBINARS_USER_UPDATE;
  }

  if (instituteType === PARTNERS.SCHOOLS) {
    return ROUTES.EVENTS;
  }
  return ROUTES.DASHBOARD;
};

const componentToBeRendered = (Component, rest) => {
  const user = useSelector(state => state.user);
  const { location } = rest;
  const paths = location.pathname.split("/");
  const pathname = paths[1];
  const history = useHistory();

  const redirectToCialfoExplore = () => {
    window.location.replace("https://www.cialfo.co/explore");
    return null;
  };

  const queryParams = queryString.parse(location.search);

  if (queryParams.redirect_to) {
    if (user.authToken) {
      return (
        <Redirect
          to={{
            pathname: `/${queryParams.redirect_to}`,
            search: location.search,
          }}
        />
      );
    }
    if (!pathname) {
      return (
        <Redirect
          to={{
            pathname: ROUTES.SIGN_IN,
            search: location.search,
          }}
        />
      );
    }
  }

  return user.authToken ? (
    <Redirect
      to={{
        pathname: findPathName(user, pathname),
        search: location.search,
      }}
    />
  ) : !pathname ? (
    redirectToCialfoExplore()
  ) : (
    <Component {...rest} history={history} />
  );
};

const UnAuthenticated = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    component={() => componentToBeRendered(Component, rest)}
  />
);

export default UnAuthenticated;
