import { connect } from "react-redux";
import { UserCreators } from "src/redux/actions/user.actions";
import { WebinarCreators } from "src/redux/actions/webinar.actions";
import EventsSignInScreen from "./events-sign-in-screen";

function mapStateToProps() {
  return {};
}

const mapDispatchToProps = {
  webinarSignIn: WebinarCreators.authenticationWebinarRequest,
  updateAppLocale: UserCreators.updateAppLocale,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EventsSignInScreen);
