/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */
import { call, put, takeEvery, select } from "redux-saga/effects";
import {
  getSchools,
  getSchool,
  setStateSchool,
  getAvailabilities,
  getPendingFeedbacks,
  addFeedback,
} from "src/web-services";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import { SchoolsCreators, SchoolsTypes } from "../actions";

const authTokenSelector = state => state.user.authToken;
const partnerIdSelector = state => state.user.data.partner_id;
const userIdSelector = state => state.user.data.id;

export function* watchSchoolsRequests() {
  yield takeEvery(
    SchoolsTypes.GET_SCHOOLS_REQUEST,
    requestGetSchools,
  );
  yield takeEvery(SchoolsTypes.GET_SCHOOL_REQUEST, requestGetSchool);
  yield takeEvery(
    SchoolsTypes.GET_AVAILABILITIES_REQUEST,
    requestGetAvailabilities,
  );
  yield takeEvery(
    SchoolsTypes.SET_SLOT_STATE_REQUEST,
    RequestsetSlotState,
  );
  yield takeEvery(
    SchoolsTypes.GET_PENDING_FEEDBACKS_REQUEST,
    requestGetPendingFeedbacks,
  );
  yield takeEvery(
    SchoolsTypes.ADD_FEEDBACK_REQUEST,
    requestAddFeedback,
  );
}

export function* requestGetSchools(action) {
  try {
    const { params, resolve } = action;

    const schoolsSelector = state => state.schools;
    const authToken = yield select(authTokenSelector);
    const partnerId = yield select(partnerIdSelector);
    const { data: currentSchools, params: currentParams } =
      yield select(schoolsSelector);

    const shouldLoadMore =
      currentSchools.length > 0 &&
      isEqual(omit(currentParams, ["page_no"]), params);

    if (!shouldLoadMore) yield put(SchoolsCreators.clearSchools());

    const page = shouldLoadMore ? currentParams.page_no + 1 : 1;
    const requestParams = { ...params };
    requestParams["page_no"] = page;

    const response = yield call(
      getSchools,
      authToken,
      partnerId,
      requestParams,
    );
    resolve && resolve();
    yield put(
      SchoolsCreators.getSchoolsSuccess(response.data, requestParams),
    );
  } catch (error) {
    const { reject } = action;
    reject && reject(error);
    yield put(SchoolsCreators.getSchoolsFailure());
  }
}

export function* requestGetSchool(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);

    const response = yield call(
      getSchool,
      authToken,
      params.partner_id,
    );
    yield put(SchoolsCreators.getSchoolSuccess(response.data));
  } catch (error) {
    yield put(SchoolsCreators.getSchoolFailure());
  }
}

export function* requestGetAvailabilities(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const partnerId = yield select(partnerIdSelector);

    const response = yield call(
      getAvailabilities,
      authToken,
      partnerId,
      params,
    );
    yield put(
      SchoolsCreators.getAvailabilitiesSuccess(
        response.data.availabilities,
      ),
    );
  } catch (error) {
    yield put(SchoolsCreators.getAvailabilitiesFailure());
  }
}

export function* RequestsetSlotState(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const userId = yield select(userIdSelector);
    const response = yield call(
      setStateSchool,
      authToken,
      userId,
      params,
    );
    yield put(SchoolsCreators.setSlotStateSuccess(response));
  } catch (error) {
    yield put(SchoolsCreators.setSlotStateFailure());
  }
}

export function* requestGetPendingFeedbacks() {
  try {
    const authToken = yield select(authTokenSelector);
    const response = yield call(getPendingFeedbacks, authToken);
    yield put(
      SchoolsCreators.getPendingFeedbacksSuccess(response.data),
    );
  } catch (error) {
    yield put(SchoolsCreators.getPendingFeedbacksFailure());
  }
}

export function* requestAddFeedback(action) {
  try {
    const { data, eventId } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      addFeedback,
      authToken,
      data,
      eventId,
    );
    yield put(SchoolsCreators.addFeedbackSuccess(response));
  } catch (error) {
    yield put(SchoolsCreators.addFeedbackFailure());
  }
}
