/* eslint-disable no-unused-expressions */
import { call, put, takeEvery, select } from "redux-saga/effects";
import { WebinarCreators, WebinarTypes } from "../actions";
import {
  webinarSignIn,
  registerWebinarUser,
  updateWebinarUser,
  updateWebinarUserPassword,
  getWebinarList,
  getTrengingEvents,
  getAllSessions,
  getWebinarEvents,
  registerUserToWebinarEvents,
  sendEventResetPasswordMail,
  resetEventPassword,
  getEventsFilters,
  getMySessions,
  cancelMySession,
  getPartnerWebinarList,
  getWebinarEvent,
} from "src/web-services";
import { Notification } from "src/helpers";
import { SubmissionError } from "redux-form";
import each from "lodash/each";
import moment from "moment";

const authTokenSelector = state => state.user.authToken;

export function* watchWebinarRequests() {
  yield takeEvery(
    WebinarTypes.REGISTER_USER_REQUEST,
    requestRegisterUser,
  );
  yield takeEvery(
    WebinarTypes.AUTHENTICATION_WEBINAR_REQUEST,
    requestSignIn,
  );
  yield takeEvery(
    WebinarTypes.UPDATE_WEBINAR_USER_REQUEST,
    requestUpdateUser,
  );
  yield takeEvery(
    WebinarTypes.UPDATE_WEBINAR_USER_PASSWORD_REQUEST,
    requestUpdatePassword,
  );
  yield takeEvery(
    WebinarTypes.GET_WEBINAR_LIST_REQUEST,
    requestGetWebinarList,
  );
  yield takeEvery(
    WebinarTypes.GET_TRENDING_EVENTS_REQUEST,
    requestGetTrengingEvents,
  );
  yield takeEvery(
    WebinarTypes.GET_ALL_SESSIONS_REQUEST,
    requestGetAllSessions,
  );
  yield takeEvery(
    WebinarTypes.GET_WEBINAR_EVENTS_REQUEST,
    requestGetWebinarEvents,
  );
  yield takeEvery(
    WebinarTypes.REGISTER_USER_TO_WEBINAR_EVENTS_REQUEST,
    requestRegisterUserToWebinarEvents,
  );
  yield takeEvery(
    WebinarTypes.SEND_EVENT_RESET_PASSWORD_MAIL_REQUEST,
    requestEventSendResetPasswordMail,
  );
  yield takeEvery(
    WebinarTypes.RESET_EVENT_PASSWORD_REQUEST,
    requestEventResetPassword,
  );
  yield takeEvery(
    WebinarTypes.REGISTER_ALL_SESSIONS_USER_TO_WEBINAR_EVENTS_REQUEST,
    requestRegisterAllSessionUserToWebinarEvents,
  );
  yield takeEvery(
    WebinarTypes.GET_EVENTS_FILTERS_REQUEST,
    requestGetEventsFilters,
  );
  yield takeEvery(
    WebinarTypes.GET_MY_SESSIONS_REQUEST,
    requestGetMySessions,
  );
  yield takeEvery(
    WebinarTypes.CANCEL_MY_SESSION_REQUEST,
    requestCancelMySession,
  );
  yield takeEvery(
    WebinarTypes.GET_WEBINAR_EVENT_REQUEST,
    requestGetWebinarEvent,
  );
  yield takeEvery(
    WebinarTypes.REGISTER_ON_DEMAND_VIDEO_CLICK_REQUEST,
    requestRegisterOnDemandVideoClick,
  );
}

export function* requestRegisterUser(action) {
  try {
    const { params } = action;
    const response = yield call(registerWebinarUser, params);
    yield put(WebinarCreators.registerUserSuccess(response.data));
  } catch (error) {
    yield put(WebinarCreators.registerUserFailure());
  }
}

export function* requestSignIn(action) {
  try {
    const { params, resolve } = action;
    const response = yield call(webinarSignIn, params);
    resolve();
    yield put(
      WebinarCreators.authenticationWebinarSuccess(response.data),
    );
  } catch (error) {
    const { reject } = action;

    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 404)
    ) {
      let errorMessages = {};

      each(error.response.data.error, (error, key) => {
        errorMessages[key] = error instanceof Array ? error : [error];
      });

      reject(new SubmissionError(errorMessages));
    } else reject(error);

    yield put(WebinarCreators.authenticationWebinarFailure());
  }
}

export function* requestUpdateUser(action) {
  try {
    const { params, resolve } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(updateWebinarUser, authToken, params);
    resolve && resolve();
    yield put(
      WebinarCreators.updateWebinarUserSuccess(response.data),
    );
  } catch (error) {
    const { reject } = action;
    let errorMessages = {};

    each(error.response.data.error, (error, key) => {
      errorMessages[key] = error instanceof Array ? error : [error];
    });

    reject && reject(new SubmissionError(errorMessages));
    yield put(WebinarCreators.updateWebinarUserFailure());
  }
}

export function* requestUpdatePassword(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);

    yield call(updateWebinarUserPassword, authToken, params);
    yield put(WebinarCreators.updateWebinarUserPasswordSuccess());
  } catch (error) {
    yield put(WebinarCreators.updateWebinarUserPasswordFailure());
  }
}

export function* requestGetWebinarList(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    let api;
    if (params && params.isPartner) {
      api = getPartnerWebinarList;
    } else {
      api = getWebinarList;
    }
    const response = yield call(api, authToken, params);
    yield put(WebinarCreators.getWebinarListSuccess(response.data));
  } catch (error) {
    const { reject } = action;
    let errorMessages = {};

    each(error.response.data.error, (error, key) => {
      errorMessages[key] = error instanceof Array ? error : [error];
    });

    reject && reject(new SubmissionError(errorMessages));
    yield put(WebinarCreators.getWebinarListFailure());
  }
}

export function* requestGetTrengingEvents(action) {
  try {
    const { params, resolve } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getTrengingEvents, params, authToken);
    resolve && resolve();
    yield put(
      WebinarCreators.getTrendingEventsSuccess(response.data),
    );
  } catch (error) {
    const { reject } = action;
    let errorMessages = {};

    each(error.response.data.error, (e, key) => {
      errorMessages[key] = e instanceof Array ? e : [e];
    });

    reject && reject(new SubmissionError(errorMessages));
    yield put(WebinarCreators.getTrendingEventsfailure());
  }
}

export function* requestGetAllSessions(action) {
  try {
    const { id, resolve } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getAllSessions,
      parseInt(id, 10),
      authToken,
    );
    resolve && resolve();
    yield put(WebinarCreators.getAllSessionsSuccess(response.data));
  } catch (error) {
    const {
      params: { reject },
    } = action;
    let errorMessages = {};

    each(error.response.data.error, (e, key) => {
      errorMessages[key] = e instanceof Array ? e : [e];
    });

    if (error?.response?.status) {
      errorMessages.status = error?.response?.status;
    }

    reject && reject(new SubmissionError(errorMessages));
    yield put(WebinarCreators.getAllSessionsFailure());
  }
}

export function* requestGetWebinarEvents(action) {
  try {
    const { params, resolve } = action;
    const authToken = yield select(authTokenSelector);

    const requestParams = { ...params };

    const response = yield call(
      getWebinarEvents,
      authToken,
      requestParams,
    );
    resolve && resolve();
    yield put(
      WebinarCreators.getWebinarEventsSuccess(
        response.data,
        requestParams,
      ),
    );
  } catch (error) {
    const { reject } = action;
    reject && reject(error);
    yield put(WebinarCreators.getWebinarEventsFailure());
  }
}

export function* requestRegisterUserToWebinarEvents(action) {
  try {
    const { params, resolve } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      registerUserToWebinarEvents,
      authToken,
      params,
    );
    resolve && resolve();
    yield put(
      WebinarCreators.registerUserToWebinarEventsSuccess(
        response.data,
      ),
    );
    let webinar = response.data;
    let isLive = moment().isBetween(
      moment(webinar.start_time),
      moment(webinar.end_time),
    );
    let isPast = moment(webinar.end_time).isBefore(moment());
    if (
      !webinar.is_in_person &&
      params.join &&
      (isLive || (isPast && webinar.is_recording_available))
    )
      webinar.join_url && window.open(webinar.join_url);
  } catch (error) {
    const { reject } = action;
    let errorMessages = {};

    each(error.response.data.error, (error, key) => {
      errorMessages[key] = error instanceof Array ? error : [error];
    });

    reject && reject(new SubmissionError(errorMessages));
    yield put(
      WebinarCreators.registerUserToWebinarEventsFailure(
        action.params.id,
      ),
    );
  }
}

export function* requestEventSendResetPasswordMail(action) {
  try {
    const { params } = action;
    const response = yield call(sendEventResetPasswordMail, params);
    Notification.success(response.data.message);
    yield put(WebinarCreators.sendEventResetPasswordMailSuccess());
  } catch (error) {
    yield put(WebinarCreators.sendEventResetPasswordMailFailure());
  }
}

export function* requestEventResetPassword(action) {
  try {
    const { params } = action;
    const response = yield call(resetEventPassword, params);
    Notification.success(response.data.message);
    yield put(WebinarCreators.resetEventPasswordSuccess());
  } catch (error) {
    yield put(WebinarCreators.resetEventPasswordFailure());
  }
}

export function* requestRegisterAllSessionUserToWebinarEvents(
  action,
) {
  try {
    const { params, resolve } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      registerUserToWebinarEvents,
      authToken,
      params,
    );
    resolve && resolve();
    yield put(
      WebinarCreators.registerAllSessionsUserToWebinarEventsSuccess(
        response.data,
      ),
    );
    let webinar = response.data;
    let isLive = moment().isBetween(
      moment(webinar.start_time),
      moment(webinar.end_time),
    );
    let isPast = moment(webinar.end_time).isBefore(moment());
    if (
      !webinar.is_in_person &&
      params.join &&
      (isLive || (isPast && webinar.is_recording_available))
    )
      webinar.join_url && window.open(webinar.join_url);
  } catch (error) {
    const { reject } = action;
    let errorMessages = {};

    each(error.response.data.error, (e, key) => {
      errorMessages[key] = e instanceof Array ? e : [e];
    });

    reject && reject(new SubmissionError(errorMessages));
    yield put(
      WebinarCreators.registerAllSessionsUserToWebinarEventsFailure(
        action.params.id,
      ),
    );
  }
}

export function* requestGetEventsFilters(action) {
  try {
    const { params, resolve } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getEventsFilters, params, authToken);
    resolve && resolve();
    yield put(WebinarCreators.getEventsFiltersSuccess(response.data));
  } catch (error) {
    const { reject } = action;
    let errorMessages = {};

    each(error.response.data.error, (e, key) => {
      errorMessages[key] = e instanceof Array ? e : [e];
    });

    reject && reject(new SubmissionError(errorMessages));
    yield put(WebinarCreators.getEventsFiltersFailure());
  }
}

export function* requestGetMySessions(action) {
  try {
    const { params, resolve } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getMySessions, params, authToken);
    resolve && resolve();
    yield put(WebinarCreators.getMySessionsSuccess(response.data));
  } catch (error) {
    const { reject } = action;
    let errorMessages = {};

    each(error.response.data.error, (error, key) => {
      errorMessages[key] = error instanceof Array ? error : [error];
    });

    reject && reject(new SubmissionError(errorMessages));
    yield put(WebinarCreators.getMySessionsFailure());
  }
}

export function* requestCancelMySession(action) {
  try {
    const { params, resolve } = action;
    const authToken = yield select(authTokenSelector);
    yield call(cancelMySession, params, authToken);
    resolve && resolve();
    yield put(WebinarCreators.cancelMySessionSuccess(params));
  } catch (error) {
    const { reject } = action;
    let errorMessages = {};

    each(error.response.data.error, (e, key) => {
      errorMessages[key] = e instanceof Array ? e : [e];
    });

    reject && reject(new SubmissionError(errorMessages));
    yield put(WebinarCreators.cancelMySessionFailure());
  }
}

export function* requestGetWebinarEvent(action) {
  try {
    const { params, resolve } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getWebinarEvent, params, authToken);
    resolve && resolve();
    yield put(WebinarCreators.getWebinarEventSuccess(response.data));
  } catch (error) {
    const { reject } = action;
    let errorMessages = {};

    each(error.response.data.error, (e, key) => {
      errorMessages[key] = e instanceof Array ? e : [e];
    });

    reject && reject(new SubmissionError(errorMessages));
    yield put(WebinarCreators.getWebinarEventFailure());
  }
}

export function* requestRegisterOnDemandVideoClick(action) {
  try {
    const {
      data: { id },
    } = action;
    const params = {
      id,
      is_video_watched: true,
    };
    const authToken = yield select(authTokenSelector);
    yield call(registerUserToWebinarEvents, authToken, params);
    yield put(WebinarCreators.registerOnDemandVideoClickSuccess());
  } catch (error) {
    yield put(WebinarCreators.registerOnDemandVideoClickFailure());
  }
}
