import { createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
  getProgramFailure: null,
  getProgramRequest: ['id'],
  getProgramSuccess: ['program'],

  updateProgramFailure: null,
  updateProgramRequest: ['id', 'params'],
  updateProgramSuccess: ['program'],

  deleteProgramFailure: null,
  deleteProgramRequest: ['id'],
  deleteProgramSuccess: ['program'],

  saveDeleteProgramRequest: null,

  createProgramCopyFailure: null,
  createProgramCopyRequest: ['id'],
  createProgramCopySuccess: ['program'],

  saveProgramDraftFailure: null,
  saveProgramDraftRequest: ['id', 'params'],
  saveProgramDraftSuccess: ['program'],

  activateProgramFailure: null,
  activateProgramRequest: ['id', 'params'],
  activateProgramSuccess: ['program'],

  deactivateProgramFailure: null,
  deactivateProgramRequest: ['id', 'params'],
  deactivateProgramSuccess: ['program'],

  getProgramTypesOptionsFailure: null,
  getProgramTypesOptionsRequest: null,
  getProgramTypesOptionsSuccess: ['programTypes'],

  getProgramTypeContentFailure: null,
  getProgramTypeContentRequest: ['programType'],
  getProgramTypeContentSuccess: ['content'],

  getCurrencyOptionsFailure: null,
  getCurrencyOptionsRequest: null,
  getCurrencyOptionsSuccess: ['currencies'],

  getUniversityOptionsFailure: null,
  getUniversityOptionsRequest: null,
  getUniversityOptionsSuccess: ['universities'],

  getBrochuresFailure: null,
  getBrochuresRequest: null,
  getBrochuresSuccess: ['brochures'],

  getDocumentTypesFailure: null,
  getDocumentTypesRequest: null,
  getDocumentTypesSuccess: ['documentTypes'],

  getBannersFailure: null,
  getBannersRequest: null,
  getBannersSuccess: ['banners'],

  getIntakeRoundsFailure: null,
  getIntakeRoundsRequest: null,
  getIntakeRoundsSuccess: ['intakeRounds'],

  createProgramNewFailure: null,
  createProgramNewRequest: null,
  createProgramNewSuccess: ['program'],

  saveDraftCliked: ['value'],  

}, { prefix: "DIRECT_APPLY_PROGRAM_" });

export const DirectApplyProgramTypes = Types;
export const DirectApplyProgramCreators = Creators;
