import { createReducer } from "reduxsauce";
import { TeamTypes as Types, UserTypes } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.team;

export const clearTeam = (state, action) => ({ ...state, data: [] });

export const downloadTeamFailure = (state, action) => ({
  ...state,
  downloadUrl: null,
  downloading: false,
});
export const downloadTeamRequest = (state, action) => ({
  ...state,
  downloadUrl: null,
  downloading: true,
});
export const downloadTeamSuccess = (state, action) => ({
  ...state,
  downloadUrl: action.url,
  downloading: false,
});

export const getTeamFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getTeamRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getTeamSuccess = (state, action) => {
  const {
    teamData: { members, matched, total },
    params,
  } = action;

  return {
    data:
      params.page_no === 1 ? members : [...state.data, ...members],
    matched,
    total,
    params: { ...state.params, ...params },
    loading: false,
  };
};

export const bulkActionFailure = (state, action) => ({
  ...state,
  performingBulkAction: false,
});
export const bulkActionRequest = (state, action) => ({
  ...state,
  performingBulkAction: true,
});
export const bulkActionSuccess = (state, action) => ({
  ...state,
  performingBulkAction: false,
});

export const createTeamMemberFailure = (state, action) => ({
  ...state,
  creatingTeamMember: false,
});
export const createTeamMemberRequest = (state, action) => ({
  ...state,
  creatingTeamMember: true,
});
export const createTeamMemberSuccess = (state, action) => ({
  ...state,
  creatingTeamMember: false,
});

export const getTeamMemberFailure = (state, action) => ({
  ...state,
  loadingSelected: false,
});
export const getTeamMemberRequest = (state, action) => ({
  ...state,
  loadingSelected: true,
  selected: null,
});
export const getTeamMemberSuccess = (state, action) => ({
  ...state,
  loadingSelected: false,
  selected: action.teamMember,
});

export const updateTeamMemberFailure = (state, action) => ({
  ...state,
  updatingTeamMember: false,
});
export const updateTeamMemberRequest = (state, action) => ({
  ...state,
  updatingTeamMember: true,
});
export const updateTeamMemberSuccess = (state, action) => {
  const { teamMember } = action;
  const { data } = state;

  return {
    ...state,
    data: data.map(u => (u.id === teamMember.id ? teamMember : u)),
    updatingTeamMember: false,
  };
};

const logout = (state, action) => INITIAL_STATE;

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.CLEAR_TEAM]: clearTeam,
  [Types.DOWNLOAD_TEAM_FAILURE]: downloadTeamFailure,
  [Types.DOWNLOAD_TEAM_REQUEST]: downloadTeamRequest,
  [Types.DOWNLOAD_TEAM_SUCCESS]: downloadTeamSuccess,
  [Types.GET_TEAM_FAILURE]: getTeamFailure,
  [Types.GET_TEAM_REQUEST]: getTeamRequest,
  [Types.GET_TEAM_SUCCESS]: getTeamSuccess,
  [Types.BULK_ACTION_FAILURE]: bulkActionFailure,
  [Types.BULK_ACTION_REQUEST]: bulkActionRequest,
  [Types.BULK_ACTION_SUCCESS]: bulkActionSuccess,
  [Types.CREATE_TEAM_MEMBER_FAILURE]: createTeamMemberFailure,
  [Types.CREATE_TEAM_MEMBER_REQUEST]: createTeamMemberRequest,
  [Types.CREATE_TEAM_MEMBER_SUCCESS]: createTeamMemberSuccess,
  [Types.GET_TEAM_MEMBER_FAILURE]: getTeamMemberFailure,
  [Types.GET_TEAM_MEMBER_REQUEST]: getTeamMemberRequest,
  [Types.GET_TEAM_MEMBER_SUCCESS]: getTeamMemberSuccess,
  [Types.UPDATE_TEAM_MEMBER_FAILURE]: updateTeamMemberFailure,
  [Types.UPDATE_TEAM_MEMBER_REQUEST]: updateTeamMemberRequest,
  [Types.UPDATE_TEAM_MEMBER_SUCCESS]: updateTeamMemberSuccess,
  [UserTypes.LOGOUT]: logout,
};

export default createReducer(INITIAL_STATE, HANDLERS);
