import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  clearApplications: null,
  clearPrograms: null,

  getApplicationFailure: null,
  getApplicationRequest: ["id"],
  getApplicationSuccess: ["data"],

  updateApplicationFailure: null,
  updateApplicationRequest: ["id", "updateData"],
  updateApplicationSuccess: ["updatedApplicationAttributes"],

  updateApplicationStatusFailure: null,
  updateApplicationStatusRequest: ["id", "updateData"],
  updateApplicationStatusSuccess: ["updatedApplicationAttributes"],

  updateApplicationResultFailure: null,
  updateApplicationResultRequest: ["id", "updateData"],
  updateApplicationResultSuccess: ["updatedApplicationAttributes"],

  resetChoiceFailure: null,
  resetChoiceRequest: ["id", "resetData"],
  resetChoiceSuccess: ["updatedApplicationAttributes"],

  updateApplicationDepositPaidFailure: null,
  updateApplicationDepositPaidRequest: ["id", "updateData"],
  updateApplicationDepositPaidSuccess: [
    "updatedApplicationAttributes",
  ],

  updateApplicationUcasChoiceFailure: null,
  updateApplicationUcasChoiceRequest: ["id", "updateData"],
  updateApplicationUcasChoiceSuccess: [
    "updatedApplicationAttributes",
  ],

  getApplicationsFailure: null,
  getApplicationsRequest: ["resolve", "reject"],
  getApplicationsSuccess: [
    "applications",
    "matched",
    "total",
    "pastTotal",
    "params",
    "data_count",
  ],

  getDirectApplyFilterCountriesFailure: null,
  getDirectApplyFilterCountriesRequest: ["params"],
  getDirectApplyFilterCountriesSuccess: ["countries"],

  getDirectApplyFilterCoursesFailure: null,
  getDirectApplyFilterCoursesRequest: ["params"],
  getDirectApplyFilterCoursesSuccess: ["courses"],

  getDirectApplyFilterProgramsFailure: null,
  getDirectApplyFilterProgramsRequest: ["params"],
  getDirectApplyFilterProgramsSuccess: ["programs"],

  getDirectApplyFilterResultsFailure: null,
  getDirectApplyFilterResultsRequest: ["params"],
  getDirectApplyFilterResultsSuccess: ["results"],

  getDirectApplyFilterStatusFailure: null,
  getDirectApplyFilterStatusRequest: ["params"],
  getDirectApplyFilterStatusSuccess: ["status"],

  getDirectApplyFilterFeeWaivedFailure: null,
  getDirectApplyFilterFeeWaivedRequest: ["params"],
  getDirectApplyFilterFeeWaivedSuccess: ["statuses"],

  getDirectApplyFeeStatusesFailure: null,
  getDirectApplyFeeStatusesRequest: ["params"],
  getDirectApplyFeeStatusesSuccess: ["statuses"],

  getDirectApplyFilterSchoolsFailure: null,
  getDirectApplyFilterSchoolsRequest: ["params"],
  getDirectApplyFilterSchoolsSuccess: ["schools"],

  getDirectApplicationResultsFailure: null,
  getDirectApplicationResultsRequest: ["id"],
  getDirectApplicationResultsSuccess: ["results"],

  getUcasChoicesFailure: null,
  getUcasChoicesRequest: null,
  getUcasChoicesSuccess: ["choices"],

  uploadDirectApplicationDocumentFailure: null,
  uploadDirectApplicationDocumentRequest: ["id", "document"],
  uploadDirectApplicationDocumentSuccess: [
    "document",
    "updatedApplicationAttributes",
  ],

  uploadUcasReportFailure: null,
  uploadUcasReportRequest: ["studentId", "data"],
  uploadUcasReportSuccess: null,

  deleteUcasReportFailure: null,
  deleteUcasReportRequest: ["studentId", "data"],
  deleteUcasReportSuccess: null,

  getDirectApplyListDownloadUrlFailure: null,
  getDirectApplyListDownloadUrlRequest: null,
  getDirectApplyListDownloadUrlSuccess: ["url"],

  getDirectApplicationActivitiesFailure: null,
  getDirectApplicationActivitiesRequest: ["applicationId"],
  getDirectApplicationActivitiesSuccess: ["activities"],

  getProgramsFailure: null,
  getProgramsRequest: ["params", "resolve", "reject"],
  getProgramsSuccess: ["programs", "matched", "total", "params"],

  deleteDirectApplicationDocumentFailure: null,
  deleteDirectApplicationDocumentRequest: ["document"],
  deleteDirectApplicationDocumentSuccess: ["id"],

  getProgramsFilterUniversitiesFailure: null,
  getProgramsFilterUniversitiesRequest: ["params"],
  getProgramsFilterUniversitiesSuccess: ["universities"],

  getProgramsFilterStatusesFailure: null,
  getProgramsFilterStatusesRequest: ["params"],
  getProgramsFilterStatusesSuccess: ["statuses"],

  getProgramsFilterRoundsFailure: null,
  getProgramsFilterRoundsRequest: ["params"],
  getProgramsFilterRoundsSuccess: ["rounds"],

  getProgramsListDownloadUrlFailure: null,
  getProgramsListDownloadUrlRequest: ["params", "resolve", "reject"],
  getProgramsListDownloadUrlSuccess: ["url"],
});

export const DirectApplyTypes = Types;
export const DirectApplyCreators = Creators;
