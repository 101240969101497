import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  clearSchools: null,

  getSchoolsFailure: null,
  getSchoolsRequest: ["params", "resolve", "reject"],
  getSchoolsSuccess: ["schoolsData", "params"],

  getSchoolFailure: null,
  getSchoolRequest: ["params"],
  getSchoolSuccess: ["partner"],

  getAvailabilitiesFailure: null,
  getAvailabilitiesRequest: ["params"],
  getAvailabilitiesSuccess: ["availabilities"],

  setSlotStateFailure: null,
  setSlotStateRequest: ["params"],
  setSlotStateSuccess: null,

  getPendingFeedbacksFailure: null,
  getPendingFeedbacksRequest: null,
  getPendingFeedbacksSuccess: ["pendingFeedbacks"],

  addFeedbackFailure: null,
  addFeedbackRequest: ["data", "eventId"],
  addFeedbackSuccess: null,
});

export const SchoolsTypes = Types;
export const SchoolsCreators = Creators;
