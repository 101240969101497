import { API } from "../helpers";
import { SERVER_URL } from "../config";

export const signIn = data => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/sessions`,
    data: data,
  });
};

export const sso = data => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/sessions/create_sso`,
    data: data,
  });
};

export const googleSSO = data => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/sessions/create_google_oauth`,
    data: data,
  });
};

export const ssoCialfo = authToken => {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/sessions/create_cialfo_sso`,
    headers: {
      "Content-Type": "multipart/form-data",
      authentication_token: authToken,
    },
  });
};

export const getSisenseUrl = (authToken, params) => {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/sisense/signin`,
    headers: {
      "Content-Type": "multipart/form-data",
      authentication_token: authToken,
    },
    params,
  });
};

export const getSisenseDashboards = authToken => {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/sisense/dashboards`,
    headers: {
      "Content-Type": "multipart/form-data",
      authentication_token: authToken,
    },
  });
};

export const getMetabaseUrl = (authToken, params) => {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/reports/${params.dashboard_id}`,
    headers: {
      "Content-Type": "multipart/form-data",
      authentication_token: authToken,
    },
  });
};

export const getMetabaseDashboards = authToken => {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/reports`,
    headers: {
      "Content-Type": "multipart/form-data",
      authentication_token: authToken,
    },
  });
};

export const checkSession = (authToken, id) => {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/users/${id}/check_session`,
    headers: {
      "Content-Type": "multipart/form-data",
      authentication_token: authToken,
    },
  });
};

export const confirmaEmailId = params => {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/registrations/email_confirmation`,
    params,
  });
};

export const sendResetPasswordMail = data => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/passwords/forgot_password`,
    data: data,
  });
};

export const resetPassword = data => {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/passwords/reset_password`,
    data: data,
  });
};

export const updatePassword = (authToken, id, data) => {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/users/${id}/update_password`,
    headers: {
      "Content-Type": "multipart/form-data",
      authentication_token: authToken,
    },
    data: data,
  });
};

export const registerUser = data => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/registrations`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: data,
    silent: true,
  });
};

export const updateUser = (authToken, id, data) => {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/users/${id}`,
    headers: {
      "Content-Type": "multipart/form-data",
      authentication_token: authToken,
    },
    data: data,
    silent: true,
  });
};

export const updateUserTimezone = (authToken, id, data) => {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/users/${id}/update_timezone`,
    headers: {
      "Content-Type": "multipart/form-data",
      authentication_token: authToken,
    },
    data: data,
  });
};

export const setOnboarding = (authToken, id, params) => {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/users/${id}/set_onboarding`,
    headers: {
      "Content-Type": "multipart/form-data",
      authentication_token: authToken,
    },
    params,
  });
};

export const updatePartner = (authToken, id, type, data) => {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/${type}/${id}`,
    headers: {
      "Content-Type": "multipart/form-data",
      authentication_token: authToken,
    },
    data,
  });
};

export const requestDemo = authToken => {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/users/70/request_demo`,
    headers: {
      "Content-Type": "multipart/form-data",
      authentication_token: authToken,
    },
  });
};

export const acceptPrivacyPolicy = (authToken, data) => {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/users/accept_privacy_policy`,
    headers: {
      "Content-Type": "multipart/form-data",
      authentication_token: authToken,
    },
    data,
  });
};

export const getCountryCode = authToken => {
  return API.request({
    method: "get",
    url: `https://geo-ip.cialfo.co`,
    mergeHeaders: false,
    silent: true,
  });
};

export const updateUserLocale = (authToken, data) => {
  return API.request({
    method: "put",
    headers: {
      "Content-Type": "multipart/form-data",
      authentication_token: authToken,
    },
    url: `${SERVER_URL}/api/v1/webinars/users/update_locale`,
    data,
  });
};

export const updateEmailSettings = (authToken, id, data) => {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/users/${id}/set_email_preference`,
    headers: {
      authentication_token: authToken,
    },
    data: data,
  });
};

export const getZoomAuthUrl = authToken => {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/zoom/connect`,
    headers: {
      authentication_token: authToken,
    },
  });
};

export const updateZoomDetails = (authToken, data) => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/zoom/sync_user`,
    headers: {
      authentication_token: authToken,
    },
    data: data,
  });
};
