import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  setRegistrationParams: ["params"],
  clearWebinarEvents: null,
  setEventCategorySearchParams: ["params"],

  registerUserFailure: null,
  registerUserRequest: ["params"],
  registerUserSuccess: ["response"],

  authenticationWebinarFailure: null,
  authenticationWebinarRequest: ["params", "resolve", "reject"],
  authenticationWebinarSuccess: ["response"],

  updateWebinarUserFailure: null,
  updateWebinarUserRequest: ["params", "resolve", "reject"],
  updateWebinarUserSuccess: ["user"],

  updateWebinarUserPasswordFailure: null,
  updateWebinarUserPasswordRequest: ["params", "resolve", "reject"],
  updateWebinarUserPasswordSuccess: null,

  getWebinarListFailure: null,
  getWebinarListRequest: ["params", "resolve", "reject"],
  getWebinarListSuccess: ["response"],

  getTrendingEventsFailure: null,
  getTrendingEventsRequest: ["params", "resolve", "reject"],
  getTrendingEventsSuccess: ["response"],

  clearTrendingEvents: null,

  getAllSessionsFailure: null,
  getAllSessionsRequest: ["id", "resolve", "reject"],
  getAllSessionsSuccess: ["response"],

  getWebinarEventsFailure: null,
  getWebinarEventsRequest: ["params", "resolve", "reject"],
  getWebinarEventsSuccess: ["eventsData", "params"],

  registerUserToWebinarEventsFailure: ["webinarId"],
  registerUserToWebinarEventsRequest: ["params", "resolve", "reject"],
  registerUserToWebinarEventsSuccess: ["response"],

  sendEventResetPasswordMailFailure: null,
  sendEventResetPasswordMailRequest: ["params"],
  sendEventResetPasswordMailSuccess: null,

  resetEventPasswordFailure: null,
  resetEventPasswordRequest: ["params"],
  resetEventPasswordSuccess: null,

  registerAllSessionsUserToWebinarEventsFailure: ["webinarId"],
  registerAllSessionsUserToWebinarEventsRequest: [
    "params",
    "resolve",
    "reject",
  ],
  registerAllSessionsUserToWebinarEventsSuccess: ["response"],

  getEventsFiltersFailure: null,
  getEventsFiltersRequest: ["params", "resolve", "reject"],
  getEventsFiltersSuccess: ["response"],

  getMySessionsFailure: null,
  getMySessionsRequest: ["params", "resolve", "reject"],
  getMySessionsSuccess: ["response"],

  cancelMySessionFailure: null,
  cancelMySessionRequest: ["params", "resolve", "reject"],
  cancelMySessionSuccess: ["response"],

  getWebinarEventFailure: null,
  getWebinarEventRequest: ["params", "resolve", "reject"],
  getWebinarEventSuccess: ["response"],

  registerOnDemandVideoClickFailure: null,
  registerOnDemandVideoClickRequest: ["data"],
  registerOnDemandVideoClickSuccess: null,
});

export const WebinarTypes = Types;
export const WebinarCreators = Creators;
