import { API } from "../helpers";
import { SERVER_URL } from "../config";

export function getEvents(authToken, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/events`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getEvent(authToken, id) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/events/${id}`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function createEvent(authToken, universityId, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/events/create_event`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function universityRescheduleEvent(authToken, eventId, data) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/events/${eventId}/reschedule`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function schoolRescheduleEvent(authToken, eventId, data) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/events/${eventId}/reschedule`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function schoolUpdateEvent(authToken, eventId, data) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/events/${eventId}/update_status`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function universityConfirmEvent(authToken, eventId, data) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/events/${eventId}/update_status`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function schoolUpdateEventLocation(
  authToken,
  universityId,
  data,
) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/schools/${universityId}/update_event_location`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getEventsList(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/events/mobile_event_list`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getEventDetails(authToken, id, pageNo) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/events/${id}/mobile_event_detail?page_no=${pageNo}`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function addStudentInEvent(authToken, eventId, studentId) {
  const data = { student_id: studentId, webinar_id: eventId };
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/events/mobile_scan_event`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function addNewStudentInEvent(authToken, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/mobile/participants/register_user`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getSchoolList(authToken, webianarId) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/mobile/participants/schools_list?webinar_id=${webianarId}`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function searchByStudentEmail(
  authToken,
  webinarId,
  schoolId,
  email,
) {
  const encodedEmail = encodeURIComponent(email);
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/mobile/participants/student?school_id=${schoolId}&webinar_id=${webinarId}&email=${encodedEmail}`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function linkEmptyQRStudent(
  authToken,
  eventId,
  studentId,
  userId,
) {
  const data = {
    student_id: studentId,
    webinar_id: eventId,
    user_id: userId,
  };
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/events/mobile_scan_event`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}
