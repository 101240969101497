import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  clearPageNotifications: null,

  getPageNotificationsFailure: null,
  getPageNotificationsRequest: ["data", "resolve", "reject"],
  getPageNotificationsSuccess: ["data"],

  markPageNotificationsReadFailure: null,
  markPageNotificationsReadRequest: ["notificationIds"],
  markPageNotificationsReadSuccess: ["notificationIds"],

  markAllPageNotificationsReadFailure: null,
  markAllPageNotificationsReadRequest: null,
  markAllPageNotificationsReadSuccess: null,
});

export const PageNotificationsTypes = Types;
export const PageNotificationsCreators = Creators;
