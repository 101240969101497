import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getCountriesFailure: null,
  getCountriesRequest: ["params"],
  getCountriesSuccess: ["countries"],

  getTeamCountriesFailure: null,
  getTeamCountriesRequest: ["params"],
  getTeamCountriesSuccess: ["countries"],

  getEventsCountriesFailure: null,
  getEventsCountriesRequest: ["params"],
  getEventsCountriesSuccess: ["countries"],

  getTimezonesFailure: null,
  getTimezonesRequest: null,
  getTimezonesSuccess: ["timezones"],

  getRegionsFailure: null,
  getRegionsRequest: null,
  getRegionsSuccess: ["regions"],

  getSchoolSizesFailure: null,
  getSchoolSizesRequest: null,
  getSchoolSizesSuccess: ["schoolSizes"],

  getManagementToolsFailure: null,
  getManagementToolsRequest: null,
  getManagementToolsSuccess: ["managementTools"],

  getMembersFailure: null,
  getMembersRequest: null,
  getMembersSuccess: ["members"],

  getAdminsFailure: null,
  getAdminsRequest: null,
  getAdminsSuccess: ["admins"],

  getEventModificationReasonsFailure: null,
  getEventModificationReasonsRequest: null,
  getEventModificationReasonsSuccess: ["reasons"],

  getEventDeclineReasonsFailure: null,
  getEventDeclineReasonsRequest: null,
  getEventDeclineReasonsSuccess: ["declineReasons"],

  getMiscDataFailure: null,
  getMiscDataRequest: null,
  getMiscDataSuccess: [
    "countries",
    "timezones",
    "regions",
    "schoolSizes",
    "reasons",
  ],

  getPaymentInfoFailure: null,
  getPaymentInfoRequest: ["id"],
  getPaymentInfoSuccess: ["clientId", "orderId"],

  sendSlackNotificationFailure: null,
  sendSlackNotificationRequest: ["params"],
  sendSlackNotificationSuccess: null,
  resetSlackNotificationState: null,

  hideCalendarExpiryMessage: null,
  showCalendarExpiryMessage: null,

  setHasPageLoadTransactionEnded: ["hasPageLoadTransactionEnded"],
});

export const MiscTypes = Types;
export const MiscCreators = Creators;
