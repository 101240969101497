const NO_ACCESS = "no_access";
const PAID = "paid";
const FREE = "free";
const ADDED = "added";
const APPLICANTS = "applicants";
const OFFERED = "offered";
const CONNECTED = "connected";
const CUMULATIVE = "Cumulative";
const MONTHLY = "Monthly";

export {
  NO_ACCESS,
  FREE,
  PAID,
  ADDED,
  APPLICANTS,
  CONNECTED,
  CUMULATIVE,
  MONTHLY,
  OFFERED,
};
