import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  getAppointmentAvailabilitiesFailure: null,
  getAppointmentAvailabilitiesRequest: ["params"],
  getAppointmentAvailabilitiesSuccess: ["availabilities", "school"],

  getZoomMeetingLinkFailure: null,
  getZoomMeetingLinkRequest: ["params"],
  getZoomMeetingLinkSuccess: ["meeting_url","instructions"],

  deleteZoomMeetingLinkRequest : ["params"],

  resetZoomValues: null,

  connectZoomFailure: null,
  connectZoomRequest: null,
  connectZoomSuccess: ["url"],
  resetZoomUrl: null,

  clearSchoolAppointment: null,

  clearSchoolId: null,
  setSchoolId: ["schoolId"],

  setSchoolAppointmentSelection: ["date", "slot"],
});

export const SchoolAppointmentTypes = Types;
export const SchoolAppointmentCreators = Creators;
