import { createReducer } from "reduxsauce";
import {
  UserTypes as Types,
  WebinarTypes as WebTypes,
} from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.user;

// reducer functions
export const authenticationFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const authenticationRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const authenticationSuccess = (state, action) => {
  const { response } = action;

  return {
    ...state,
    data: response,
    showOnboarding: response.is_onboard ? false : true,
    authToken: response.authentication_token,
    loading: false,
  };
};

export const googleSSOFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const googleSSORequest = (state, action) => ({
  ...state,
  loading: true,
});
export const googleSSOSuccess = (state, action) => {
  const { response } = action;

  return {
    ...state,
    data: response,
    showOnboarding: response.is_onboard ? false : true,
    authToken: response.authentication_token,
    loading: false,
  };
};

export const finishOnboardingFailure = (state, action) => ({
  ...state,
});
export const finishOnboardingRequest = (state, action) => ({
  ...state,
});
export const finishOnboardingSuccess = (state, action) => ({
  ...state,
  showOnboarding: false,
});

export const ssoFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const ssoRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const ssoSuccess = (state, action) => {
  const { response } = action;

  return {
    ...state,
    data: response,
    showOnboarding: response.is_onboard ? false : true,
    authToken: response.authentication_token,
    loading: false,
  };
};

export const ssoCialfoFailure = (state, action) => ({ ...state });
export const ssoCialfoRequest = (state, action) => ({ ...state });
export const ssoCialfoSuccess = (state, action) => ({
  ...state,
  ssoUrl: action.url,
});

export const checkSessionFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const checkSessionRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const checkSessionSuccess = (state, action) => {
  const { response } = action;

  return {
    ...state,
    data: response,
    showOnboarding: response.last_login ? false : true,
    authToken: response.authentication_token,
    loading: false,
  };
};

export const confirmEmailIdFailure = (state, action) => ({
  ...state,
  validatedUserPartnerType: null,
});
export const confirmEmailIdRequest = (state, action) => ({
  ...state,
  validatedUserPartnerType: null,
});
export const confirmEmailIdSuccess = (state, action) => ({
  ...state,
  validatedUserPartnerType: action.partnerType,
});

export const sendResetPasswordMailFailure = (state, action) => state;
export const sendResetPasswordMailRequest = (state, action) => state;
export const sendResetPasswordMailSuccess = (state, action) => state;

export const requestDemoFailure = (state, action) => state;
export const requestDemoRequest = (state, action) => state;
export const requestDemoSuccess = (state, action) => state;

export const resetPasswordFailure = (state, action) => state;
export const resetPasswordRequest = (state, action) => state;
export const resetPasswordSuccess = (state, action) => state;

export const registerUserFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const registerUserRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const registerUserSuccess = (state, action) => ({
  ...state,
  loading: false,
});

export const updateUserFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const updateUserRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const updateUserSuccess = (state, action) => ({
  ...state,
  data: { ...state.data, ...action.user },
  loading: false,
});

export const updateUserTimezoneFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const updateUserTimezoneRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const updateUserTimezoneSuccess = (state, action) => ({
  ...state,
  data: { ...state.data, ...action.user },
  loading: false,
});

export const updatePasswordFailure = (state, action) => ({
  ...state,
  updatingPassword: false,
});
export const updatePasswordRequest = (state, action) => ({
  ...state,
  updatingPassword: true,
});
export const updatePasswordSuccess = (state, action) => ({
  ...state,
  updatingPassword: false,
});

export const updateEmailSettingsFailure = (state, action) => ({
  ...state,
  updatingEmailSettings: false,
});
export const updateEmailSettingsRequest = (state, action) => ({
  ...state,
  updatingEmailSettings: true,
});
export const updateEmailSettingsSuccess = (state, action) => ({
  ...state,
  updatingEmailSettings: false,
  data: {
    ...state.data,
    preferences: action.preferences.preferences,
  },
});

export const acceptPrivacyPolicyFailure = (state, action) => ({
  ...state,
  updatingPrivacyPolicyAcceptance: false,
});
export const acceptPrivacyPolicyRequest = (state, action) => ({
  ...state,
  updatingPrivacyPolicyAcceptance: true,
});
export const acceptPrivacyPolicySuccess = (state, action) => {
  const { user } = action;

  return {
    ...state,
    updatingPrivacyPolicyAcceptance: false,
    data: user,
    showOnboarding: user.last_login ? false : true,
    authToken: user.authentication_token,
  };
};

export const updateUserLocaleFailure = (state, action) => ({
  ...state,
});
export const updateUserLocaleRequest = (state, action) => ({
  ...state,
});
export const updateUserLocaleSuccess = (state, action) => ({
  ...state,
  data: { ...state.data, ...action.user },
});

export const updateAppLocale = (state, action) => ({
  ...state,
  locale: action.locale,
});

export const updateSettingsTabIndex = (state, action) => ({
  ...state,
  settingsTabIndex: action.settingsTabIndex,
});

export const getZoomAccountRequest = (state, action) => ({
  ...state,
  zoom: {
    ...state.zoom,
  },
});
export const getZoomAccountSuccess = (state, action) => ({
  ...state,
  zoom: {
    ...state.zoom,
    isConnected: action.isZoomConnected,
    account: action.zoomAccount,
  },
});
export const getZoomAccountFailure = (state, action) => ({
  ...state,
  zoom: {
    ...state.zoom,
  },
});

export const getZoomAuthUrlRequest = (state, action) => ({
  ...state,
  zoom: {
    ...state.zoom,
  },
});
export const getZoomAuthUrlSuccess = (state, action) => ({
  ...state,
  zoom: {
    ...state.zoom,
    authUrl: action.zoomAuthUrl,
  },
});
export const getZoomAuthUrlFailure = (state, action) => ({
  ...state,
  zoom: {
    ...state.zoom,
  },
});

export const getSisenseUrlRequest = (state, action) => ({
  ...state,
  sisense: {
    ...state.sisense,
    url: null,
  },
});

export const getSisenseUrlSuccess = (state, action) => ({
  ...state,
  sisense: {
    ...state.sisense,
    url: action.sisenseUrl,
  },
});

export const getSisenseUrlFailure = (state, action) => ({
  ...state,
  sisense: {
    ...state.sisense,
    url: null,
  },
});

export const getSisenseDashboardsRequest = state => ({
  ...state,
  sisense: {
    ...state.sisense,
  },
});

export const getSisenseDashboardsSuccess = (state, action) => ({
  ...state,
  sisense: {
    ...state.sisense,
    dashboards: action.dashboards,
  },
});

export const getSisenseDashboardsFailure = state => ({
  ...state,
  sisense: {
    ...state.sisense,
    dashboards: [],
  },
});

export const getMetabaseUrlRequest = state => ({
  ...state,
  metabase: {
    ...state.metabase,
    url: null,
  },
});

export const getMetabaseUrlSuccess = (state, action) => ({
  ...state,
  metabase: {
    ...state.metabase,
    url: action.metabaseUrl,
  },
});

export const getMetabaseUrlFailure = state => ({
  ...state,
  metabase: {
    ...state.metabase,
    url: null,
  },
});

export const getMetabaseDashboardsRequest = state => ({
  ...state,
  metabase: {
    ...state.metabase,
  },
});

export const getMetabaseDashboardsSuccess = (state, action) => {
  return {
    ...state,
    metabase: {
      ...state.metabase,
      dashboards: action.dashboards,
    },
  };
};

export const getMetabaseDashboardsFailure = state => ({
  ...state,
  metabase: {
    ...state.metabase,
    dashboards: [],
  },
});

export const updateZoomDetailsRequest = (state, action) => ({
  ...state,
  zoom: {
    ...state.zoom,
    updatingZoomDetails: true,
  },
});
export const updateZoomDetailsSuccess = (state, action) => ({
  ...state,
  zoom: {
    ...state.zoom,
    updatingZoomDetails: false,
    account: action.zoomDetails.zoom_email,
    isConnected: action.zoomDetails.is_zoom_connected,
  },
});
export const updateZoomDetailsFailure = (state, action) => ({
  ...state,
  zoom: {
    ...state.zoom,
    updatingZoomDetails: false,
  },
});

const logout = (state, action) => ({
  ...INITIAL_STATE,
  isWebinarLogout: action.isWebinarLogout,
  locale: state.locale,
});

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.AUTHENTICATION_FAILURE]: authenticationFailure,
  [Types.AUTHENTICATION_REQUEST]: authenticationRequest,
  [Types.AUTHENTICATION_SUCCESS]: authenticationSuccess,
  [WebTypes.AUTHENTICATION_WEBINAR_SUCCESS]: authenticationSuccess,

  [Types.GOOGLE_SSO_FAILURE]: googleSSOFailure,
  [Types.GOOGLE_SSO_REQUEST]: googleSSORequest,
  [Types.GOOGLE_SSO_SUCCESS]: googleSSOSuccess,

  [Types.FINISH_ONBOARDING_FAILURE]: finishOnboardingFailure,
  [Types.FINISH_ONBOARDING_REQUEST]: finishOnboardingRequest,
  [Types.FINISH_ONBOARDING_SUCCESS]: finishOnboardingSuccess,

  [Types.SSO_FAILURE]: ssoFailure,
  [Types.SSO_REQUEST]: ssoRequest,
  [Types.SSO_SUCCESS]: ssoSuccess,

  [Types.SSO_CIALFO_FAILURE]: ssoCialfoFailure,
  [Types.SSO_CIALFO_REQUEST]: ssoCialfoRequest,
  [Types.SSO_CIALFO_SUCCESS]: ssoCialfoSuccess,

  [Types.CHECK_SESSION_FAILURE]: checkSessionFailure,
  [Types.CHECK_SESSION_REQUEST]: checkSessionRequest,
  [Types.CHECK_SESSION_SUCCESS]: checkSessionSuccess,

  [Types.CONFIRM_EMAIL_ID_FAILURE]: confirmEmailIdFailure,
  [Types.CONFIRM_EMAIL_ID_REQUEST]: confirmEmailIdRequest,
  [Types.CONFIRM_EMAIL_ID_SUCCESS]: confirmEmailIdSuccess,

  [Types.SEND_RESET_PASSWORD_MAIL_FAILURE]:
    sendResetPasswordMailFailure,
  [Types.SEND_RESET_PASSWORD_MAIL_REQUEST]:
    sendResetPasswordMailRequest,
  [Types.SEND_RESET_PASSWORD_MAIL_SUCCESS]:
    sendResetPasswordMailSuccess,

  [Types.REQUEST_DEMO_FAILURE]: requestDemoFailure,
  [Types.REQUEST_DEMO_REQUEST]: requestDemoRequest,
  [Types.REQUEST_DEMO_SUCCESS]: requestDemoSuccess,

  [Types.RESET_PASSWORD_FAILURE]: resetPasswordFailure,
  [Types.RESET_PASSWORD_REQUEST]: resetPasswordRequest,
  [Types.RESET_PASSWORD_SUCCESS]: resetPasswordSuccess,

  [Types.REGISTER_USER_FAILURE]: registerUserFailure,
  [Types.REGISTER_USER_REQUEST]: registerUserRequest,
  [Types.REGISTER_USER_SUCCESS]: registerUserSuccess,

  [Types.UPDATE_USER_FAILURE]: updateUserFailure,
  [Types.UPDATE_USER_REQUEST]: updateUserRequest,
  [Types.UPDATE_USER_SUCCESS]: updateUserSuccess,
  [WebTypes.UPDATE_WEBINAR_USER_SUCCESS]: updateUserSuccess,

  [Types.UPDATE_USER_TIMEZONE_FAILURE]: updateUserTimezoneFailure,
  [Types.UPDATE_USER_TIMEZONE_REQUEST]: updateUserTimezoneRequest,
  [Types.UPDATE_USER_TIMEZONE_SUCCESS]: updateUserTimezoneSuccess,

  [Types.UPDATE_PASSWORD_FAILURE]: updatePasswordFailure,
  [Types.UPDATE_PASSWORD_REQUEST]: updatePasswordRequest,
  [Types.UPDATE_PASSWORD_SUCCESS]: updatePasswordSuccess,

  [Types.ACCEPT_PRIVACY_POLICY_FAILURE]: acceptPrivacyPolicyFailure,
  [Types.ACCEPT_PRIVACY_POLICY_REQUEST]: acceptPrivacyPolicyRequest,
  [Types.ACCEPT_PRIVACY_POLICY_SUCCESS]: acceptPrivacyPolicySuccess,

  [Types.UPDATE_USER_LOCALE_FAILURE]: updateUserLocaleFailure,
  [Types.UPDATE_USER_LOCALE_REQUEST]: updateUserLocaleRequest,
  [Types.UPDATE_USER_LOCALE_SUCCESS]: updateUserLocaleSuccess,

  [Types.UPDATE_APP_LOCALE]: updateAppLocale,

  [Types.UPDATE_EMAIL_SETTINGS_FAILURE]: updateEmailSettingsFailure,
  [Types.UPDATE_EMAIL_SETTINGS_REQUEST]: updateEmailSettingsRequest,
  [Types.UPDATE_EMAIL_SETTINGS_SUCCESS]: updateEmailSettingsSuccess,

  [Types.UPDATE_SETTINGS_TAB_INDEX]: updateSettingsTabIndex,

  [Types.GET_ZOOM_ACCOUNT_REQUEST]: getZoomAccountRequest,
  [Types.GET_ZOOM_ACCOUNT_SUCCESS]: getZoomAccountSuccess,
  [Types.GET_ZOOM_ACCOUNT_FAILURE]: getZoomAccountFailure,

  [Types.GET_ZOOM_AUTH_URL_REQUEST]: getZoomAuthUrlRequest,
  [Types.GET_ZOOM_AUTH_URL_SUCCESS]: getZoomAuthUrlSuccess,
  [Types.GET_ZOOM_AUTH_URL_FAILURE]: getZoomAuthUrlFailure,

  [Types.UPDATE_ZOOM_DETAILS_REQUEST]: updateZoomDetailsRequest,
  [Types.UPDATE_ZOOM_DETAILS_SUCCESS]: updateZoomDetailsSuccess,
  [Types.UPDATE_ZOOM_DETAILS_FAILURE]: updateZoomDetailsFailure,

  [Types.GET_SISENSE_URL_REQUEST]: getSisenseUrlRequest,
  [Types.GET_SISENSE_URL_SUCCESS]: getSisenseUrlSuccess,
  [Types.GET_SISENSE_URL_FAILURE]: getSisenseUrlFailure,

  [Types.GET_SISENSE_DASHBOARDS_REQUEST]: getSisenseDashboardsRequest,
  [Types.GET_SISENSE_DASHBOARDS_SUCCESS]: getSisenseDashboardsSuccess,
  [Types.GET_SISENSE_DASHBOARDS_FAILURE]: getSisenseDashboardsFailure,

  [Types.GET_METABASE_URL_REQUEST]: getMetabaseUrlRequest,
  [Types.GET_METABASE_URL_SUCCESS]: getMetabaseUrlSuccess,
  [Types.GET_METABASE_URL_FAILURE]: getMetabaseUrlFailure,

  [Types.GET_METABASE_DASHBOARDS_REQUEST]:
    getMetabaseDashboardsRequest,
  [Types.GET_METABASE_DASHBOARDS_SUCCESS]:
    getMetabaseDashboardsSuccess,
  [Types.GET_METABASE_DASHBOARDS_FAILURE]:
    getMetabaseDashboardsFailure,

  [Types.LOGOUT]: logout,
};

export default createReducer(INITIAL_STATE, HANDLERS);
