import { createReducer } from "reduxsauce";
import pick from "lodash/pick";
import { EventsTypes as Types, UserTypes } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.events;

export const clearEvents = (state, action) => ({
  ...state,
  data: [],
});

export const createEventFailure = (state, action) => ({
  ...state,
  adding: false,
});
export const createEventRequest = (state, action) => ({
  ...state,
  adding: true,
});
export const createEventSuccess = (state, action) => ({
  ...state,
  adding: false,
});

export const downloadEventsFailure = (state, action) => ({
  ...state,
  downloadUrl: null,
  downloading: false,
});
export const downloadEventsRequest = (state, action) => ({
  ...state,
  downloadUrl: null,
  downloading: true,
});
export const downloadEventsSuccess = (state, action) => ({
  ...state,
  downloadUrl: action.url,
  downloading: false,
});

export const getEventsFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getEventsRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getEventsSuccess = (state, action) => {
  const {
    eventsData: { events, matched, total, total_without_group },
    params,
  } = action;

  return {
    ...state,
    data: params.page_no === 1 ? events : [...state.data, ...events],
    matched,
    total_without_group,
    total,
    params: { ...state.params, ...params },
    loading: false,
  };
};

export const getEventFailure = (state, action) => ({
  ...state,
  loadingSelected: false,
});
export const getEventRequest = (state, action) => ({
  ...state,
  loadingSelected: true,
  selected: null,
});
export const getEventSuccess = (state, action) => ({
  ...state,
  loadingSelected: false,
  selected: action.eventData,
});

export const rescheduleEventFailure = (state, action) => ({
  ...state,
  rescheduling: false,
});
export const rescheduleEventRequest = (state, action) => ({
  ...state,
  rescheduling: true,
});
export const rescheduleEventSuccess = (state, action) => {
  const { eventData } = action;

  return {
    ...state,
    data: state.data.map(event =>
      event.id === eventData.id
        ? {
            ...event,
            ...pick(eventData, [
              "status",
              "event_date",
              "time_from",
              "time_to",
              "timezone",
            ]),
          }
        : event,
    ),
    selected: eventData,
    rescheduling: false,
  };
};

export const confirmEventFailure = (state, action) => ({
  ...state,
  confirming: false,
});
export const confirmEventRequest = (state, action) => ({
  ...state,
  confirming: true,
});
export const confirmEventSuccess = (state, action) => {
  const { eventData } = action;

  return {
    ...state,
    data: state.data.map(event =>
      event.id === eventData.id
        ? {
            ...event,
            ...pick(eventData, [
              "status",
              "event_date",
              "time_from",
              "time_to",
            ]),
          }
        : event,
    ),
    selected: eventData,
    confirming: false,
  };
};

export const cancelEventFailure = (state, action) => ({
  ...state,
  cancelling: false,
});
export const cancelEventRequest = (state, action) => ({
  ...state,
  cancelling: true,
});
export const cancelEventSuccess = (state, action) => {
  const { eventData } = action;

  return {
    ...state,
    data: state.data.map(event =>
      event.id === eventData.id
        ? {
            ...event,
            ...pick(eventData, [
              "status",
              "event_date",
              "time_from",
              "time_to",
            ]),
          }
        : event,
    ),
    selected: eventData,
    cancelling: false,
  };
};

export const updateLocationFailure = (state, action) => ({
  ...state,
  updating: false,
});
export const updateLocationRequest = (state, action) => ({
  ...state,
  updating: true,
});
export const updateLocationSuccess = (state, action) => {
  const { eventData } = action;

  return {
    ...state,
    data: state.data.map(event =>
      event.id === eventData.id
        ? {
            ...event,
            ...pick(eventData, [
              "status",
              "event_date",
              "time_from",
              "time_to",
            ]),
          }
        : event,
    ),
    selected: eventData,
    updating: false,
  };
};

const logout = (state, action) => INITIAL_STATE;

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.CLEAR_EVENTS]: clearEvents,
  [Types.CREATE_EVENT_FAILURE]: createEventFailure,
  [Types.CREATE_EVENT_REQUEST]: createEventRequest,
  [Types.CREATE_EVENT_SUCCESS]: createEventSuccess,
  [Types.DOWNLOAD_EVENTS_FAILURE]: downloadEventsFailure,
  [Types.DOWNLOAD_EVENTS_REQUEST]: downloadEventsRequest,
  [Types.DOWNLOAD_EVENTS_SUCCESS]: downloadEventsSuccess,
  [Types.GET_EVENTS_FAILURE]: getEventsFailure,
  [Types.GET_EVENTS_REQUEST]: getEventsRequest,
  [Types.GET_EVENTS_SUCCESS]: getEventsSuccess,
  [Types.GET_EVENT_FAILURE]: getEventFailure,
  [Types.GET_EVENT_REQUEST]: getEventRequest,
  [Types.GET_EVENT_SUCCESS]: getEventSuccess,
  [Types.RESCHEDULE_EVENT_FAILURE]: rescheduleEventFailure,
  [Types.RESCHEDULE_EVENT_REQUEST]: rescheduleEventRequest,
  [Types.RESCHEDULE_EVENT_SUCCESS]: rescheduleEventSuccess,
  [Types.CONFIRM_EVENT_FAILURE]: confirmEventFailure,
  [Types.CONFIRM_EVENT_REQUEST]: confirmEventRequest,
  [Types.CONFIRM_EVENT_SUCCESS]: confirmEventSuccess,
  [Types.CANCEL_EVENT_FAILURE]: cancelEventFailure,
  [Types.CANCEL_EVENT_REQUEST]: cancelEventRequest,
  [Types.CANCEL_EVENT_SUCCESS]: cancelEventSuccess,
  [Types.UPDATE_LOCATION_FAILURE]: updateLocationFailure,
  [Types.UPDATE_LOCATION_REQUEST]: updateLocationRequest,
  [Types.UPDATE_LOCATION_SUCCESS]: updateLocationSuccess,
  [UserTypes.LOGOUT]: logout,
};

export default createReducer(INITIAL_STATE, HANDLERS);
