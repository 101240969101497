import { call, put, takeEvery, select } from "redux-saga/effects";
import { Utility } from "src/helpers/utility";
import {
  getAppointmentAvailabilities,
  getZoomMeetingLink,
  deleteZoomLink,
  connectZoom,
} from "src/web-services";
import {
  SchoolAppointmentCreators,
  SchoolAppointmentTypes,
} from "../actions";

export function* requestGetAppointmentAvailabilities(action) {
  try {
    const { params } = action;

    const response = yield call(getAppointmentAvailabilities, params);
    yield put(
      SchoolAppointmentCreators.getAppointmentAvailabilitiesSuccess(
        response.data.availabilities,
        response.data.school,
      ),
    );
  } catch (error) {
    yield put(
      SchoolAppointmentCreators.getAppointmentAvailabilitiesFailure(),
    );
  }
}

export function* requestGetZoomMeetingLink(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getZoomMeetingLink,
      authToken,
      params,
    );
    yield put(
      SchoolAppointmentCreators.getZoomMeetingLinkSuccess(
        response.data.meeting_url,
        response.data.instructions,
      ),
    );
  } catch (error) {
    yield put(SchoolAppointmentCreators.getZoomMeetingLinkFailure());
  }
}

export function* requestDeleteZoomMeetingLink(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    yield call(deleteZoomLink, authToken, params);
  } catch (error) {
    console.log(error);
  }
}

export function* requestConnectZoom(action) {
  try {
    const coreUser = Utility.isCoreUser();
    if (coreUser) {
      localStorage.setItem("core", "true");
    }
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(connectZoom, authToken, params);
    yield put(
      SchoolAppointmentCreators.connectZoomSuccess(response.data.url),
    );
  } catch (error) {
    yield put(SchoolAppointmentCreators.connectZoomFailure());
  }
}

export function* watchScholAppointmentRequests() {
  yield takeEvery(
    SchoolAppointmentTypes.GET_APPOINTMENT_AVAILABILITIES_REQUEST,
    requestGetAppointmentAvailabilities,
  );
  yield takeEvery(
    SchoolAppointmentTypes.GET_ZOOM_MEETING_LINK_REQUEST,
    requestGetZoomMeetingLink,
  );
  yield takeEvery(
    SchoolAppointmentTypes.DELETE_ZOOM_MEETING_LINK_REQUEST,
    requestDeleteZoomMeetingLink,
  );
  yield takeEvery(
    SchoolAppointmentTypes.CONNECT_ZOOM_REQUEST,
    requestConnectZoom,
  );
}
