import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  clearApplicationFilters: null,

  setApplicationFilters: ["filters"],

  setDirectApplySearchText: ["text"],

  setDirectApplySortBy: ["sortBy"],

  setDirectApplyTab: ["tab"],
});

export const DirectApplyFiltersTypes = Types;
export const DirectApplyFiltersCreators = Creators;
