/* eslint-disable camelcase */
/* eslint-disable no-unused-expressions */
import { call, put, takeEvery, select } from "redux-saga/effects";
import {
  getPageNotifications,
  markNotificationsRead,
} from "src/web-services";
import {
  PageNotificationsCreators,
  PageNotificationsTypes,
  NotificationsCreators,
} from "../actions";

const pageNotificationsSelector = state => state.pageNotifications;

export function* requestGetPageNotifications(action) {
  try {
    const { data, resolve } = action;
    const {
      loadMore,
      notification_type,
      is_read: is_read_filter,
      search_text,
    } = data;
    const userSelector = state => state.user;
    const {
      authToken,
      data: { id: userId },
    } = yield select(userSelector);
    const { page_no } = yield select(pageNotificationsSelector);

    if (!loadMore)
      yield put(PageNotificationsCreators.clearPageNotifications());

    const params = {
      page_no: loadMore ? page_no + 1 : 1,
      notification_type,
      search_text,
    };

    if (is_read_filter !== "") {
      params.is_read = is_read_filter === "read";
    }

    const response = yield call(
      getPageNotifications,
      authToken,
      userId,
      params,
    );
    const {
      total,
      filter_count,
      notifications,
      read_count,
      unread_count,
    } = response.data;
    resolve && resolve();
    yield put(
      PageNotificationsCreators.getPageNotificationsSuccess({
        total,
        filter_count,
        notifications,
        read_count,
        unread_count,
        page_no: params.page_no,
      }),
    );
  } catch (error) {
    const { reject } = action;
    reject && reject(error);
    yield put(
      PageNotificationsCreators.getPageNotificationsFailure(),
    );
  }
}

export function* requestMarkPageNotificationsRead(action) {
  try {
    const { notificationIds } = action;
    const userSelector = state => state.user;
    const {
      authToken,
      data: { id: userId },
    } = yield select(userSelector);
    const requestParams = new FormData();
    notificationIds.forEach(notificationId =>
      requestParams.append("notifications[ids][]", notificationId),
    );

    yield call(
      markNotificationsRead,
      authToken,
      userId,
      requestParams,
    );
    yield put(
      NotificationsCreators.markNotificationsReadSuccess(
        notificationIds,
      ),
    );
    yield put(
      PageNotificationsCreators.markPageNotificationsReadSuccess(
        notificationIds,
      ),
    );
  } catch (error) {
    yield put(
      PageNotificationsCreators.markPageNotificationsReadFailure(),
    );
  }
}

export function* requestMarkAllPageNotificationsRead(action) {
  try {
    const userSelector = state => state.user;
    const {
      authToken,
      data: { id: userId },
    } = yield select(userSelector);
    const requestParams = new FormData();
    requestParams.append("notifications[read_all]", true);

    yield call(
      markNotificationsRead,
      authToken,
      userId,
      requestParams,
    );
    yield put(
      NotificationsCreators.markAllNotificationsReadSuccess(),
    );
    yield put(
      PageNotificationsCreators.markAllPageNotificationsReadSuccess(),
    );
  } catch (error) {
    yield put(
      PageNotificationsCreators.markAllPageNotificationsReadFailure(),
    );
  }
}

export function* watchPageNotificationRequests() {
  yield takeEvery(
    PageNotificationsTypes.GET_PAGE_NOTIFICATIONS_REQUEST,
    requestGetPageNotifications,
  );
  yield takeEvery(
    PageNotificationsTypes.MARK_PAGE_NOTIFICATIONS_READ_REQUEST,
    requestMarkPageNotificationsRead,
  );
  yield takeEvery(
    PageNotificationsTypes.MARK_ALL_PAGE_NOTIFICATIONS_READ_REQUEST,
    requestMarkAllPageNotificationsRead,
  );
}
