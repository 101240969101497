import { API } from "../helpers";
import { SERVER_URL } from "../config";

//TODO: remove this
export function getNotifications(authToken, userId, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/users/${userId}/notifications`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getPageNotifications(authToken, userId, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/users/${userId}/notifications`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function markNotificationsRead(authToken, userId, data) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/users/${userId}/notifications/mark_read`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}
