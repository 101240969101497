import { API } from "../helpers";
import { SERVER_URL } from "../config";

export function getSessions(authToken, filter) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/partner_webinars?filter=${filter}`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getSession(authToken, id) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/partner_webinars/${id}`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function updateSession(authToken, id, data) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/partner_webinars/${id}`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}
