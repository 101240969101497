import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  authenticationFailure: null,
  authenticationRequest: ["params", "resolve", "reject"],
  authenticationSuccess: ["response"],

  finishOnboardingFailure: null,
  finishOnboardingRequest: null,
  finishOnboardingSuccess: null,

  ssoFailure: null,
  ssoRequest: ["params"],
  ssoSuccess: ["response"],

  googleSSOFailure: null,
  googleSSORequest: ["code", "resolve", "reject"],
  googleSSOSuccess: ["response"],

  ssoCialfoFailure: null,
  ssoCialfoRequest: null,
  ssoCialfoSuccess: ["url"],

  checkSessionFailure: null,
  checkSessionRequest: null,
  checkSessionSuccess: ["response"],

  confirmEmailIdFailure: null,
  confirmEmailIdRequest: ["params"],
  confirmEmailIdSuccess: ["partnerType"],

  sendResetPasswordMailFailure: null,
  sendResetPasswordMailRequest: ["params"],
  sendResetPasswordMailSuccess: null,

  requestDemoFailure: null,
  requestDemoRequest: null,
  requestDemoSuccess: null,

  resetPasswordFailure: null,
  resetPasswordRequest: ["params", "resolve", "reject"],
  resetPasswordSuccess: null,

  registerUserFailure: null,
  registerUserRequest: ["params"],
  registerUserSuccess: ["response"],

  updateUserFailure: null,
  updateUserRequest: ["params", "resolve", "reject"],
  updateUserSuccess: ["user"],

  updateUserTimezoneFailure: null,
  updateUserTimezoneRequest: ["params"],
  updateUserTimezoneSuccess: ["user"],

  updatePasswordFailure: null,
  updatePasswordRequest: ["params", "resolve", "reject"],
  updatePasswordSuccess: null,

  acceptPrivacyPolicyFailure: null,
  acceptPrivacyPolicyRequest: ["params"],
  acceptPrivacyPolicySuccess: ["user"],

  updateUserLocaleFailure: null,
  updateUserLocaleRequest: ["locale"],
  updateUserLocaleSuccess: ["user"],

  updateAppLocale: ["locale"],

  updateEmailSettingsRequest: ["params", "resolve", "reject"],
  updateEmailSettingsSuccess: ["preferences"],
  updateEmailSettingsFailure: null,

  updateSettingsTabIndex: ["settingsTabIndex"],

  getZoomAccountRequest: null,
  getZoomAccountSuccess: ["isZoomConnected", "zoomAccount"],
  getZoomAccountFailure: null,

  getZoomAuthUrlRequest: null,
  getZoomAuthUrlSuccess: ["zoomAuthUrl"],
  getZoomAuthUrlFailure: null,

  getSisenseUrlRequest: ["params", "resolve", "reject"],
  getSisenseUrlSuccess: ["sisenseUrl"],
  getSisenseUrlFailure: null,

  getSisenseDashboardsRequest: null,
  getSisenseDashboardsSuccess: ["dashboards"],
  getSisenseDashboardsFailure: null,

  getMetabaseUrlRequest: ["params", "resolve", "reject"],
  getMetabaseUrlSuccess: ["metabaseUrl"],
  getMetabaseUrlFailure: null,

  getMetabaseDashboardsRequest: null,
  getMetabaseDashboardsSuccess: ["dashboards"],
  getMetabaseDashboardsFailure: null,

  updateZoomDetailsRequest: ["params"],
  updateZoomDetailsSuccess: ["zoomDetails"],
  updateZoomDetailsFailure: null,

  logout: ["isWebinarLogout"],
});

export const UserTypes = Types;
export const UserCreators = Creators;
