import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  clearEvents: null,
  createEventFailure: null,
  createEventRequest: ["params", "resolve", "reject"],
  createEventSuccess: ["event"],
  downloadEventsFailure: null,
  downloadEventsRequest: ["params"],
  downloadEventsSuccess: ["url"],
  getEventsFailure: null,
  getEventsRequest: ["params", "resolve", "reject"],
  getEventsSuccess: ["eventsData", "params"],
  getEventFailure: null,
  getEventRequest: ["params"],
  getEventSuccess: ["eventData"],
  rescheduleEventFailure: null,
  rescheduleEventRequest: ["params"],
  rescheduleEventSuccess: ["eventData"],
  confirmEventFailure: null,
  confirmEventRequest: ["params"],
  confirmEventSuccess: ["eventData"],
  cancelEventFailure: null,
  cancelEventRequest: ["params"],
  cancelEventSuccess: ["eventData"],
  updateLocationFailure: null,
  updateLocationRequest: ["params"],
  updateLocationSuccess: ["eventData"],
});

export const EventsTypes = Types;
export const EventsCreators = Creators;
