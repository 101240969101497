import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  clearConnections: null,

  getConnectionsRequest: ["params", "resolve", "reject"],
  getConnectionsSuccess: ["connectionsData", "params"],
  getConnectionsFailure: null,

  getConnectionCountriesFailure: null,
  getConnectionCountriesRequest: ["params"],
  getConnectionCountriesSuccess: ["response"],

  getConnectionCitizenshipFailure: null,
  getConnectionCitizenshipRequest: ["params"],
  getConnectionCitizenshipSuccess: ["response"],

  getConnectionWebinarsFailure: null,
  getConnectionWebinarsRequest: ["params"],
  getConnectionWebinarsSuccess: ["response"],

  getConnectionGraduationYearFailure: null,
  getConnectionGraduationYearRequest: ["params"],
  getConnectionGraduationYearSuccess: ["response"],

  getConnectionDetailsRequest: ["id"],
  getConnectionDetailsSuccess: ["response"],
  getConnectionDetailsFailure: null,

  getEngagementDataRequest: ["id", "params", "resolve", "reject"],
  getEngagementDataSuccess: ["response", "params", "id"],
  getEngagementDataFailure: null,

  getConnectionSourcesFailure: null,
  getConnectionSourcesRequest: ["params"],
  getConnectionSourcesSuccess: ["response"],

  getConnectionActivitiesFailure: null,
  getConnectionActivitiesRequest: ["params"],
  getConnectionActivitiesSuccess: ["response"],

  getConnectionStatusesFailure: null,
  getConnectionStatusesRequest: ["params"],
  getConnectionStatusesSuccess: ["response"],

  downloadConnectionsRequest: ["params", "resolve", "reject"],
  downloadConnectionsSuccess: ["response"],
  downloadConnectionsFailure: null,

  hideBanner: null,

  hideCountsRequest: ["params", "resolve", "reject"],
  hideCountsSuccess: ["response"],
  hideCountsFailure: null,

  getConnectionSchoolStatesFailure: null,
  getConnectionSchoolStatesRequest: ["params"],
  getConnectionSchoolStatesSuccess: ["response"],

  getConnectionSchoolNamesFailure: null,
  getConnectionSchoolNamesRequest: ["params"],
  getConnectionSchoolNamesSuccess: ["response"],

  getConnectionSchoolCityFailure: null,
  getConnectionSchoolCityRequest: ["params"],
  getConnectionSchoolCitySuccess: ["response"],

  getConnectionCourseInterestFailure: null,
  getConnectionCourseInterestRequest: ["params"],
  getConnectionCourseInterestSuccess: ["response"],

  getConnectionAcademicInterestFailure: null,
  getConnectionAcademicInterestRequest: ["params"],
  getConnectionAcademicInterestSuccess: ["response"],

  getConnectionEventTitleFailure: null,
  getConnectionEventTitleRequest: ["params"],
  getConnectionEventTitleSuccess: ["response"],

  resetEngagementData: null,
});

export const ConnectionsTypes = Types;
export const ConnectionsCreators = Creators;
