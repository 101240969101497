/* eslint-disable no-unused-vars */
import { createReducer } from "reduxsauce";
import { DirectApplyTypes as Types, UserTypes } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.directApply;

export const clearApplications = (state, action) => ({
  ...state,
  data: [],
});

export const getApplicationFailure = (state, action) => ({
  ...state,
  loadingSelected: false,
});
export const getApplicationRequest = (state, action) => ({
  ...state,
  loadingSelected: true,
  selected: null,
});
export const getApplicationSuccess = (state, action) => {
  const { data } = action;
  return {
    ...state,
    loadingSelected: false,
    selected: data.application,
    studentId: data.student.user_id,
  };
};

export const updateApplicationFailure = (state, action) => ({
  ...state,
});
export const updateApplicationRequest = (state, action) => ({
  ...state,
});
export const updateApplicationSuccess = (state, action) => ({
  ...state,
  selected: {
    ...state.selected,
    ...action.updatedApplicationAttributes,
  },
});

export const updateApplicationStatusFailure = (state, action) => ({
  ...state,
});
export const updateApplicationStatusRequest = (state, action) => ({
  ...state,
});
export const updateApplicationStatusSuccess = (state, action) => ({
  ...state,
  selected: {
    ...state.selected,
    ...action.updatedApplicationAttributes,
  },
});

export const updateApplicationResultFailure = (state, action) => ({
  ...state,
});
export const updateApplicationResultRequest = (state, action) => ({
  ...state,
});
export const updateApplicationResultSuccess = (state, action) => ({
  ...state,
  selected: {
    ...state.selected,
    ...action.updatedApplicationAttributes,
  },
});

export const resetChoiceFailure = (state, action) => ({
  ...state,
});
export const resetChoiceRequest = (state, action) => ({
  ...state,
});
export const resetChoiceSuccess = (state, action) => ({
  ...state,
});

export const updateApplicationDepositPaidFailure = (
  state,
  action,
) => ({ ...state });
export const updateApplicationDepositPaidRequest = (
  state,
  action,
) => ({ ...state });
export const updateApplicationDepositPaidSuccess = (
  state,
  action,
) => ({
  ...state,
  selected: {
    ...state.selected,
    ...action.updatedApplicationAttributes,
  },
});

export const updateApplicationUcasChoiceFailure = (
  state,
  action,
) => ({ ...state });
export const updateApplicationUcasChoiceRequest = (
  state,
  action,
) => ({ ...state });
export const updateApplicationUcasChoiceSuccess = (
  state,
  action,
) => ({
  ...state,
  selected: {
    ...state.selected,
    ...action.updatedApplicationAttributes,
  },
});

export const getApplicationsFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getApplicationsRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getApplicationsSuccess = (state, action) => {
  const {
    applications,
    matched,
    total,
    pastTotal,
    params,
    data_count,
  } = action;

  return {
    ...state,
    data:
      params.page_no === 1
        ? applications
        : [...state.data, ...applications],
    matched,
    total,
    pastTotal,
    data_count,
    params: { ...state.params, ...params },
    loading: false,
  };
};

export const getDirectApplyFilterCountriesFailure = (
  state,
  action,
) => ({ ...state });
export const getDirectApplyFilterCountriesRequest = (
  state,
  action,
) => ({ ...state });
export const getDirectApplyFilterCountriesSuccess = (
  state,
  action,
) => ({ ...state, countries: action.countries });

export const getDirectApplyFilterCoursesFailure = (
  state,
  action,
) => ({ ...state });
export const getDirectApplyFilterCoursesRequest = (
  state,
  action,
) => ({ ...state });
export const getDirectApplyFilterCoursesSuccess = (
  state,
  action,
) => ({ ...state, courses: action.courses });

export const getDirectApplyFilterProgramsFailure = (
  state,
  action,
) => ({ ...state });
export const getDirectApplyFilterProgramsRequest = (
  state,
  action,
) => ({ ...state });
export const getDirectApplyFilterProgramsSuccess = (
  state,
  action,
) => ({ ...state, programs: action.programs });

export const getDirectApplyFilterResultsFailure = (
  state,
  action,
) => ({ ...state });
export const getDirectApplyFilterResultsRequest = (
  state,
  action,
) => ({ ...state });
export const getDirectApplyFilterResultsSuccess = (
  state,
  action,
) => ({ ...state, results: action.results });

export const getDirectApplyFilterStatusFailure = (state, action) => ({
  ...state,
});
export const getDirectApplyFilterStatusRequest = (state, action) => ({
  ...state,
});
export const getDirectApplyFilterStatusSuccess = (state, action) => ({
  ...state,
  status: action.status,
});

export const getDirectApplyFilterFeeWaivedFailure = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyFilterFeeWaivedRequest = (
  state,
  action,
) => ({
  ...state,
});
export const getDirectApplyFilterFeeWaivedSuccess = (
  state,
  action,
) => ({
  ...state,
  feeWaived: action.statuses,
});

export const getDirectApplyFeeStatusesFailure = (state, action) => ({
  ...state,
});
export const getDirectApplyFeeStatusesRequest = (state, action) => ({
  ...state,
});
export const getDirectApplyFeeStatusesSuccess = (state, action) => ({
  ...state,
  feeStatuses: action.statuses,
});

export const getDirectApplyFilterSchoolsFailure = (
  state,
  action,
) => ({ ...state });
export const getDirectApplyFilterSchoolsRequest = (
  state,
  action,
) => ({ ...state });
export const getDirectApplyFilterSchoolsSuccess = (
  state,
  action,
) => ({ ...state, schools: action.schools });

export const getDirectApplicationResultsFailure = (
  state,
  action,
) => ({ ...state });
export const getDirectApplicationResultsRequest = (
  state,
  action,
) => ({ ...state });
export const getDirectApplicationResultsSuccess = (
  state,
  action,
) => ({
  ...state,
  applicationResults: action.results.results,
  applicationStatuses: action.results.statuses,
});

export const getUcasChoicesFailure = (state, action) => ({
  ...state,
});
export const getUcasChoicesRequest = (state, action) => ({
  ...state,
});
export const getUcasChoicesSuccess = (state, action) => ({
  ...state,
  ucasChoices: action.choices,
});

export const uploadDirectApplicationDocumentFailure = (
  state,
  action,
) => ({ ...state });
export const uploadDirectApplicationDocumentRequest = (
  state,
  action,
) => ({ ...state });
export const uploadDirectApplicationDocumentSuccess = (
  state,
  action,
) => ({
  ...state,
  selected: {
    ...state.selected,
    ...action.updatedApplicationAttributes,
    university_documents: [
      ...state.selected.university_documents,
      action.document,
    ],
  },
});

export const uploadUcasReportFailure = (state, action) => ({
  ...state,
});
export const uploadUcasReportRequest = (state, action) => ({
  ...state,
});
export const uploadUcasReportSuccess = (state, action) => ({
  ...state,
});

export const deleteUcasReportFailure = (state, action) => ({
  ...state,
});
export const deleteUcasReportRequest = (state, action) => ({
  ...state,
});
export const deleteUcasReportSuccess = (state, action) => ({
  ...state,
});

export const deleteDirectApplicationDocumentFailure = (
  state,
  action,
) => ({ ...state, loading: false });
export const deleteDirectApplicationDocumentRequest = (
  state,
  action,
) => ({ ...state, loading: true });
export const deleteDirectApplicationDocumentSuccess = (
  state,
  action,
) => {
  const {
    selected: { university_documents },
  } = JSON.parse(JSON.stringify(state));
  let doc = university_documents.find(x => x.id === action.id);
  doc.is_deleted = true;
  return {
    ...state,
    selected: { ...state.selected, university_documents },
    loading: false,
  };
};

export const getDirectApplyListDownloadUrlFailure = (
  state,
  action,
) => ({ ...state, downloadUrl: null });
export const getDirectApplyListDownloadUrlRequest = (
  state,
  action,
) => ({ ...state, downloadUrl: null });
export const getDirectApplyListDownloadUrlSuccess = (
  state,
  action,
) => ({ ...state, downloadUrl: action.url });

export const getDirectApplicationListActivitiesFailure = (
  state,
  action,
) => ({ ...state, activities: [], isLoadingActivities: false });
export const getDirectApplicationListActivitiesRequest = (
  state,
  action,
) => ({ ...state, activities: [], isLoadingActivities: true });
export const getDirectApplicationListActivitiesSuccess = (
  state,
  action,
) => ({
  ...state,
  activities: action.activities,
  isLoadingActivities: false,
});

export const getProgramsFilterUniversitiesFailure = (
  state,
  action,
) => ({ ...state });
export const getProgramsFilterUniversitiesRequest = (
  state,
  action,
) => ({ ...state });
export const getProgramsFilterUniversitiesSuccess = (
  state,
  action,
) => ({ ...state, programsFilterUniversities: action.universities });

export const getProgramsFilterStatusesFailure = (state, action) => ({
  ...state,
});
export const getProgramsFilterStatusesRequest = (state, action) => ({
  ...state,
});
export const getProgramsFilterStatusesSuccess = (state, action) => ({
  ...state,
  programsFilterStatuses: action.statuses,
});

export const getProgramsFilterRoundsFailure = (state, action) => ({
  ...state,
});
export const getProgramsFilterRoundsRequest = (state, action) => ({
  ...state,
});
export const getProgramsFilterRoundsSuccess = (state, action) => ({
  ...state,
  programsFilterRounds: action.rounds,
});

const logout = (state, action) => INITIAL_STATE;

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.CLEAR_APPLICATIONS]: clearApplications,

  [Types.GET_APPLICATION_FAILURE]: getApplicationFailure,
  [Types.GET_APPLICATION_REQUEST]: getApplicationRequest,
  [Types.GET_APPLICATION_SUCCESS]: getApplicationSuccess,

  [Types.UPDATE_APPLICATION_FAILURE]: updateApplicationFailure,
  [Types.UPDATE_APPLICATION_REQUEST]: updateApplicationRequest,
  [Types.UPDATE_APPLICATION_SUCCESS]: updateApplicationSuccess,

  [Types.UPDATE_APPLICATION_STATUS_FAILURE]:
    updateApplicationStatusFailure,
  [Types.UPDATE_APPLICATION_STATUS_REQUEST]:
    updateApplicationStatusRequest,
  [Types.UPDATE_APPLICATION_STATUS_SUCCESS]:
    updateApplicationStatusSuccess,

  [Types.UPDATE_APPLICATION_RESULT_FAILURE]:
    updateApplicationResultFailure,
  [Types.UPDATE_APPLICATION_RESULT_REQUEST]:
    updateApplicationResultRequest,
  [Types.UPDATE_APPLICATION_RESULT_SUCCESS]:
    updateApplicationResultSuccess,

  [Types.RESET_CHOICE_FAILURE]: resetChoiceFailure,
  [Types.RESET_CHOICE_REQUEST]: resetChoiceRequest,
  [Types.RESET_CHOICE_SUCCESS]: resetChoiceSuccess,

  [Types.UPDATE_APPLICATION_DEPOSIT_PAID_FAILURE]:
    updateApplicationDepositPaidFailure,
  [Types.UPDATE_APPLICATION_DEPOSIT_PAID_REQUEST]:
    updateApplicationDepositPaidRequest,
  [Types.UPDATE_APPLICATION_DEPOSIT_PAID_SUCCESS]:
    updateApplicationDepositPaidSuccess,

  [Types.UPDATE_APPLICATION_UCAS_CHOICE_FAILURE]:
    updateApplicationUcasChoiceFailure,
  [Types.UPDATE_APPLICATION_UCAS_CHOICE_REQUEST]:
    updateApplicationUcasChoiceRequest,
  [Types.UPDATE_APPLICATION_UCAS_CHOICE_SUCCESS]:
    updateApplicationUcasChoiceSuccess,

  [Types.GET_APPLICATIONS_FAILURE]: getApplicationsFailure,
  [Types.GET_APPLICATIONS_REQUEST]: getApplicationsRequest,
  [Types.GET_APPLICATIONS_SUCCESS]: getApplicationsSuccess,

  [Types.GET_DIRECT_APPLY_FILTER_COUNTRIES_FAILURE]:
    getDirectApplyFilterCountriesFailure,
  [Types.GET_DIRECT_APPLY_FILTER_COUNTRIES_REQUEST]:
    getDirectApplyFilterCountriesRequest,
  [Types.GET_DIRECT_APPLY_FILTER_COUNTRIES_SUCCESS]:
    getDirectApplyFilterCountriesSuccess,

  [Types.GET_DIRECT_APPLY_FILTER_COURSES_FAILURE]:
    getDirectApplyFilterCoursesFailure,
  [Types.GET_DIRECT_APPLY_FILTER_COURSES_REQUEST]:
    getDirectApplyFilterCoursesRequest,
  [Types.GET_DIRECT_APPLY_FILTER_COURSES_SUCCESS]:
    getDirectApplyFilterCoursesSuccess,

  [Types.GET_DIRECT_APPLY_FILTER_PROGRAMS_FAILURE]:
    getDirectApplyFilterProgramsFailure,
  [Types.GET_DIRECT_APPLY_FILTER_PROGRAMS_REQUEST]:
    getDirectApplyFilterProgramsRequest,
  [Types.GET_DIRECT_APPLY_FILTER_PROGRAMS_SUCCESS]:
    getDirectApplyFilterProgramsSuccess,

  [Types.GET_DIRECT_APPLY_FILTER_RESULTS_FAILURE]:
    getDirectApplyFilterResultsFailure,
  [Types.GET_DIRECT_APPLY_FILTER_RESULTS_REQUEST]:
    getDirectApplyFilterResultsRequest,
  [Types.GET_DIRECT_APPLY_FILTER_RESULTS_SUCCESS]:
    getDirectApplyFilterResultsSuccess,

  [Types.GET_DIRECT_APPLY_FILTER_STATUS_FAILURE]:
    getDirectApplyFilterStatusFailure,
  [Types.GET_DIRECT_APPLY_FILTER_STATUS_REQUEST]:
    getDirectApplyFilterStatusRequest,
  [Types.GET_DIRECT_APPLY_FILTER_STATUS_SUCCESS]:
    getDirectApplyFilterStatusSuccess,

  [Types.GET_DIRECT_APPLY_FILTER_FEE_WAIVED_FAILURE]:
    getDirectApplyFilterFeeWaivedFailure,
  [Types.GET_DIRECT_APPLY_FILTER_FEE_WAIVED_REQUEST]:
    getDirectApplyFilterFeeWaivedRequest,
  [Types.GET_DIRECT_APPLY_FILTER_FEE_WAIVED_SUCCESS]:
    getDirectApplyFilterFeeWaivedSuccess,

  [Types.GET_DIRECT_APPLY_FEE_STATUSES_FAILURE]:
    getDirectApplyFeeStatusesFailure,
  [Types.GET_DIRECT_APPLY_FEE_STATUSES_REQUEST]:
    getDirectApplyFeeStatusesRequest,
  [Types.GET_DIRECT_APPLY_FEE_STATUSES_SUCCESS]:
    getDirectApplyFeeStatusesSuccess,

  [Types.GET_DIRECT_APPLY_FILTER_SCHOOLS_FAILURE]:
    getDirectApplyFilterSchoolsFailure,
  [Types.GET_DIRECT_APPLY_FILTER_SCHOOLS_REQUEST]:
    getDirectApplyFilterSchoolsRequest,
  [Types.GET_DIRECT_APPLY_FILTER_SCHOOLS_SUCCESS]:
    getDirectApplyFilterSchoolsSuccess,

  [Types.GET_DIRECT_APPLICATION_RESULTS_FAILURE]:
    getDirectApplicationResultsFailure,
  [Types.GET_DIRECT_APPLICATION_RESULTS_REQUEST]:
    getDirectApplicationResultsRequest,
  [Types.GET_DIRECT_APPLICATION_RESULTS_SUCCESS]:
    getDirectApplicationResultsSuccess,

  [Types.GET_UCAS_CHOICES_FAILURE]: getUcasChoicesFailure,
  [Types.GET_UCAS_CHOICES_REQUEST]: getUcasChoicesRequest,
  [Types.GET_UCAS_CHOICES_SUCCESS]: getUcasChoicesSuccess,

  [Types.UPLOAD_DIRECT_APPLICATION_DOCUMENT_FAILURE]:
    uploadDirectApplicationDocumentFailure,
  [Types.UPLOAD_DIRECT_APPLICATION_DOCUMENT_REQUEST]:
    uploadDirectApplicationDocumentRequest,
  [Types.UPLOAD_DIRECT_APPLICATION_DOCUMENT_SUCCESS]:
    uploadDirectApplicationDocumentSuccess,

  [Types.UPLOAD_UCAS_REPORT_FAILURE]: uploadUcasReportFailure,
  [Types.UPLOAD_UCAS_REPORT_REQUEST]: uploadUcasReportRequest,
  [Types.UPLOAD_UCAS_REPORT_SUCCESS]: uploadUcasReportSuccess,

  [Types.DELETE_UCAS_REPORT_FAILURE]: deleteUcasReportFailure,
  [Types.DELETE_UCAS_REPORT_REQUEST]: deleteUcasReportRequest,
  [Types.DELETE_UCAS_REPORT_SUCCESS]: deleteUcasReportSuccess,

  [Types.GET_DIRECT_APPLY_LIST_DOWNLOAD_URL_FAILURE]:
    getDirectApplyListDownloadUrlFailure,
  [Types.GET_DIRECT_APPLY_LIST_DOWNLOAD_URL_REQUEST]:
    getDirectApplyListDownloadUrlRequest,
  [Types.GET_DIRECT_APPLY_LIST_DOWNLOAD_URL_SUCCESS]:
    getDirectApplyListDownloadUrlSuccess,

  [Types.GET_DIRECT_APPLICATION_ACTIVITIES_FAILURE]:
    getDirectApplicationListActivitiesFailure,
  [Types.GET_DIRECT_APPLICATION_ACTIVITIES_REQUEST]:
    getDirectApplicationListActivitiesRequest,
  [Types.GET_DIRECT_APPLICATION_ACTIVITIES_SUCCESS]:
    getDirectApplicationListActivitiesSuccess,

  [Types.DELETE_DIRECT_APPLICATION_DOCUMENT_FAILURE]:
    deleteDirectApplicationDocumentFailure,
  [Types.DELETE_DIRECT_APPLICATION_DOCUMENT_REQUEST]:
    deleteDirectApplicationDocumentRequest,
  [Types.DELETE_DIRECT_APPLICATION_DOCUMENT_SUCCESS]:
    deleteDirectApplicationDocumentSuccess,

  [Types.GET_PROGRAMS_FILTER_UNIVERSITIES_FAILURE]:
    getProgramsFilterUniversitiesFailure,
  [Types.GET_PROGRAMS_FILTER_UNIVERSITIES_REQUEST]:
    getProgramsFilterUniversitiesRequest,
  [Types.GET_PROGRAMS_FILTER_UNIVERSITIES_SUCCESS]:
    getProgramsFilterUniversitiesSuccess,

  [Types.GET_PROGRAMS_FILTER_STATUSES_FAILURE]:
    getProgramsFilterStatusesFailure,
  [Types.GET_PROGRAMS_FILTER_STATUSES_REQUEST]:
    getProgramsFilterStatusesRequest,
  [Types.GET_PROGRAMS_FILTER_STATUSES_SUCCESS]:
    getProgramsFilterStatusesSuccess,

  [Types.GET_PROGRAMS_FILTER_ROUNDS_FAILURE]:
    getProgramsFilterRoundsFailure,
  [Types.GET_PROGRAMS_FILTER_ROUNDS_REQUEST]:
    getProgramsFilterRoundsRequest,
  [Types.GET_PROGRAMS_FILTER_ROUNDS_SUCCESS]:
    getProgramsFilterRoundsSuccess,

  [UserTypes.LOGOUT]: logout,
};

export default createReducer(INITIAL_STATE, HANDLERS);
