/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
import React, { Component } from "react";
import Loader from "react-loader-spinner";
import logo from "src/assets/cialfo-logo.png";
import EventBus from "eventing-bus";
import { withTranslation } from "react-i18next";
import { UserTypes } from "src/redux/actions/user.actions";
import Analytics from "src/helpers/analytics";
import { GoogleLogin } from "react-google-login";
import { GOOGLE_CLIENT_ID } from "src/config";
import { getCountryCode } from "src/web-services/user";
import queryString from "query-string";
import ContentfulScreen from "src/screens/contentful";
import { Utility } from "src/helpers/utility";
import SignInForm from "./sign-in-form";

class SignInScreen extends Component {
  constructor(props) {
    super(props);
    this.handleLoad = this.handleLoad.bind(this);
  }

  state = {
    loading: false,
    showGoogleButton: false,
    hasTracked: false,
  };

  componentDidMount() {
    window.addEventListener("load", this.handleLoad);

    this.authenticationRequestSubscription = EventBus.on(
      UserTypes.AUTHENTICATION_REQUEST,
      () => this.setState({ loading: true }),
    );
    this.authenticationSuccessSubscription = EventBus.on(
      UserTypes.AUTHENTICATION_SUCCESS,
      () => this.setState({ loading: false }),
    );
    this.authenticationFailureSubscription = EventBus.on(
      UserTypes.AUTHENTICATION_FAILURE,
      () => this.setState({ loading: false }),
    );

    this.googleSSORequestSubscription = EventBus.on(
      UserTypes.GOOGLE_SSO_REQUEST,
      () => this.setState({ loading: true }),
    );
    this.googleSSOSuccessSubscription = EventBus.on(
      UserTypes.GOOGLE_SSO_SUCCESS,
      () => this.setState({ loading: false }),
    );
    this.googleSSOFailureSubscription = EventBus.on(
      UserTypes.GOOGLE_SSO_FAILURE,
      () => this.setState({ loading: false }),
    );
  }

  componentDidUpdate() {
    const { hasTracked } = this.state;
    if (!hasTracked && window.analytics) {
      Analytics.trackPublic("Page View", {
        "Page Name": "Login",
        "Page URL": window.location && window.location.href,
      });
      this.setState({ hasTracked: true });
    }
  }

  componentWillUnmount() {
    this._unsubscribeEvents();
  }

  handleLoad() {
    getCountryCode()
      .then(response => {
        if (response.data === "CN") {
          this.setState({ showGoogleButton: false });
        } else {
          this.setState({ showGoogleButton: true });
        }
      })
      .catch(() => {
        this.setState({ showGoogleButton: true });
      });
  }

  _unsubscribeEvents = () => {
    this.authenticationRequestSubscription();
    this.authenticationSuccessSubscription();
    this.authenticationFailureSubscription();

    this.googleSSORequestSubscription();
    this.googleSSOSuccessSubscription();
    this.googleSSOFailureSubscription();
  };

  _onSubmit = values => {
    const { email, password } = values;
    const { signIn, location, getZoomAuthUrl } = this.props;
    const params = {
      email,
      password,
    };

    return new Promise(function (resolve, reject) {
      signIn(params, resolve, reject);
    })
      .then(() => {
        const queryParams = queryString.parse(location.search);

        if (queryParams.zoom) {
          return getZoomAuthUrl();
        }
      })
      .catch(e => {
        Utility.promiseCatch(e);
      });
  };

  _googleAuthenticationSuccessHandler = params => {
    const { googleSSO } = this.props;
    const { REACT_APP_ENVIRONMENT } = process.env;
    console.log("google sso code", params);

    if (REACT_APP_ENVIRONMENT !== "preproduction") {
      return new Promise(function (resolve, reject) {
        googleSSO(params.code, resolve, reject);
      }).catch(e => {
        Utility.promiseCatch(e);
      });
    }
  };

  _redirectToHome = () => {
    const { signInForm } = this.props;
    Analytics.trackPublic("Visit Explore", {
      "Login Type": "Email",
      Email: signInForm?.values?.email,
    });
    const homeUrl = "https://cialfo.co/explore";
    window.location.replace(homeUrl);
  };

  render() {
    const { t } = this.props;
    const { loading } = this.state;

    return (
      <div className="h-screen flex flex-row justify-start items-center">
        <div className="h-full p-8 bg-white rounded-lg shadow-lg w-full lg:w-477px max-w-lg flex flex-col justify-between overflow-y-auto  z-10">
          <div>
            <div className="flex flex-row justify-center mb-4 mt-8 cursor-pointer">
              <img
                className="w-112px h-38px"
                src={logo}
                alt="logo"
                onClick={this._redirectToHome}
              />
            </div>

            {this.state.showGoogleButton ? (
              <>
                <GoogleLogin
                  clientId={GOOGLE_CLIENT_ID}
                  render={renderProps => (
                    <div className="mt-6">
                      <button
                        className="mx-auto btn-secondary lg:w-3/5 md:w-4/5 w-full"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                      >
                        <img
                          className="h-6 w-6 object-contain mr-1"
                          src={
                            require("src/assets/google-sign-in.png")
                              .default
                          }
                          alt="google"
                        />
                        {t("common.buttons.sign_in_google")}
                      </button>
                    </div>
                  )}
                  accessType={"offline"}
                  responseType={"code"}
                  prompt={"select_account"}
                  scope={
                    "https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/plus.login"
                  }
                  onSuccess={this._googleAuthenticationSuccessHandler}
                  onFailure={Utility.doNothing}
                  cookiePolicy={"single_host_origin"}
                />
              </>
            ) : (
              ""
            )}

            <div className="flex flex-row flex-nowrap mb-6 mt-6">
              <div className="h-px w-3/4 bg-gray-300 my-3 mr-3"></div>
              <div className="text-gray-300">or</div>
              <div className="h-px w-3/4 bg-gray-300 my-3 ml-3"></div>
            </div>

            <SignInForm onSubmit={this._onSubmit} />

            <div className="flex flex-row items-center justify-center my-6">
              <button
                className="btn-border-primary w-full"
                onClick={this._redirectToHome}
              >
                {t("common.buttons.redirect_home")}
              </button>
            </div>
          </div>

          <div className="mt-8 flex flex-row items-center justify-center">
            <button
              onClick={() => {
                this.props.updateAppLocale("en-US");
              }}
              className={
                "text-sm text-blue-700 font-bold focus:outline-none mr-2"
              }
            >
              English
            </button>
            <button
              onClick={() => {
                this.props.updateAppLocale("zh-CN");
              }}
              className={
                "text-sm text-blue-700 font-bold focus:outline-none"
              }
            >
              中文
            </button>
          </div>
        </div>

        <ContentfulScreen />
        {loading ? (
          <div className="flex justify-center items-center z-20 absolute inset-0">
            <Loader type="Oval" color="grey" height={50} width={50} />
          </div>
        ) : null}
      </div>
    );
  }
}

export default withTranslation()(SignInScreen);
