import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  sendMessageFailure: null,
  sendMessageRequest: ["params", "resolve", "reject"],
  sendMessageSuccess: null,

  getPartnerFailure: null,
  getPartnerRequest: null,
  getPartnerSuccess: ["partner"],

  getGoogleAuthorizationUrlFailure: null,
  getGoogleAuthorizationUrlRequest: null,
  getGoogleAuthorizationUrlSuccess: ["url"],

  getAvailableCalendarsFailure: null,
  getAvailableCalendarsRequest: null,
  getAvailableCalendarsSuccess: ["calendars"],

  getAvailableCalendarsWithTokenFailure: null,
  getAvailableCalendarsWithTokenRequest: ["token"],
  getAvailableCalendarsWithTokenSuccess: ["calendars"],

  getSyncedCalendarsFailure: null,
  getSyncedCalendarsRequest: ["accessToken"],
  getSyncedCalendarsSuccess: ["calendars"],

  sendInvitationFailure: null,
  sendInvitationRequest: ["params"],
  sendInvitationSuccess: null,

  setCalendarTokenFailure: null,
  setCalendarTokenRequest: ["token"],
  setCalendarTokenSuccess: null,

  syncCalendarsFailure: null,
  syncCalendarsRequest: ["params"],
  syncCalendarsSuccess: ["calendars"],

  updatePartnerFailure: null,
  updatePartnerRequest: ["params", "resolve", "reject"],
  updatePartnerSuccess: ["partner"],

  updatePartnerTimezoneFailure: null,
  updatePartnerTimezoneRequest: ["params", "resolve", "reject"],
  updatePartnerTimezoneSuccess: ["partner"],
});

export const PartnerTypes = Types;
export const PartnerCreators = Creators;
