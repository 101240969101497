import { call, put, takeEvery, select } from "redux-saga/effects";
import {
  PartnerCreators,
  PartnerTypes,
  UserCreators,
} from "../actions";
import {
  sendInvitation,
  sendMessage,
  getPartner,
  updatePartner,
  updatePartnerTimezone,
  getSyncedCalendars,
  syncCalendars,
  setCalendarToken,
  getAvailableCalendars,
  getGoogleAuthorizationUrl,
} from "src/web-services";
import { SubmissionError } from "redux-form";
import each from "lodash/each";
import { Utility } from "src/helpers/utility";

const partnerIdSelector = state => state.user.data.partner_id;
const partnerTypeSelector = state =>
  state.partner.data.institute_type;
export function* watchPartnerRequests() {
  yield takeEvery(
    PartnerTypes.SEND_MESSAGE_REQUEST,
    requestSendMessage,
  );
  yield takeEvery(
    PartnerTypes.SEND_INVITATION_REQUEST,
    requestSendInvitation,
  );
  yield takeEvery(
    PartnerTypes.GET_PARTNER_REQUEST,
    requestGetPartner,
  );
  yield takeEvery(
    PartnerTypes.GET_GOOGLE_AUTHORIZATION_URL_REQUEST,
    requestGetGoogleAuthorizationUrl,
  );
  yield takeEvery(
    PartnerTypes.GET_AVAILABLE_CALENDARS_REQUEST,
    requestGetAvailableCalendars,
  );
  yield takeEvery(
    PartnerTypes.GET_AVAILABLE_CALENDARS_WITH_TOKEN_REQUEST,
    requestGetAvailableCalendarsWithToken,
  );
  yield takeEvery(
    PartnerTypes.GET_SYNCED_CALENDARS_REQUEST,
    requestGetSyncedCalendars,
  );
  yield takeEvery(
    PartnerTypes.SET_CALENDAR_TOKEN_REQUEST,
    requestSetCalendarToken,
  );
  yield takeEvery(
    PartnerTypes.SYNC_CALENDARS_REQUEST,
    requestSyncCalendars,
  );
  yield takeEvery(
    PartnerTypes.UPDATE_PARTNER_REQUEST,
    requestUpdatePartner,
  );
  yield takeEvery(
    PartnerTypes.UPDATE_PARTNER_TIMEZONE_REQUEST,
    requestUpdatePartnerTimezone,
  );
}

export function* requestSendMessage(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);

    const response = yield call(sendMessage, authToken, params);
    yield put(PartnerCreators.sendMessageSuccess(response.data));
  } catch (error) {
    yield put(PartnerCreators.sendMessageFailure());
  }
}

export function* requestSendInvitation(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);

    yield call(sendInvitation, authToken, params);
    yield put(PartnerCreators.sendInvitationSuccess());
  } catch (error) {
    yield put(PartnerCreators.sendInvitationFailure());
  }
}

export function* requestGetPartner(action) {
  try {
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getPartner, authToken);
    yield put(PartnerCreators.getPartnerSuccess(response.data));

    const isZoomConnected = response.data.is_zoom_connected
      ? true
      : false;
    const zoomAccount = isZoomConnected
      ? response.data.zoom_email
      : null;
    yield put(
      UserCreators.getZoomAccountSuccess(
        isZoomConnected,
        zoomAccount,
      ),
    );
  } catch (error) {
    yield put(PartnerCreators.getPartnerFailure());
  }
}

export function* requestGetGoogleAuthorizationUrl(action) {
  try {
    const coreUser = Utility.isCoreUser();
    if (coreUser) {
      localStorage.setItem("core", "true");
    }

    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getGoogleAuthorizationUrl, authToken);

    window.location.href = response.data.url;
    yield put(
      PartnerCreators.getGoogleAuthorizationUrlSuccess(
        response.data.url,
      ),
    );
  } catch (error) {
    yield put(PartnerCreators.getGoogleAuthorizationUrlFailure());
  }
}

export function* requestGetAvailableCalendars(action) {
  try {
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getAvailableCalendars, authToken);
    yield put(
      PartnerCreators.getAvailableCalendarsSuccess(
        response.data.calendars,
      ),
    );
  } catch (error) {
    yield put(PartnerCreators.getAvailableCalendarsFailure());
  }
}

export function* requestGetAvailableCalendarsWithToken(action) {
  try {
    const { token } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const setTokenParams = {
      auth_token: token,
    };

    yield call(setCalendarToken, authToken, setTokenParams);

    const response = yield call(getAvailableCalendars, authToken);
    yield put(
      PartnerCreators.getAvailableCalendarsSuccess(
        response.data.calendars,
      ),
    );
  } catch (error) {
    yield put(PartnerCreators.getAvailableCalendarsFailure());
  }
}

export function* requestGetSyncedCalendars(action) {
  try {
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getSyncedCalendars, authToken);
    yield put(
      PartnerCreators.getSyncedCalendarsSuccess(
        response.data.calendars,
      ),
    );
  } catch (error) {
    yield put(PartnerCreators.getSyncedCalendarsFailure());
  }
}

export function* requestSetCalendarToken(action) {
  try {
    const { token } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const params = {
      auth_token: token,
    };

    yield call(setCalendarToken, authToken, params);
    yield put(PartnerCreators.setCalendarTokenSuccess());
  } catch (error) {
    yield put(PartnerCreators.setCalendarTokenFailure());
  }
}

export function* requestSyncCalendars(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);
    const response = yield call(syncCalendars, authToken, params);
    yield put(
      PartnerCreators.syncCalendarsSuccess(response.data.calendars),
    );
  } catch (error) {
    yield put(PartnerCreators.syncCalendarsFailure());
  }
}

export function* requestUpdatePartner(action) {
  try {
    const { params, resolve } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);

    const partnerId = yield select(partnerIdSelector);

    const partnerType = yield select(partnerTypeSelector);
    const updatePartnerType =
      partnerType === "University" ? "universities" : "schools";
    const response = yield call(
      updatePartner,
      authToken,
      partnerId,
      updatePartnerType,
      params,
    );

    resolve();
    yield put(PartnerCreators.updatePartnerSuccess(response.data));
  } catch (error) {
    const { reject } = action;
    let errorMessages = {};

    each(error.response.data.error, (e, key) => {
      errorMessages[key] = e instanceof Array ? e : [e];
    });

    reject(new SubmissionError(errorMessages));
    yield put(PartnerCreators.updatePartnerFailure());
  }
}

export function* requestUpdatePartnerTimezone(action) {
  try {
    const { params } = action;
    const authTokenSelector = state => state.user.authToken;
    const authToken = yield select(authTokenSelector);

    const response = yield call(
      updatePartnerTimezone,
      authToken,
      params,
    );
    yield put(
      PartnerCreators.updatePartnerTimezoneSuccess(response.data),
    );
  } catch (error) {
    yield put(PartnerCreators.updatePartnerTimezoneFailure());
  }
}
