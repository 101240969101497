import { createReducer } from "reduxsauce";
import {
  DirectApplyFiltersTypes as Types,
  UserTypes,
} from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.directApplyListState;

export const clearApplicationFilters = (state, action) =>
  INITIAL_STATE;

export const setApplicationFilters = (state, action) => ({
  ...state,
  filters: action.filters,
});

export const setDirectApplySearchText = (state, action) => ({
  ...state,
  searchText: action.text,
});

export const setDirectApplySortBy = (state, action) => ({
  ...state,
  sortBy: action.sortBy,
});

export const setDirectApplyTab = (state, action) => ({
  ...INITIAL_STATE,
  tab: action.tab,
});

const logout = (state, action) => INITIAL_STATE;

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.CLEAR_APPLICATION_FILTERS]: clearApplicationFilters,

  [Types.SET_APPLICATION_FILTERS]: setApplicationFilters,

  [Types.SET_DIRECT_APPLY_SEARCH_TEXT]: setDirectApplySearchText,

  [Types.SET_DIRECT_APPLY_SORT_BY]: setDirectApplySortBy,

  [Types.SET_DIRECT_APPLY_TAB]: setDirectApplyTab,

  [UserTypes.LOGOUT]: logout,
};

export default createReducer(INITIAL_STATE, HANDLERS);
