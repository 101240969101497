import { API } from "../helpers";
import { SERVER_URL } from "../config";

export function getApplication(authToken, applicationId) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/student_applications/${applicationId}`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function updateApplication(authToken, applicationId, data) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/student_applications/${applicationId}`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function updateChoice(authToken, applicationId, data) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/student_applications/${applicationId}/update_choice`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function resetChoices(authToken, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/student_applications/reset_choices`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getApplications(authToken, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/student_applications`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getDirectApplyFilterCountries(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/student_applications/countries`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getDirectApplyFilterCourses(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/student_applications/courses`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getDirectApplyFilterPrograms(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/student_applications/programs`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getDirectApplyFilterResults(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/student_applications/results`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getDirectApplyFilterStatus(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/student_applications/statuses`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getDirectApplyFilterFeeWaived(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/student_applications/fee_waived_statuses`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getDirectApplyFeeStatuses(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/student_applications/fee_statuses`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getDirectApplyFilterSchools(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/student_applications/schools`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getDirectApplicationResults(
  authToken,
  applicationId,
) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/student_applications/${applicationId}/application_results`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getUcasChoices(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/student_applications/choices`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function uploadDirectApplicationDocument(
  authToken,
  applicationId,
  data,
) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/student_applications/${applicationId}/upload_document`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function uploadUcasReport(authToken, studentId, data) {
  return API.request({
    method: "patch",
    url: `${SERVER_URL}/api/v2/partners/students/${studentId}`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getDirectApplicationActivities(
  authToken,
  applicationId,
) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/student_applications/${applicationId}/activities`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getPrograms(authToken, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/programs/list`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getPartnerProgramUniversities(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/programs/universities`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function getProgramsFilterStatuses(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/programs/statuses`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}
export function getProgramsFilterRounds(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/programs/rounds`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function deleteDocument(authToken, id) {
  return API.request({
    method: "DELETE",
    url: `${SERVER_URL}/api/v1/partners/student_application_documents/${id}`,
    headers: {
      authentication_token: authToken,
    },
  });
}
