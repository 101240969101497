import { createReducer } from "reduxsauce";
import {
  PageNotificationsTypes as Types,
  UserTypes,
} from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.pageNotifications;

export const clearPageNotifications = (state, action) => ({
  ...state,
  data: [],
  loading: false,
  no_notification_found: false,
  no_search_result: false,
  page_no: 1,
});

export const getPageNotificationsFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getPageNotificationsRequest = (state, action) => ({
  ...state,
  loading: true,
  no_notification_found: false,
  no_search_result: false,
});
export const getPageNotificationsSuccess = (state, action) => {
  const {
    page_no,
    total,
    unread_count,
    read_count,
    filter_count,
    notifications,
  } = action.data;

  return {
    ...state,
    data:
      page_no === 1
        ? notifications
        : [...state.data, ...notifications],
    total,
    read_count,
    unread_count,
    filter_count,
    no_notification_found: total === 0 && filter_count === 0,
    no_search_result: filter_count === 0,
    page_no,
    loading: false,
  };
};

export const markPageNotificationsReadFailure = (state, action) => ({
  ...state,
});
export const markPageNotificationsReadRequest = (state, action) => ({
  ...state,
});
export const markPageNotificationsReadSuccess = (state, action) => {
  const { notificationIds: readNotificationsIds } = action;
  return {
    ...state,
    data: state.data.map(notification =>
      readNotificationsIds.includes(notification.id)
        ? { ...notification, is_read: true }
        : notification,
    ),
  };
};

export const markAllPageNotificationsReadFailure = (
  state,
  action,
) => ({ ...state });
export const markAllPageNotificationsReadRequest = (
  state,
  action,
) => ({ ...state, loading: true });
export const markAllPageNotificationsReadSuccess = (
  state,
  action,
) => {
  return {
    ...state,
    loading: false,
    unread_count: 0,
    data: state.data.map(notification => {
      return { ...notification, is_read: true };
    }),
  };
};

const logout = (state, action) => INITIAL_STATE;

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.CLEAR_PAGE_NOTIFICATIONS]: clearPageNotifications,

  [Types.GET_PAGE_NOTIFICATIONS_FAILURE]: getPageNotificationsFailure,
  [Types.GET_PAGE_NOTIFICATIONS_REQUEST]: getPageNotificationsRequest,
  [Types.GET_PAGE_NOTIFICATIONS_SUCCESS]: getPageNotificationsSuccess,

  [Types.MARK_PAGE_NOTIFICATIONS_READ_FAILURE]:
    markPageNotificationsReadFailure,
  [Types.MARK_PAGE_NOTIFICATIONS_READ_REQUEST]:
    markPageNotificationsReadRequest,
  [Types.MARK_PAGE_NOTIFICATIONS_READ_SUCCESS]:
    markPageNotificationsReadSuccess,

  [Types.MARK_ALL_PAGE_NOTIFICATIONS_READ_FAILURE]:
    markAllPageNotificationsReadFailure,
  [Types.MARK_ALL_PAGE_NOTIFICATIONS_READ_REQUEST]:
    markAllPageNotificationsReadRequest,
  [Types.MARK_ALL_PAGE_NOTIFICATIONS_READ_SUCCESS]:
    markAllPageNotificationsReadSuccess,

  [UserTypes.LOGOUT]: logout,
};

export default createReducer(INITIAL_STATE, HANDLERS);
