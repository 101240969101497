const week = {
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
  7: "Sunday",
};

const accountStatus = {
  1: "Active",
  2: "Inactive",
};

const USER_ROLES = {
  MEMBER: "member",
  ADMIN: "admin",
  ADMISSION_OFFICER: "admission_officer",
  STUDENT: "student",
  PARENT: "parent",
  COUNSELOR: "counselor",
};

const PARTNERS = {
  UNIVERSITY: "University",
  SCHOOL: "School",
};

const WEB_USER_ROLE_OPTIONS = {
  student: "Student",
  parent: "Parent/Guardian",
  counselor: "Counselor/Educator",
};

const GENDER_TYPES = {
  male: "Male",
  female: "Female",
  other: "Other",
};

const GENDER_OPTIONS = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];

const PARTNER_TYPES = {
  public_partner: "Public",
  private_partner: "Private",
};

const PARTNER_TYPES_OPTIONS = [
  { value: "public_partner", label: "Public" },
  { value: "private_partner", label: "Private" },
];

const EVENT_UI_CONFIG = {
  pending: {
    icon: ["fas", "circle"],
    iconClass: "text-xl text-yellow-800",
    class: "text-yellow-800",
  },
  declined: {
    icon: ["fas", "times-circle"],
    iconClass: "text-xl text-gray-500",
    class: "text-gray-500",
  },
  requested: {
    icon: ["fas", "circle"],
    iconClass: "text-xl text-yellow-800",
    class: "text-yellow-800",
  },
  reschedule: {
    icon: ["fas", "exclamation-circle"],
    iconClass: "text-xl text-red-800",
    class: "text-red-800",
  },
  confirm: {
    icon: ["fas", "check-circle"],
    iconClass: "text-xl text-green-500",
    class: "text-green-500",
  },
  cancel: {
    icon: ["fas", "times-circle"],
    iconClass: "text-xl text-gray-500",
    class: "text-gray-500",
  },
};

const ERRORS = {
  RECORD_NOT_FOUND: "Record not found.",
};

export {
  week,
  accountStatus,
  PARTNER_TYPES,
  EVENT_UI_CONFIG,
  USER_ROLES,
  WEB_USER_ROLE_OPTIONS,
  GENDER_TYPES,
  GENDER_OPTIONS,
  PARTNER_TYPES_OPTIONS,
  ERRORS,
  PARTNERS,
};
