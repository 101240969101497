import React from "react";
import { Query } from "react-contentful";

const ErrorImage = () => (
  <img
    alt="banner"
    className="object-cover h-220px w-450px xl:h-300px xl:w-620px rounded-xl shadow-contentful-image"
    src={
      window.innerWidth > 1280
        ? require("src/assets/contentful/sign-in/sign-in-desktop.png")
            .default
        : require("src/assets/contentful/sign-in/sign-in-tablet.png")
            .default
    }
  />
);

const ContentfulScreen = () => {
  return (
    <div className="flex-1 hidden sm:flex h-full px-2 bg-gray-50 justify-center items-center overflow-hidden">
      <div className="hidden lg:block">
        <Query contentType="exploreLoginPageBanner">
          {({ data, error, fetched, loading }) => {
            if (loading || !fetched) {
              return null;
            }

            if (
              error ||
              !data ||
              !data.items ||
              data.items?.length === 0
            ) {
              return <ErrorImage />;
            }

            const banner =
              window.innerWidth > 1280
                ? data.items[0].fields.desktopBanner
                : data.items[0].fields.tabletBanner;

            if (!banner.fields) {
              return <ErrorImage />;
            }

            return (
              <img
                alt="banner"
                className="object-cover h-220px w-450px xl:h-300px xl:w-620px rounded-xl shadow-contentful-image"
                src={banner.fields.file.url}
              />
            );
          }}
        </Query>
      </div>
    </div>
  );
};

export default ContentfulScreen;
