/* eslint-disable no-unused-expressions */
import moment from "moment";
import { Notification } from "src/helpers/notification";
import startCase from "lodash/startCase";

const isFileSizeCorrect = (file, fileLimit) => {
  const fileSize = file.size / 1024 / 1024;
  if (fileSize < fileLimit) {
    return true;
  }
  return false;
};
export class Utility {
  static isNonEmptyString(x) {
    return (
      !!x && Object.prototype.toString.call(x) === "[object String]"
    );
  }

  static isPlural(num) {
    return num > 1 || num === 0 || !num;
  }

  static isPastDate(date, format = "YYYY-MM-DD") {
    return moment(date, format).isBefore(moment(), "date");
  }

  static promiseCatch(e) {
    return console.log(e?.response?.data?.error);
  }

  static validateFile(file, fileLimit) {
    if (!file) {
      return false;
    }

    if (isFileSizeCorrect(file, fileLimit)) {
      return true;
    }
    Notification.warning(
      `File size should be less than ${fileLimit} MB`,
    );
    return false;
  }

  static isCoreUser() {
    const coreUser = new URLSearchParams(window.location.search).get(
      "core",
    );

    return coreUser === "true";
  }

  static getFilterName(filters, core) {
    const copyFilters = { ...filters };

    return Object.keys(copyFilters)
      .map(key => {
        if (key === "partnerType") {
          if (core) {
            return "College Type";
          }
          return "School Type";
        }
        if (key === "eventTypes") {
          return "Visit Type";
        }
        if (key === "eventStatus") {
          return "Visit Status";
        }
        if (key === "rounds") {
          return "Intake";
        }
        if (key === "statuses") {
          return "Status";
        }
        return startCase(key);
      })
      .toString();
  }

  static doNothing = () => {
    // do nothing
  };

  static isCoreIframe() {
    const parentOrigin = document.referrer;
    return parentOrigin.includes(process.env.REACT_APP_CORE_URL);
  }

  static dateCheck(date, format) {
    return date ? date.format(format) : "-";
  }

  static found = (filter, selected) => {
    const index = selected.findIndex(o => o.id === filter.id);
    return index >= 0;
  };
}
