import { createReducer } from "reduxsauce";
import { AvailabilityTypes as Types, UserTypes } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.availability;

export const checkDateAvailabilityPrecedenceFailure = state => ({
  ...state,
});

export const checkDateAvailabilityPrecedenceRequest = state => ({
  ...state,
  availabilityPrecedence: null,
});

export const checkDateAvailabilityPrecedenceSuccess = (
  state,
  action,
) => ({
  ...state,
  availabilityPrecedence: {
    date: action.date,
    isAvailable: action.isAvailable,
  },
});

export const getAvailabilityFailure = state => ({
  ...state,
  loading: false,
});

export const getAvailabilityRequest = state => ({
  ...state,
  loading: true,
});

export const getAvailabilitySuccess = (state, action) => ({
  ...state,
  data: action.availability,
  startDate: action.startDate,
  endDate: action.endDate,
  adding: false,
  loading: false
});

export const setAvailabilityFailure = state => ({
  ...state,
  adding: false,
});

export const setAvailabilityRequest = state => ({
  ...state,
  adding: true,
});

export const setAvailabilitySuccess = state => ({
  ...state,
  adding: false,
});

export const updateAvailabilityFailure = state => ({
  ...state,
  adding: false,
});

export const updateAvailabilityRequest = state => ({
  ...state,
  adding: true,
});

export const updateAvailabilitySuccess = state => {
  return {
    ...state,
    adding: true,
  };
};

export const deleteAvailabilityFailure = state => ({
  ...state,
  adding: false,
});

export const deleteAvailabilityRequest = state => ({
  ...state,
  adding: true,
});

export const deleteAvailabilitySuccess = (state, action) => {
  const slots = state.data[action.date].slots.filter(
    s =>
      !(
        s.slot_id === action.slotId &&
        s.availability_id === action.availabilityId
      ),
  );

  return {
    ...state,
    adding: false,
    data: {
      ...state.data,
      [action.date]: {
        slots,
      },
    },
  };
};

export const createBulkAvailabilityFailure = state => ({
  ...state,
  bulkCreate: {
    ...state.bulkCreate,
    loading: false,
  },
});

export const createBulkAvailabilityRequest = state => ({
  ...state,
  bulkCreate: {
    ...state.bulkCreate,
    loading: true,
  },
});

export const createBulkAvailabilitySuccess = state => ({
  ...state,
  bulkCreate: {
    ...state.bulkCreate,
    loading: false,
  },
});

export const getAvailabilitySettingsFailure = state => ({
  ...state,
  loading: false,
});

export const getAvailabilitySettingsRequest = state => ({
  ...state,
  loading: true,
});

export const getAvailabilitySettingsSuccess = (state, action) => {
  const { school, primary_contact, member_contacts } = action;

  return {
    ...state,
    school,
    primaryContact: primary_contact,
    memberContacts: member_contacts,
    loading: false,
  };
};

export const updatePrimarySettingsFailure = state => ({
  ...state,
  loading: false,
});

export const updatePrimarySettingsRequest = state => ({
  ...state,
  loading: true,
});

export const updatePrimarySettingsSuccess = (state, action) => {
  const { school, primary_contact, member_contacts } = action;

  return {
    ...state,
    school: school,
    primaryContact: primary_contact,
    memberContacts: member_contacts,
    loading: false,
  };
};

export const updateEventPublishSettingsFailure = state => ({
  ...state,
  loading: false,
});

export const updateEventPublishSettingsRequest = state => ({
  ...state,
  loading: true,
});

export const updateEventPublishSettingsSuccess = (state, action) => ({
  ...state,
  school: action.school,
  loading: false,
});

export const updateMessageSettingsFailure = state => ({
  ...state,
  loading: false,
});

export const updateMessageSettingsRequest = state => ({
  ...state,
  loading: true,
});

export const updateMessageSettingsSuccess = (state, action) => ({
  ...state,
  school: action.school,
  loading: false,
});

export const updateEventLocationSettingsFailure = state => ({
  ...state,
  loading: false,
});

export const updateEventLocationSettingsRequest = state => ({
  ...state,
  loading: true,
});

export const updateEventLocationSettingsSuccess = (
  state,
  action,
) => ({ ...state, school: action.school, loading: false });

export const updateAutoAcceptEventFailure = state => ({
  ...state,
  loading: false,
});

export const updateAutoAcceptEventRequest = state => ({
  ...state,
  loading: true,
});

export const updateAutoAcceptEventSuccess = (state, action) => ({
  ...state,
  school: action.school,
  loading: false,
});

export const removeAvailabilityFailure = state => ({
  ...state,
  removing: false,
});

export const removeAvailabilityRequest = state => ({
  ...state,
  removing: true,
});

export const removeAvailabilitySuccess = state => ({
  ...state,
  removing: false,
});

const logout = () => INITIAL_STATE;

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.CHECK_DATE_AVAILABILITY_PRECEDENCE_FAILURE]:
    checkDateAvailabilityPrecedenceFailure,
  [Types.CHECK_DATE_AVAILABILITY_PRECEDENCE_REQUEST]:
    checkDateAvailabilityPrecedenceRequest,
  [Types.CHECK_DATE_AVAILABILITY_PRECEDENCE_SUCCESS]:
    checkDateAvailabilityPrecedenceSuccess,

  [Types.GET_AVAILABILITY_FAILURE]: getAvailabilityFailure,
  [Types.GET_AVAILABILITY_REQUEST]: getAvailabilityRequest,
  [Types.GET_AVAILABILITY_SUCCESS]: getAvailabilitySuccess,

  [Types.SET_AVAILABILITY_FAILURE]: setAvailabilityFailure,
  [Types.SET_AVAILABILITY_REQUEST]: setAvailabilityRequest,
  [Types.SET_AVAILABILITY_SUCCESS]: setAvailabilitySuccess,

  [Types.REMOVE_AVAILABILITY_FAILURE]: removeAvailabilityFailure,
  [Types.REMOVE_AVAILABILITY_REQUEST]: removeAvailabilityRequest,
  [Types.REMOVE_AVAILABILITY_SUCCESS]: removeAvailabilitySuccess,

  [Types.UPDATE_AVAILABILITY_FAILURE]: updateAvailabilityFailure,
  [Types.UPDATE_AVAILABILITY_REQUEST]: updateAvailabilityRequest,
  [Types.UPDATE_AVAILABILITY_SUCCESS]: updateAvailabilitySuccess,

  [Types.DELETE_AVAILABILITY_FAILURE]: deleteAvailabilityFailure,
  [Types.DELETE_AVAILABILITY_REQUEST]: deleteAvailabilityRequest,
  [Types.DELETE_AVAILABILITY_SUCCESS]: deleteAvailabilitySuccess,

  [Types.CREATE_BULK_AVAILABILITY_FAILURE]:
    createBulkAvailabilityFailure,
  [Types.CREATE_BULK_AVAILABILITY_REQUEST]:
    createBulkAvailabilityRequest,
  [Types.CREATE_BULK_AVAILABILITY_SUCCESS]:
    createBulkAvailabilitySuccess,

  [Types.GET_AVAILABILITY_SETTINGS_FAILURE]:
    getAvailabilitySettingsFailure,
  [Types.GET_AVAILABILITY_SETTINGS_REQUEST]:
    getAvailabilitySettingsRequest,
  [Types.GET_AVAILABILITY_SETTINGS_SUCCESS]:
    getAvailabilitySettingsSuccess,

  [Types.UPDATE_PRIMARY_SETTINGS_FAILURE]:
    updatePrimarySettingsFailure,
  [Types.UPDATE_PRIMARY_SETTINGS_REQUEST]:
    updatePrimarySettingsRequest,
  [Types.UPDATE_PRIMARY_SETTINGS_SUCCESS]:
    updatePrimarySettingsSuccess,

  [Types.UPDATE_EVENT_PUBLISH_SETTINGS_FAILURE]:
    updateEventPublishSettingsFailure,
  [Types.UPDATE_EVENT_PUBLISH_SETTINGS_REQUEST]:
    updateEventPublishSettingsRequest,
  [Types.UPDATE_EVENT_PUBLISH_SETTINGS_SUCCESS]:
    updateEventPublishSettingsSuccess,

  [Types.UPDATE_MESSAGE_SETTINGS_FAILURE]:
    updateMessageSettingsFailure,
  [Types.UPDATE_MESSAGE_SETTINGS_REQUEST]:
    updateMessageSettingsRequest,
  [Types.UPDATE_MESSAGE_SETTINGS_SUCCESS]:
    updateMessageSettingsSuccess,

  [Types.UPDATE_EVENT_LOCATION_SETTINGS_FAILURE]:
    updateEventLocationSettingsFailure,
  [Types.UPDATE_EVENT_LOCATION_SETTINGS_REQUEST]:
    updateEventLocationSettingsRequest,
  [Types.UPDATE_EVENT_LOCATION_SETTINGS_SUCCESS]:
    updateEventLocationSettingsSuccess,

  [Types.UPDATE_AUTO_ACCEPT_EVENT_FAILURE]:
    updateAutoAcceptEventFailure,
  [Types.UPDATE_AUTO_ACCEPT_EVENT_REQUEST]:
    updateAutoAcceptEventRequest,
  [Types.UPDATE_AUTO_ACCEPT_EVENT_SUCCESS]:
    updateAutoAcceptEventSuccess,

  [UserTypes.LOGOUT]: logout,
};

export default createReducer(INITIAL_STATE, HANDLERS);
