import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "../reducers";
import createSagaMiddleware from "redux-saga";
import { eventBusMiddleware } from "../middlewares";
import { rootSaga } from "../sagas";

const middlewares = [eventBusMiddleware];
const sagaMiddleware = createSagaMiddleware();
middlewares.push(sagaMiddleware);

export default function configureStore(initialState) {
  var store = createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middlewares)),
  );
  sagaMiddleware.run(rootSaga);

  return store;
}
