export const ROUTES = {
  EVENTS: "/events",
  EVENT_DETAIL: "/events/:id",
  AVAILABILITY: "/availability",
  TEAM: "/team",
  SETTINGS: "/settings",
  CALENDAR_SYNC: "/calendar-sync",
  SCHOOL_DETAILS: "/school-details",

  DIRECT_APPLY: "/direct-apply",
  DIRECT_APPLY_APPLICATIONS: "/direct-apply/applications",
  DIRECT_APPLY_APPLICATION_DETAIL: "/direct-apply/applications/:id",
  DIRECT_APPLY_PROGRAMS: "/direct-apply/programs",
  DIRECT_APPLY_PROGRAM_DETAIL: "/direct-apply/programs/:id",

  EMAIL_VALIDATED: "/email-validated",

  PAYMENTS: "/payments",
  PAYMENT_DETAIL: "/payments/:id",

  NOTIFICATIONS: "/notifications",
  SCHOOLS: "/schools",
  DASHBOARD: "/dashboard",
  SCHOOL_DETAIL: "/schools/:id",
  UNIVERSITY_DETAILS: "/university-details",
  CIALFO_EVENTS: "/event/cialfo-events",
  CONNECTIONS: "/connections",
  EVENT_SESSIONS: "/event/sessions",
  SESSION_DETAIL: "/event/sessions/:id",

  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",

  REGISTER_SCHOOL: "/register-school",
  SEARCH_UNIVERSITY: "/search-university",
  REGISTER_UNIVERSITY: "/register-university",

  SIGN_IN: "/sign-in",

  SCHEDULE_APPOINTMENT: "/schedule-appointment/:id",

  PRIVACY_POLICY: "/privacy-policy",
  COOKIES_POLICY: "/cookies-policy",

  WEBINARS: "/webinars",
  WEBINARS_USER_UPDATE: "/webinars/users/update",
  WEBINARS_MY_SESSIONS: "/webinars/my-sessions",
  WEBINARS_CATEGORY_DETAIL: "/webinars/categories/:id",
  WEBINARS_CATEGORY_SEARCH: "/webinars/categories/events/search",
  WEBINARS_CATEGORY_EVENT_DETAIL:
    "/webinars/categories/:id/events/:id",
  WEBINARS_FAQ: "/webinars/faq",
  WEBINARS_ABOUT: "/webinars/about",
  WEBINARS_FORGOT_PASSWORD: "/webinars/forgot-password",
  WEBINARS_RESET_PASSWORD: "/webinars/reset-password",
  WEBINARS_SIGN_IN: "/webinars/sign-in",
  WEBINARS_SEARCH_SCHOOL: "/webinars/search-school",
  WEBINARS_REGISTER: "/webinars/register",
  TUITION_FEE: "/tuition-fee",

  PROSPECT_AND_ENGAGE: "/prospect-and-engage",
  PROSPECT_AND_ENGAGE_INSIGHTS: "/prospect-and-engage/insights",
  PROSPECT_AND_ENGAGE_PREMIUM: "/prospect-and-engage/premium",
  PROSPECT_AND_ENGAGE_CONNECT: "/prospect-and-engage/connect",
  PROSPECT_AND_ENGAGE_EVENTS:
    "/prospect-and-engage/engagement-events",

  HIGHER_ED_INSIGHTS: "/higher-ed-insights",
  HIGHER_ED_INSIGHTS_BETA: "/higher-ed-insights-beta",
  ZOOM: "/zoom",
  SSO: "/sso",
  MOBILE_PAYMENT_REDIRECT: "/mobile-payment-redirect",
  CANCEL_EVENT: "/cancel-event",
  EVENTS_LIST: "/events-list",
  EVENTS_LIST_DETAILS: "/events-list/:id",
};

export const ROUTE_AUTHORIZATION = {
  School: {
    initialRoute: ROUTES.EVENTS,
    routes: [
      ROUTES.EVENTS,
      ROUTES.AVAILABILITY,
      ROUTES.TEAM,
      ROUTES.SETTINGS,
      ROUTES.CALENDAR_SYNC,
      ROUTES.SCHOOL_DETAILS,
      ROUTES.WEBINARS,
      ROUTES.WEBINARS_USER_UPDATE,
      ROUTES.WEBINARS_MY_SESSIONS,
      ROUTES.DIRECT_APPLY,
      ROUTES.DIRECT_APPLY_APPLICATIONS,
      ROUTES.DIRECT_APPLY_APPLICATION_DETAIL,
      ROUTES.DIRECT_APPLY_PROGRAMS,
      ROUTES.DIRECT_APPLY_PROGRAM_DETAIL,
      ROUTES.SESSION_DETAIL,
      ROUTES.PAYMENTS,
      ROUTES.NOTIFICATIONS,
    ],
  },
  University: {
    initialRoute: ROUTES.DASHBOARD,
    routes: [
      ROUTES.EVENTS_LIST,
      ROUTES.EVENTS_LIST_DETAILS,
      ROUTES.DASHBOARD,
      ROUTES.SCHOOLS,
      ROUTES.EVENTS,
      ROUTES.TEAM,
      ROUTES.SETTINGS,
      ROUTES.CALENDAR_SYNC,
      ROUTES.UNIVERSITY_DETAILS,
      ROUTES.CIALFO_EVENTS,
      ROUTES.CONNECTIONS,
      ROUTES.DIRECT_APPLY,
      ROUTES.DIRECT_APPLY_APPLICATIONS,
      ROUTES.DIRECT_APPLY_APPLICATION_DETAIL,
      ROUTES.DIRECT_APPLY_PROGRAMS,
      ROUTES.DIRECT_APPLY_PROGRAM_DETAIL,
      ROUTES.EVENT_SESSIONS,
      ROUTES.SESSION_DETAIL,
      ROUTES.PAYMENTS,
      ROUTES.NOTIFICATIONS,
      ROUTES.DASHBOARD,
      ROUTES.PROSPECT_AND_ENGAGE,
      ROUTES.PROSPECT_AND_ENGAGE_INSIGHTS,
      ROUTES.PROSPECT_AND_ENGAGE_PREMIUM,
      ROUTES.PROSPECT_AND_ENGAGE_CONNECT,
      ROUTES.PROSPECT_AND_ENGAGE_EVENTS,
      ROUTES.HIGHER_ED_INSIGHTS,
      ROUTES.HIGHER_ED_INSIGHTS_BETA,
    ],
  },
  ApplicationClient: {
    initialRoute: ROUTES.DIRECT_APPLY_APPLICATIONS,
    routes: [
      ROUTES.DIRECT_APPLY_APPLICATIONS,
      ROUTES.DIRECT_APPLY_APPLICATION_DETAIL,
      ROUTES.DIRECT_APPLY,
      ROUTES.TEAM,
      ROUTES.SETTINGS,
      ROUTES.CONNECTIONS,
      ROUTES.DIRECT_APPLY_PROGRAMS,
      ROUTES.DIRECT_APPLY_PROGRAM_DETAIL,
      ROUTES.SESSION_DETAIL,
      ROUTES.PAYMENTS,
      ROUTES.NOTIFICATIONS,
      ROUTES.CIALFO_EVENTS,
      ROUTES.EVENT_SESSIONS,
      ROUTES.DASHBOARD,
      ROUTES.HIGHER_ED_INSIGHTS,
      ROUTES.HIGHER_ED_INSIGHTS_BETA,
    ],
  },
};
