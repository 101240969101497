import { createReducer } from "reduxsauce";
import {
  SchoolAppointmentTypes as Types,
  UserTypes,
} from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.schoolAppointment;

export const getAppointmentAvailabilitiesFailure = (
  state,
  action,
) => ({
  ...state,
  availabilities: {},
  school: null,
  loadingAvailabilities: false,
});
export const getAppointmentAvailabilitiesRequest = (
  state,
  action,
) => ({
  ...state,
  availabilities: {},
  school: null,
  loadingAvailabilities: true,
});
export const getAppointmentAvailabilitiesSuccess = (
  state,
  action,
) => ({
  ...state,
  availabilities: action.availabilities,
  school: action.school,
  loadingAvailabilities: true,
});

export const getZoomMeetingLinkFailure = (
  state,
  action,
) => ({
  ...state,
  loadingMeeting: false,
});

export const getZoomMeetingLinkRequest = (
  state,
  action,
) => ({
  ...state,
  loadingMeeting: true,
});

export const getZoomMeetingLinkSuccess = (
  state,
  action,
) => ({
  ...state,
  meetingUrl: action.meeting_url,
  meetingInstructions: action.instructions,
  loadingMeeting: false,
});

export const resetZoomValues = (
  state,
  action,
) => ({
  ...state,
  meetingUrl: "",
  meetingInstructions: "",
});

export const connectZoomFailure = (
  state,
  action,
) => ({
  ...state,
  zoomConnectUrl:""
});
export const connectZoomRequest = (
  state,
  action,
) => ({
  ...state,
  zoomConnectUrl:""
});
export const connectZoomSuccess = (
  state,
  action,
) => ({
  ...state,
  zoomConnectUrl:action.url
});

export const resetZoomUrl = (
  state,
  action,
) => ({
  ...state,
  zoomConnectUrl:""
});

export const clearSchoolAppointment = (state, action) =>
  INITIAL_STATE;

export const clearSchoolId = (state, action) => ({
  ...state,
  schoolId: null,
});
export const setSchoolId = (state, action) => ({
  ...state,
  schoolId: action.schoolId,
});

export const setSchoolAppointmentSelection = (state, action) => ({
  ...state,
  selectedDate: action.date,
  selectedSlot: action.slot,
});

const logout = (state, action) => INITIAL_STATE;

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.GET_APPOINTMENT_AVAILABILITIES_FAILURE]:
    getAppointmentAvailabilitiesFailure,
  [Types.GET_APPOINTMENT_AVAILABILITIES_REQUEST]:
    getAppointmentAvailabilitiesRequest,
  [Types.GET_APPOINTMENT_AVAILABILITIES_SUCCESS]:
    getAppointmentAvailabilitiesSuccess,

  [Types.GET_ZOOM_MEETING_LINK_FAILURE]: getZoomMeetingLinkFailure,
  [Types.GET_ZOOM_MEETING_LINK_REQUEST]: getZoomMeetingLinkRequest,
  [Types.GET_ZOOM_MEETING_LINK_SUCCESS]: getZoomMeetingLinkSuccess,
  [Types.RESET_ZOOM_VALUES]: resetZoomValues,

  [Types.CONNECT_ZOOM_FAILURE]: connectZoomFailure,
  [Types.CONNECT_ZOOM_REQUEST]: connectZoomRequest,
  [Types.CONNECT_ZOOM_SUCCESS]: connectZoomSuccess,
  [Types.RESET_ZOOM_URL]: resetZoomUrl,

  [Types.CLEAR_SCHOOL_APPOINTMENT]: clearSchoolAppointment,

  [Types.CLEAR_SCHOOL_ID]: clearSchoolId,
  [Types.SET_SCHOOL_ID]: setSchoolId,

  [Types.SET_SCHOOL_APPOINTMENT_SELECTION]:
    setSchoolAppointmentSelection,

  [UserTypes.LOGOUT]: logout,
};

export default createReducer(INITIAL_STATE, HANDLERS);
