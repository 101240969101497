import { createReducer } from "reduxsauce";
import { NotificationsTypes as Types, UserTypes } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.notifications;

export const clearNotifications = (state, action) => ({
  ...INITIAL_STATE,
});

export const getNotificationsFailure = (state, action) => ({
  ...state,
});
export const getNotificationsRequest = (state, action) => ({
  ...state,
});
export const getNotificationsSuccess = (state, action) => {
  return {
    ...state,
    data:
      action.page_no === 1
        ? action.notifications
        : [...state.data, ...action.notifications],
    num_read: action.read_count,
    num_unread: action.unread_count,
    total: action.total,
    page_no: action.page_no,
    unread_badge_count: action.unread_count,
    has_notifications: action.total > 0,
    has_unread_notifications: action.unread_count > 0,
  };
};

export const markNotificationsReadFailure = (state, action) => ({
  ...state,
});
export const markNotificationsReadRequest = (state, action) => ({
  ...state,
});
export const markNotificationsReadSuccess = (state, action) => {
  const { notificationIds: readNotificationsIds } = action;
  return {
    ...state,
    num_unread: state.num_unread - readNotificationsIds.length,
    unread_badge_count:
      state.num_unread - readNotificationsIds.length,
    has_unread_notifications:
      state.num_unread - readNotificationsIds.length > 0,
    data: state.data.map(notification =>
      readNotificationsIds.includes(notification.id)
        ? {
            ...notification,
            is_read: true,
          }
        : notification,
    ),
  };
};

export const markAllNotificationsReadFailure = (state, action) => ({
  ...state,
});
export const markAllNotificationsReadRequest = (state, action) => ({
  ...state,
});
export const markAllNotificationsReadSuccess = (state, action) => {
  return {
    ...state,
    data: state.data.map(notification => ({
      ...notification,
      is_read: true,
    })),
    num_unread: 0,
    unread_badge_count: 0,
    has_notifications: state.data.length > 0,
    has_unread_notifications: false,
  };
};

export const updateNotificationBadgeCounter = (state, action) => ({
  ...state,
  unread_badge_count: action.count,
  has_unread_notifications: action.count > 0,
});

export const updateNotification = (state, action) => {
  return {
    ...state,
    data: [action.data, ...state.data],
  };
};

const logout = (state, action) => INITIAL_STATE;

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.CLEAR_NOTIFICATIONS]: clearNotifications,

  [Types.GET_NOTIFICATIONS_FAILURE]: getNotificationsFailure,
  [Types.GET_NOTIFICATIONS_REQUEST]: getNotificationsRequest,
  [Types.GET_NOTIFICATIONS_SUCCESS]: getNotificationsSuccess,

  [Types.MARK_NOTIFICATIONS_READ_FAILURE]:
    markNotificationsReadFailure,
  [Types.MARK_NOTIFICATIONS_READ_REQUEST]:
    markNotificationsReadRequest,
  [Types.MARK_NOTIFICATIONS_READ_SUCCESS]:
    markNotificationsReadSuccess,

  [Types.MARK_ALL_NOTIFICATIONS_READ_FAILURE]:
    markAllNotificationsReadFailure,
  [Types.MARK_ALL_NOTIFICATIONS_READ_REQUEST]:
    markAllNotificationsReadRequest,
  [Types.MARK_ALL_NOTIFICATIONS_READ_SUCCESS]:
    markAllNotificationsReadSuccess,

  [Types.UPDATE_NOTIFICATION_BADGE_COUNTER]:
    updateNotificationBadgeCounter,

  [Types.UPDATE_NOTIFICATION]: updateNotification,

  [UserTypes.LOGOUT]: logout,
};

export default createReducer(INITIAL_STATE, HANDLERS);
