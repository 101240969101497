import { connect } from "react-redux";
import { UserCreators } from "src/redux/actions/user.actions";
import SignInScreen from "./sign-in-screen";

function mapStateToProps(state) {
  return {
    authenticationInProgess: state.user.loading,
    user: state.user.data,
    signInForm: state.form.signInForm,
  };
}

const mapDispatchToProps = {
  signIn: UserCreators.authenticationRequest,
  googleSSO: UserCreators.googleSSORequest,
  updateAppLocale: UserCreators.updateAppLocale,
  getZoomAuthUrl: UserCreators.getZoomAuthUrlRequest,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignInScreen);
