/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable no-shadow */
import { call, put, takeEvery, select } from "redux-saga/effects";
import {
  getApplication,
  updateApplication,
  getApplications,
  getDirectApplyFilterCountries,
  getDirectApplyFilterCourses,
  getDirectApplyFilterPrograms,
  getDirectApplyFilterResults,
  getDirectApplyFilterStatus,
  getDirectApplyFilterFeeWaived,
  getDirectApplyFilterSchools,
  getDirectApplicationResults,
  getUcasChoices,
  uploadDirectApplicationDocument,
  uploadUcasReport,
  getDirectApplicationActivities,
  getPrograms,
  deleteDocument,
  getPartnerProgramUniversities,
  getProgramsFilterStatuses,
  getProgramsFilterRounds,
  getDirectApplyFeeStatuses,
  updateChoice,
  resetChoices,
} from "src/web-services";
import isEqual from "lodash/isEqual";
import omit from "lodash/omit";
import pick from "lodash/pick";
import moment from "moment";
import { applicationsSortableColumns } from "src/screens/direct-apply/applications-list/helper";
import { Notification } from "src/helpers/notification";
import { DirectApplyCreators, DirectApplyTypes } from "../actions";

const authTokenSelector = state => state.user.authToken;

export function* watchDirectApplyRequests() {
  yield takeEvery(
    DirectApplyTypes.GET_APPLICATION_REQUEST,
    requestGetApplication,
  );
  yield takeEvery(
    DirectApplyTypes.UPDATE_APPLICATION_REQUEST,
    requestUpdateApplication,
  );
  yield takeEvery(
    DirectApplyTypes.UPDATE_APPLICATION_STATUS_REQUEST,
    requestUpdateApplicationStatus,
  );
  yield takeEvery(
    DirectApplyTypes.UPDATE_APPLICATION_RESULT_REQUEST,
    requestUpdateApplicationResult,
  );
  yield takeEvery(
    DirectApplyTypes.RESET_CHOICE_REQUEST,
    requestResetChoice,
  );
  yield takeEvery(
    DirectApplyTypes.UPDATE_APPLICATION_DEPOSIT_PAID_REQUEST,
    requestUpdateApplicationDepositPaid,
  );
  yield takeEvery(
    DirectApplyTypes.GET_APPLICATIONS_REQUEST,
    requestGetApplications,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLY_FILTER_COUNTRIES_REQUEST,
    requestGetCountriesFilter,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLY_FILTER_COURSES_REQUEST,
    requestGetCoursesFilter,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLY_FILTER_PROGRAMS_REQUEST,
    requestGetProgramsFilter,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLY_FILTER_RESULTS_REQUEST,
    requestGetResultsFilter,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLY_FILTER_STATUS_REQUEST,
    requestGetStatusFilter,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLY_FILTER_FEE_WAIVED_REQUEST,
    requestGetFeeWaivedFilter,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLY_FEE_STATUSES_REQUEST,
    requestGetDirectApplyFeeStatuses,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLY_FILTER_SCHOOLS_REQUEST,
    requestGetSchoolsFilter,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLICATION_RESULTS_REQUEST,
    requestGetDirectApplicationResults,
  );
  yield takeEvery(
    DirectApplyTypes.UPDATE_APPLICATION_UCAS_CHOICE_REQUEST,
    requestUpdateApplicationUcasChoice,
  );
  yield takeEvery(
    DirectApplyTypes.GET_UCAS_CHOICES_REQUEST,
    requestGetUcasChoicesRequest,
  );
  yield takeEvery(
    DirectApplyTypes.UPLOAD_DIRECT_APPLICATION_DOCUMENT_REQUEST,
    requestUploadDirectApplicationDocument,
  );
  yield takeEvery(
    DirectApplyTypes.UPLOAD_UCAS_REPORT_REQUEST,
    requestUploadUcasReport,
  );
  yield takeEvery(
    DirectApplyTypes.DELETE_UCAS_REPORT_REQUEST,
    requestDeleteUcasReport,
  );
  yield takeEvery(
    DirectApplyCreators.getDirectApplyListDownloadUrlRequest().type,
    requestGetListDownloadURL,
  );
  yield takeEvery(
    DirectApplyTypes.GET_DIRECT_APPLICATION_ACTIVITIES_REQUEST,
    requestGetDirectApplicationActivities,
  );
  yield takeEvery(
    DirectApplyTypes.GET_PROGRAMS_REQUEST,
    requestGetPrograms,
  );
  yield takeEvery(
    DirectApplyTypes.DELETE_DIRECT_APPLICATION_DOCUMENT_REQUEST,
    requestDeleteDocument,
  );
  yield takeEvery(
    DirectApplyTypes.GET_PROGRAMS_FILTER_UNIVERSITIES_REQUEST,
    requestGetProgramsFilterUniversities,
  );
  yield takeEvery(
    DirectApplyTypes.GET_PROGRAMS_FILTER_STATUSES_REQUEST,
    requestGetProgramsFilterStatuses,
  );
  yield takeEvery(
    DirectApplyTypes.GET_PROGRAMS_FILTER_ROUNDS_REQUEST,
    requestGetProgramsFilterRounds,
  );
  yield takeEvery(
    DirectApplyTypes.GET_PROGRAMS_LIST_DOWNLOAD_URL_REQUEST,
    requestGetProgramsDownloadURL,
  );
}

const documentUploadedRange = document_upload_date => {
  return document_upload_date
    ? {
        document_uploaded_from: moment(document_upload_date.startDate)
          .startOf("day")
          .format("YYYY-MM-DDTHH:mm:ss"),
        document_uploaded_to: moment(document_upload_date.endDate)
          .endOf("day")
          .format("YYYY-MM-DDTHH:mm:ss"),
      }
    : {};
};

function* requestGetApplication(action) {
  try {
    const { id } = action;
    const authToken = yield select(authTokenSelector);

    const response = yield call(getApplication, authToken, id);
    yield put(
      DirectApplyCreators.getApplicationSuccess(response.data),
    );
  } catch (error) {
    yield put(DirectApplyCreators.getApplicationFailure());
  }
}

function* requestUpdateApplication(action) {
  try {
    const { id, updateData } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      updateApplication,
      authToken,
      id,
      updateData,
    );
    yield put(
      DirectApplyCreators.updateApplicationSuccess(response.data),
    );
  } catch (error) {
    yield put(DirectApplyCreators.updateApplicationFailure());
  }
}

function* requestUpdateApplicationStatus(action) {
  try {
    const { id, updateData } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      updateApplication,
      authToken,
      id,
      updateData,
    );
    yield put(
      DirectApplyCreators.updateApplicationStatusSuccess(
        response.data,
      ),
    );
  } catch (error) {
    yield put(DirectApplyCreators.updateApplicationStatusFailure());
  }
}

function* requestUpdateApplicationResult(action) {
  try {
    const { id, updateData } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      updateApplication,
      authToken,
      id,
      updateData,
    );
    yield put(
      DirectApplyCreators.updateApplicationResultSuccess(
        response.data,
      ),
    );
  } catch (error) {
    yield put(DirectApplyCreators.updateApplicationResultFailure());
  }
}

function* requestResetChoice(action) {
  try {
    const { resetData } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(resetChoices, authToken, resetData);
    yield put(DirectApplyCreators.resetChoiceSuccess(response.data));
  } catch (error) {
    yield put(DirectApplyCreators.resetChoiceFailure());
  }
}

function* requestUpdateApplicationDepositPaid(action) {
  try {
    const { id, updateData } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      updateApplication,
      authToken,
      id,
      updateData,
    );
    yield put(
      DirectApplyCreators.updateApplicationDepositPaidSuccess(
        response.data,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.updateApplicationDepositPaidFailure(),
    );
  }
}

function* requestUpdateApplicationUcasChoice(action) {
  try {
    const { id, updateData } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      updateChoice,
      authToken,
      id,
      updateData,
    );
    yield put(
      DirectApplyCreators.updateApplicationUcasChoiceSuccess(
        response.data,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.updateApplicationUcasChoiceFailure(),
    );
  }
}

function* requestGetApplications(action) {
  try {
    const { resolve } = action;
    const authToken = yield select(authTokenSelector);
    const directApplySelector = state => state.directApply;
    const directApplyListStateSelector = state =>
      state.directApplyListState;
    const { data: currentApplications, params: currentParams } =
      yield select(directApplySelector);
    const {
      filters,
      searchText,
      tab,
      sortBy: { column: sortColumn, direction: sortDirection },
    } = yield select(directApplyListStateSelector);
    const countryFilter =
      filters.country && filters.country.length > 0
        ? { country_ids: filters.country.map(country => country.id) }
        : {};
    const coursesFilter =
      filters.course && filters.course.length > 0
        ? { course_ids: filters.course.map(course => course.id) }
        : {};
    const programsFilter =
      filters.program && filters.program.length > 0
        ? { program_ids: filters.program.map(program => program.id) }
        : {};
    const schoolsFilter =
      filters.school && filters.school.length > 0
        ? { school_ids: filters.school.map(school => school.id) }
        : {};
    const statusFilter =
      filters.status && filters.status.length > 0
        ? { status: filters.status.map(status => status.id) }
        : {};
    const resultsFilter =
      filters.results && filters.results.length > 0
        ? { result: filters.results.map(result => result.id) }
        : {};
    const feeStatusFilter =
      filters.fee_status && filters.fee_status.length > 0
        ? { fee_status: filters.fee_status.map(status => status.id) }
        : {};
    const dateRange = filters.application_date
      ? {
          start_date: moment(filters.application_date.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss"),
          end_date: moment(filters.application_date.endDate)
            .endOf("day")
            .format("YYYY-MM-DDTHH:mm:ss"),
        }
      : {};
    const params = {
      search_text: searchText,
      filters: {
        ...countryFilter,
        ...coursesFilter,
        ...programsFilter,
        ...schoolsFilter,
        ...statusFilter,
        ...resultsFilter,
        application_status: tab,
        ...dateRange,
        ...feeStatusFilter,
        ...documentUploadedRange(filters.document_upload_date),
      },
      sort_params: [
        {
          sort_by: applicationsSortableColumns[sortColumn],
          sort_direction: sortDirection,
        },
      ],
    };

    const shouldLoadMore =
      currentApplications.length > 0 &&
      isEqual(omit(currentParams, ["page_no"]), params);

    if (!shouldLoadMore)
      yield put(DirectApplyCreators.clearApplications());

    const page = shouldLoadMore ? currentParams.page_no + 1 : 1;
    const requestParams = { ...params };
    requestParams["page_no"] = page;

    const response = yield call(
      getApplications,
      authToken,
      requestParams,
    );
    resolve && resolve();
    const { applications, matched, total, past_count, data_count } =
      response.data;
    yield put(
      DirectApplyCreators.getApplicationsSuccess(
        applications,
        matched,
        total,
        past_count,
        requestParams,
        data_count,
      ),
    );
  } catch (error) {
    const { reject } = action;
    reject && reject(error);
    yield put(DirectApplyCreators.getApplicationsFailure());
  }
}

function* requestGetCountriesFilter(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplyFilterCountries,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getDirectApplyFilterCountriesSuccess(
        response.data.countries,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplyFilterCountriesFailure(),
    );
  }
}

function* requestGetCoursesFilter(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplyFilterCourses,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getDirectApplyFilterCoursesSuccess(
        response.data.courses,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplyFilterCoursesFailure(),
    );
  }
}

function* requestGetProgramsFilter(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplyFilterPrograms,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getDirectApplyFilterProgramsSuccess(
        response.data.programs,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplyFilterProgramsFailure(),
    );
  }
}

function* requestGetResultsFilter(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplyFilterResults,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getDirectApplyFilterResultsSuccess(
        response.data.results,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplyFilterResultsFailure(),
    );
  }
}

function* requestGetStatusFilter(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplyFilterStatus,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getDirectApplyFilterStatusSuccess(
        response.data.statuses,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplyFilterStatusFailure(),
    );
  }
}

function* requestGetFeeWaivedFilter(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplyFilterFeeWaived,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getDirectApplyFilterFeeWaivedSuccess(
        response.data.statuses,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplyFilterFeeWaivedFailure(),
    );
  }
}

function* requestGetDirectApplyFeeStatuses(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplyFeeStatuses,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getDirectApplyFeeStatusesSuccess(
        response.data.statuses,
      ),
    );
  } catch (error) {
    yield put(DirectApplyCreators.getDirectApplyFeeStatusesFailure());
  }
}

function* requestGetSchoolsFilter(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplyFilterSchools,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getDirectApplyFilterSchoolsSuccess(
        response.data.schools,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplyFilterSchoolsFailure(),
    );
  }
}

function* requestGetDirectApplicationResults(action) {
  try {
    const { id } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplicationResults,
      authToken,
      id,
    );
    yield put(
      DirectApplyCreators.getDirectApplicationResultsSuccess(
        response.data,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplicationResultsFailure(),
    );
  }
}

function* requestGetUcasChoicesRequest(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(getUcasChoices, authToken, params);
    yield put(
      DirectApplyCreators.getUcasChoicesSuccess(
        response.data.choices,
      ),
    );
  } catch (error) {
    yield put(DirectApplyCreators.getUcasChoicesFailure());
  }
}

function* requestUploadDirectApplicationDocument(action) {
  try {
    const authToken = yield select(authTokenSelector);
    const { id, document } = action;
    const params = new FormData();
    params.append("document", document);
    const applicationAttributeNames = [
      "is_deposit_paid",
      "is_new",
      "result",
      "result_class_name",
      "status",
      "status_class_name",
    ];

    const response = yield call(
      uploadDirectApplicationDocument,
      authToken,
      id,
      params,
    );

    const documentResponse = omit(
      response.data,
      applicationAttributeNames,
    );
    const updatedApplicationAttributes = pick(
      response.data,
      applicationAttributeNames,
    );

    yield put(
      DirectApplyCreators.uploadDirectApplicationDocumentSuccess(
        documentResponse,
        updatedApplicationAttributes,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.uploadDirectApplicationDocumentFailure(),
    );
  }
}

function* requestUploadUcasReport(action) {
  try {
    const authToken = yield select(authTokenSelector);
    const { studentId, data } = action;
    const { document_type, file } = data;
    const params = new FormData();
    params.append(`student[${document_type}]`, file);

    const response = yield call(
      uploadUcasReport,
      authToken,
      studentId,
      params,
    );

    yield put(
      DirectApplyCreators.uploadUcasReportSuccess(response.data),
    );
  } catch (error) {
    yield put(DirectApplyCreators.uploadUcasReportFailure());
  }
}

function* requestDeleteUcasReport(action) {
  try {
    const authToken = yield select(authTokenSelector);
    const { studentId, data } = action;
    const { document_type, document_name } = data;
    const params = new FormData();
    params.append(`student[${document_type}]`, "true");

    const response = yield call(
      uploadUcasReport,
      authToken,
      studentId,
      params,
    );
    Notification.success(`${document_name} deleted successfully`);

    yield put(
      DirectApplyCreators.deleteUcasReportSuccess(response.data),
    );
  } catch (error) {
    yield put(DirectApplyCreators.deleteUcasReportFailure());
  }
}

function* requestGetListDownloadURL(action) {
  try {
    const authToken = yield select(authTokenSelector);
    const directApplyListStateSelector = state =>
      state.directApplyListState;
    const {
      filters,
      searchText,
      tab,
      sortBy: { column: sortColumn, direction: sortDirection },
    } = yield select(directApplyListStateSelector);

    const countryFilter =
      filters.country && filters.country.length > 0
        ? { country_ids: filters.country.map(country => country.id) }
        : {};
    const coursesFilter =
      filters.course && filters.course.length > 0
        ? { course_ids: filters.course.map(course => course.id) }
        : {};
    const programsFilter =
      filters.program && filters.program.length > 0
        ? { program_ids: filters.program.map(program => program.id) }
        : {};
    const schoolsFilter =
      filters.school && filters.school.length > 0
        ? { school_ids: filters.school.map(school => school.id) }
        : {};
    const statusFilter =
      filters.status && filters.status.length > 0
        ? { status: filters.status.map(status => status.id) }
        : {};
    const resultsFilter =
      filters.results && filters.results.length > 0
        ? { result: filters.results.map(result => result.id) }
        : {};
    const feeWaivedFilter =
      filters.fee_waived && filters.fee_waived.length > 0
        ? {
            fee_waived: filters.fee_waived.map(
              fee_waived => fee_waived.id,
            ),
          }
        : {};
    const dateRange = filters.application_date
      ? {
          start_date: moment(filters.application_date.startDate)
            .startOf("day")
            .format("YYYY-MM-DDTHH:mm:ss"),
          end_date: moment(filters.application_date.endDate)
            .endOf("day")
            .format("YYYY-MM-DDTHH:mm:ss"),
        }
      : {};
    const params = {
      download: true,
      search_text: searchText,
      filters: {
        ...countryFilter,
        ...coursesFilter,
        ...programsFilter,
        ...schoolsFilter,
        ...statusFilter,
        ...resultsFilter,
        ...feeWaivedFilter,
        application_status: tab,
        ...dateRange,
        ...documentUploadedRange(filters.document_upload_date),
      },
      sort_params: [
        {
          sort_by: applicationsSortableColumns[sortColumn],
          sort_direction: sortDirection,
        },
      ],
    };
    const response = yield call(getApplications, authToken, params);
    yield put(
      DirectApplyCreators.getDirectApplyListDownloadUrlSuccess(
        response.data.csv_url,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplyListDownloadUrlFailure(),
    );
  }
}

function* requestGetDirectApplicationActivities(action) {
  try {
    const { applicationId } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getDirectApplicationActivities,
      authToken,
      applicationId,
    );
    yield put(
      DirectApplyCreators.getDirectApplicationActivitiesSuccess(
        response.data.activities,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getDirectApplicationActivitiesFailure(),
    );
  }
}

function* requestGetPrograms(action) {
  try {
    const { resolve, params: currentParams } = action;
    const authToken = yield select(authTokenSelector);

    const directApplyProgramsSelector = state =>
      state.directApplyPrograms;
    const { data: currentPrograms, params: previousParams } =
      yield select(directApplyProgramsSelector);

    const shouldLoadMore =
      currentPrograms.length > 0 &&
      isEqual(
        omit(previousParams, ["page_no", "per_page"]),
        currentParams,
      );

    const page = shouldLoadMore ? previousParams.page_no + 1 : 1;

    if (!shouldLoadMore)
      yield put(DirectApplyCreators.clearPrograms());

    const requestParams = {
      per_page: 20,
      ...currentParams,
    };
    requestParams["page_no"] = page;
    const response = yield call(
      getPrograms,
      authToken,
      requestParams,
    );
    resolve && resolve();
    const { programs, matched, total } = response.data;
    yield put(
      DirectApplyCreators.getProgramsSuccess(
        programs,
        matched,
        total,
        requestParams,
      ),
    );
  } catch (error) {
    const { reject } = action;
    reject && reject(error);
    yield put(DirectApplyCreators.getProgramsFailure());
  }
}

function* requestGetProgramsDownloadURL(action) {
  try {
    const { resolve, params: currentParams } = action;
    const authToken = yield select(authTokenSelector);

    const requestParams = {
      ...currentParams,
      download: true,
    };
    const response = yield call(
      getPrograms,
      authToken,
      requestParams,
    );
    resolve && resolve();
    yield put(
      DirectApplyCreators.getProgramsListDownloadUrlSuccess(
        response.data.csv_url,
      ),
    );
  } catch (error) {
    const { reject } = action;
    reject && reject();
    yield put(
      DirectApplyCreators.getProgramsListDownloadUrlFailure(),
    );
  }
}

function* requestDeleteDocument(action) {
  try {
    const authToken = yield select(authTokenSelector);
    const { document } = action;
    yield call(deleteDocument, authToken, document.id);

    yield put(
      DirectApplyCreators.deleteDirectApplicationDocumentSuccess(
        document.id,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.deleteDirectApplicationDocumentFailure(),
    );
  }
}

function* requestGetProgramsFilterUniversities(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getPartnerProgramUniversities,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getProgramsFilterUniversitiesSuccess(
        response.data.universities,
      ),
    );
  } catch (error) {
    yield put(
      DirectApplyCreators.getProgramsFilterUniversitiesFailure(),
    );
  }
}

function* requestGetProgramsFilterStatuses(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getProgramsFilterStatuses,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getProgramsFilterStatusesSuccess(
        response.data.statuses,
      ),
    );
  } catch (error) {
    yield put(DirectApplyCreators.getProgramsFilterStatusesFailure());
  }
}

function* requestGetProgramsFilterRounds(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const response = yield call(
      getProgramsFilterRounds,
      authToken,
      params,
    );
    yield put(
      DirectApplyCreators.getProgramsFilterRoundsSuccess(
        response.data.rounds,
      ),
    );
  } catch (error) {
    yield put(DirectApplyCreators.getProgramsFilterRoundsFailure());
  }
}
