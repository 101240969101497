import { createActions } from "reduxsauce";

const { Types, Creators } = createActions(
  {
    getSessionsFailure: null,
    getSessionsRequest: ["filter"],
    getSessionsSuccess: ["sessions"],

    getSessionFailure: null,
    getSessionRequest: ["id"],
    getSessionSuccess: ["session"],

    updateSessionFailure: null,
    updateSessionRequest: ["id", "params"],
    updateSessionSuccess: ["session"],
  },
  { prefix: "SESSIONS_" },
);

export const SessionsTypes = Types;
export const SessionsCreators = Creators;
