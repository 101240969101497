import { createReducer } from 'reduxsauce'
import { DirectApplyProgramTypes as Types, UserTypes } from '../actions';
import storeInitialState from '../store/initial-state';
import { removeCommasRegex } from "src/constants/programs.constants";

const INITIAL_STATE = storeInitialState.directApplyProgram;

const getProgramFailure = (state, action) => ({ ...state, isLoadingProgram: false, programLoadFailed: true });
const getProgramRequest = (state, action) => ({ ...state, program: null, isLoadingProgram: true, programLoadFailed: false });
const getProgramSuccess = (state, action) => ({ ...state,
  program: {
    ...action.program,
    max_cost: action.program.max_cost?.replace(removeCommasRegex,''),
    min_cost: action.program.min_cost?.replace(removeCommasRegex,''),
  },
   isLoadingProgram: false,
   programLoadFailed: false
  });

const updateProgramFailure = (state, action) => ({ ...state, isUpdating: false, updateSuccess: false });
const updateProgramRequest = (state, action) => ({ ...state, isUpdating: true, updateSuccess: false });
const updateProgramSuccess = (state, action) => ({ ...state, program: action.program, isUpdating: false, updateSuccess: true });

const deleteProgramFailure = (state, action) => ({ ...state, isDeleting: false, deleteSuccess: false });
const deleteProgramRequest = (state, action) => ({ ...state, isDeleting: true, deleteSuccess: false });
const deleteProgramSuccess = (state, action) => ({ ...state, isDeleting: false, deleteSuccess: true });

const saveDeleteProgramRequest = state => ({
  ...state,
  deleteSuccess: false,
  activateSuccess: false,
  deactivateSuccess: false,
});

const createProgramCopyFailure = (state, action) => ({ ...state, isCreatingCopy: false, createProgramCopySuccess: false });
const createProgramCopyRequest = (state, action) => ({ ...state, isCreatingCopy: true, createProgramCopySuccess: false });
const createProgramCopySuccess = (state, action) => ({ ...state, program: action.program, isCreatingCopy: false, createProgramCopySuccess: true });

const createProgramNewFailure = (state, action) => ({ ...state, isCreatingNew: false, createProgramNewSuccess: false });
const createProgramNewRequest = (state, action) => ({ ...state, isCreatingNew: true, createProgramNewSuccess: false });
const createProgramNewSuccess = (state, action) => ({ ...state, program: action.program, isCreatingNew: false, createProgramNewSuccess: true });

const saveProgramDraftFailure = (state, action) => ({ ...state, isSavingDraft: false, saveDraftSuccess: false });
const saveProgramDraftRequest = (state, action) => ({ ...state, isSavingDraft: true, saveDraftSuccess: false });
const saveProgramDraftSuccess = (state, action) => ({ ...state, program: action.program, isSavingDraft: false, saveDraftSuccess: true });

const activateProgramFailure = (state, action) => ({ ...state, isActivating: false, activateSuccess: false });
const activateProgramRequest = (state, action) => ({ ...state, isActivating: true, activateSuccess: false });
const activateProgramSuccess = (state, action) => ({ ...state, program: action.program, isActivating: false, activateSuccess: true });

const deactivateProgramFailure = (state, action) => ({ ...state, isDeactivating: false, deactivateSuccess: false });
const deactivateProgramRequest = (state, action) => ({ ...state, isDeactivating: true, deactivateSuccess: false });
const deactivateProgramSuccess = (state, action) => ({ ...state, program: action.program, isDeactivating: false, deactivateSuccess: true });

const getProgramTypesOptionsFailure = (state, action) => ({ ...state, isFetchingProgramTypes: false });
const getProgramTypesOptionsRequest = (state, action) => ({ ...state, isFetchingProgramTypes: true });
const getProgramTypesOptionsSuccess = (state, action) => ({ ...state, programTypes: action.programTypes, isFetchingProgramTypes: false });

const getProgramTypeContentFailure = (state, action) => ({ ...state, isFetchingProgramTypeContent: false });
const getProgramTypeContentRequest = (state, action) => ({ ...state, isFetchingProgramTypeContent: true });
const getProgramTypeContentSuccess = (state, action) => ({ ...state, programTypeContent: action.content, isFetchingProgramTypeContent: false });

const getCurrenciesFailure = (state, action) => ({ ...state, isFetchingCurrencies: false });
const getCurrenciesRequest = (state, action) => ({ ...state, isFetchingCurrencies: true });
const getCurrenciesSuccess = (state, action) => ({ ...state, currencies: action.currencies, isFetchingCurrencies: false });

const getUniversitiesFailure = (state, action) => ({ ...state, isFetchingUniversities: false });
const getUniversitiesRequest = (state, action) => ({ ...state, isFetchingUniversities: true });
const getUniversitiesSuccess = (state, action) => ({ ...state, universities: action.universities, isFetchingUniversities: false });

const getBrochuresFailure = (state, action) => ({ ...state, isFetchingBrochures: false });
const getBrochuresRequest = (state, action) => ({ ...state, isFetchingBrochures: true });
const getBrochuresSuccess = (state, action) => ({ ...state, brochures: action.brochures, isFetchingBrochures: false });

const getDocumentTypesFailure = (state, action) => ({ ...state, isFetchingDocumentTypes: false });
const getDocumentTypesRequest = (state, action) => ({ ...state, isFetchingDocumentTypes: true });
const getDocumentTypesSuccess = (state, action) => ({ ...state, documentTypes: action.documentTypes, isFetchingDocumentTypes: false });

const getBannersFailure = (state, action) => ({ ...state, isFetchingBanners: false });
const getBannersRequest = (state, action) => ({ ...state, isFetchingBanners: true });
const getBannersSuccess = (state, action) => ({ ...state, banners: action.banners, isFetchingBanners: false });

const getIntakeRoundsFailure = (state, action) => ({...state, isFetchingIntakeRounds: false});
const getIntakeRoundsRequest = (state, action) => ({...state, isFetchingIntakeRounds: true});
const getIntakeRoundsSuccess = (state, action) => ({ ...state, intakeRounds: action.intakeRounds, isFetchingIntakeRounds: false });

const saveDraftCliked = (state, action) => ({ ...state, isDraftClicked: action.value })

const logout = (state, action) => INITIAL_STATE;

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.GET_PROGRAM_FAILURE]: getProgramFailure,
  [Types.GET_PROGRAM_REQUEST]: getProgramRequest,
  [Types.GET_PROGRAM_SUCCESS]: getProgramSuccess,

  [Types.UPDATE_PROGRAM_FAILURE]: updateProgramFailure,
  [Types.UPDATE_PROGRAM_REQUEST]: updateProgramRequest,
  [Types.UPDATE_PROGRAM_SUCCESS]: updateProgramSuccess,

  [Types.DELETE_PROGRAM_FAILURE]: deleteProgramFailure,
  [Types.DELETE_PROGRAM_REQUEST]: deleteProgramRequest,
  [Types.DELETE_PROGRAM_SUCCESS]: deleteProgramSuccess,

  [Types.CREATE_PROGRAM_COPY_FAILURE]: createProgramCopyFailure,
  [Types.CREATE_PROGRAM_COPY_REQUEST]: createProgramCopyRequest,
  [Types.CREATE_PROGRAM_COPY_SUCCESS]: createProgramCopySuccess,

  [Types.CREATE_PROGRAM_NEW_FAILURE]: createProgramNewFailure,
  [Types.CREATE_PROGRAM_NEW_REQUEST]: createProgramNewRequest,
  [Types.CREATE_PROGRAM_NEW_SUCCESS]: createProgramNewSuccess,

  [Types.SAVE_PROGRAM_DRAFT_FAILURE]: saveProgramDraftFailure,
  [Types.SAVE_PROGRAM_DRAFT_REQUEST]: saveProgramDraftRequest,
  [Types.SAVE_PROGRAM_DRAFT_SUCCESS]: saveProgramDraftSuccess,

  [Types.ACTIVATE_PROGRAM_FAILURE]: activateProgramFailure,
  [Types.ACTIVATE_PROGRAM_REQUEST]: activateProgramRequest,
  [Types.ACTIVATE_PROGRAM_SUCCESS]: activateProgramSuccess,

  [Types.DEACTIVATE_PROGRAM_FAILURE]: deactivateProgramFailure,
  [Types.DEACTIVATE_PROGRAM_REQUEST]: deactivateProgramRequest,
  [Types.DEACTIVATE_PROGRAM_SUCCESS]: deactivateProgramSuccess,

  [Types.GET_PROGRAM_TYPES_OPTIONS_FAILURE]: getProgramTypesOptionsFailure,
  [Types.GET_PROGRAM_TYPES_OPTIONS_REQUEST]: getProgramTypesOptionsRequest,
  [Types.GET_PROGRAM_TYPES_OPTIONS_SUCCESS]: getProgramTypesOptionsSuccess,

  [Types.GET_PROGRAM_TYPE_CONTENT_FAILURE]: getProgramTypeContentFailure,
  [Types.GET_PROGRAM_TYPE_CONTENT_REQUEST]: getProgramTypeContentRequest,
  [Types.GET_PROGRAM_TYPE_CONTENT_SUCCESS]: getProgramTypeContentSuccess,

  [Types.GET_CURRENCY_OPTIONS_FAILURE]: getCurrenciesFailure,
  [Types.GET_CURRENCY_OPTIONS_REQUEST]: getCurrenciesRequest,
  [Types.GET_CURRENCY_OPTIONS_SUCCESS]: getCurrenciesSuccess,

  [Types.GET_UNIVERSITY_OPTIONS_FAILURE]: getUniversitiesFailure,
  [Types.GET_UNIVERSITY_OPTIONS_REQUEST]: getUniversitiesRequest,
  [Types.GET_UNIVERSITY_OPTIONS_SUCCESS]: getUniversitiesSuccess,

  [Types.GET_BROCHURES_FAILURE]: getBrochuresFailure,
  [Types.GET_BROCHURES_REQUEST]: getBrochuresRequest,
  [Types.GET_BROCHURES_SUCCESS]: getBrochuresSuccess,

  [Types.GET_DOCUMENT_TYPES_FAILURE]: getDocumentTypesFailure,
  [Types.GET_DOCUMENT_TYPES_REQUEST]: getDocumentTypesRequest,
  [Types.GET_DOCUMENT_TYPES_SUCCESS]: getDocumentTypesSuccess,

  [Types.GET_BANNERS_FAILURE]: getBannersFailure,
  [Types.GET_BANNERS_REQUEST]: getBannersRequest,
  [Types.GET_BANNERS_SUCCESS]: getBannersSuccess,

  [Types.GET_INTAKE_ROUNDS_FAILURE]: getIntakeRoundsFailure,
  [Types.GET_INTAKE_ROUNDS_REQUEST]: getIntakeRoundsRequest,
  [Types.GET_INTAKE_ROUNDS_SUCCESS]: getIntakeRoundsSuccess,

  [Types.SAVE_DELETE_PROGRAM_REQUEST]: saveDeleteProgramRequest,

  [Types.SAVE_DRAFT_CLIKED] : saveDraftCliked,

  [UserTypes.LOGOUT]: logout
};

export default createReducer(INITIAL_STATE, HANDLERS);
