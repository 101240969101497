import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  checkDateAvailabilityPrecedenceFailure: null,
  checkDateAvailabilityPrecedenceRequest: ["date"],
  checkDateAvailabilityPrecedenceSuccess: ["date", "isAvailable"],

  getAvailabilityFailure: null,
  getAvailabilityRequest: [
    "startDate",
    "endDate",
    "universityPartnerId",
  ],
  getAvailabilitySuccess: ["availability", "startDate", "endDate"],

  setAvailabilityFailure: null,
  setAvailabilityRequest: ["params", "resolve", "reject"],
  setAvailabilitySuccess: ["availability"],

  updateAvailabilityFailure: null,
  updateAvailabilityRequest: ["params", "resolve", "reject"],
  updateAvailabilitySuccess: ["availability"],

  deleteAvailabilityFailure: null,
  deleteAvailabilityRequest: ["params", "resolve", "reject"],
  deleteAvailabilitySuccess: ["date", "slotId", "availabilityId"],

  createBulkAvailabilityFailure: null,
  createBulkAvailabilityRequest: ["params", "resolve", "reject"],
  createBulkAvailabilitySuccess: ["availability"],

  getAvailabilitySettingsFailure: null,
  getAvailabilitySettingsRequest: null,
  getAvailabilitySettingsSuccess: [
    "school",
    "primary_contact",
    "member_contacts",
  ],

  updatePrimarySettingsFailure: null,
  updatePrimarySettingsRequest: ["params"],
  updatePrimarySettingsSuccess: [
    "school",
    "primary_contact",
    "member_contacts",
  ],

  updateEventPublishSettingsFailure: null,
  updateEventPublishSettingsRequest: ["params"],
  updateEventPublishSettingsSuccess: ["school"],

  updateMessageSettingsFailure: null,
  updateMessageSettingsRequest: ["params"],
  updateMessageSettingsSuccess: ["school"],

  updateEventLocationSettingsFailure: null,
  updateEventLocationSettingsRequest: ["params"],
  updateEventLocationSettingsSuccess: ["school"],

  updateAutoAcceptEventFailure: null,
  updateAutoAcceptEventRequest: ["autoAccept"],
  updateAutoAcceptEventSuccess: ["school"],

  removeAvailabilityFailure: null,
  removeAvailabilityRequest: ["params", "resolve", "reject"],
  removeAvailabilitySuccess: ["availability"],
});

export const AvailabilityTypes = Types;
export const AvailabilityCreators = Creators;
