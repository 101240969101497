import { call, put, takeEvery, select } from "redux-saga/effects";
import {
  NotificationsCreators,
  NotificationsTypes,
  PageNotificationsCreators,
} from "../actions";
import {
  getNotifications,
  markNotificationsRead,
} from "src/web-services";

const notificationsSelector = state => state.notifications;

export function* watchNotificationRequests() {
  yield takeEvery(
    NotificationsTypes.GET_NOTIFICATIONS_REQUEST,
    requestGetNotifications,
  );
  yield takeEvery(
    NotificationsTypes.MARK_NOTIFICATIONS_READ_REQUEST,
    requestMarkNotificationsRead,
  );
  yield takeEvery(
    NotificationsTypes.MARK_ALL_NOTIFICATIONS_READ_REQUEST,
    requestMarkAllNotificationsRead,
  );
}

export function* requestGetNotifications(action) {
  try {
    const { loadMore, resolve } = action;
    const userSelector = state => state.user;
    const {
      authToken,
      data: { id: userId },
    } = yield select(userSelector);
    const { page_no } = yield select(notificationsSelector);

    if (!loadMore)
      yield put(NotificationsCreators.clearNotifications());

    const params = {
      page_no: loadMore ? page_no + 1 : 1,
    };

    const response = yield call(
      getNotifications,
      authToken,
      userId,
      params,
    );
    resolve && resolve();

    const { notifications, read_count, unread_count, total } =
      response.data;

    yield put(
      NotificationsCreators.getNotificationsSuccess(
        notifications,
        read_count,
        unread_count,
        total,
        params.page_no,
      ),
    );
  } catch (error) {
    const { reject } = action;
    reject && reject();
    yield put(NotificationsCreators.getNotificationsFailure());
  }
}

export function* requestMarkNotificationsRead(action) {
  try {
    const { notificationIds } = action;
    const userSelector = state => state.user;
    const {
      authToken,
      data: { id: userId },
    } = yield select(userSelector);
    const requestParams = new FormData();
    notificationIds.forEach(notificationId =>
      requestParams.append("notifications[ids][]", notificationId),
    );

    yield call(
      markNotificationsRead,
      authToken,
      userId,
      requestParams,
    );
    yield put(
      PageNotificationsCreators.markPageNotificationsReadSuccess(
        notificationIds,
      ),
    );
    yield put(
      NotificationsCreators.markNotificationsReadSuccess(
        notificationIds,
      ),
    );
  } catch (error) {
    yield put(NotificationsCreators.markNotificationsReadFailure());
  }
}

export function* requestMarkAllNotificationsRead(action) {
  try {
    const userSelector = state => state.user;
    const {
      authToken,
      data: { id: userId },
    } = yield select(userSelector);
    const requestParams = new FormData();
    requestParams.append("notifications[read_all]", true);

    yield call(
      markNotificationsRead,
      authToken,
      userId,
      requestParams,
    );
    yield put(
      PageNotificationsCreators.markAllPageNotificationsReadSuccess(),
    );
    yield put(
      NotificationsCreators.markAllNotificationsReadSuccess(),
    );
  } catch (error) {
    yield put(NotificationsCreators.markNotificationsReadFailure());
  }
}
