import { call, put, takeEvery, select } from "redux-saga/effects";
import {
  getEvent,
  getEvents,
  createEvent,
  universityRescheduleEvent,
  schoolRescheduleEvent,
  schoolUpdateEvent,
  universityConfirmEvent,
  schoolUpdateEventLocation,
} from "src/web-services";
import omit from "lodash/omit";
import isEqual from "lodash/isEqual";
import { EventsCreators, EventsTypes } from "../actions";

const authTokenSelector = state => state.user.authToken;
const partnerIdSelector = state => state.user.data.partner_id;
const eventIdSelector = state => state.events.selected.id;

export function* watchEventsRequests() {
  yield takeEvery(EventsTypes.GET_EVENTS_REQUEST, requestGetEvents);
  yield takeEvery(
    EventsTypes.DOWNLOAD_EVENTS_REQUEST,
    requestDownloadEvents,
  );
  yield takeEvery(EventsTypes.GET_EVENT_REQUEST, requestGetEvent);
  yield takeEvery(
    EventsTypes.CREATE_EVENT_REQUEST,
    requestCreateEvent,
  );
  yield takeEvery(
    EventsTypes.RESCHEDULE_EVENT_REQUEST,
    requestRescheduleEvent,
  );
  yield takeEvery(
    EventsTypes.CONFIRM_EVENT_REQUEST,
    requestConfirmEvent,
  );
  yield takeEvery(
    EventsTypes.CANCEL_EVENT_REQUEST,
    requestCancelEvent,
  );
  yield takeEvery(
    EventsTypes.UPDATE_LOCATION_REQUEST,
    requestUpdateLocation,
  );
}

export function* requestGetEvents(action) {
  try {
    const { params, resolve } = action;
    const eventsSelector = state => state.events;
    const authToken = yield select(authTokenSelector);
    const { data: currentEvents, params: currentParams } =
      yield select(eventsSelector);
    const shouldLoadMore =
      currentEvents.length > 0 &&
      isEqual(omit(currentParams, ["page_no"]), params);

    if (!shouldLoadMore) yield put(EventsCreators.clearEvents());

    const page = shouldLoadMore ? currentParams.page_no + 1 : 1;

    const requestParams = { ...params };
    requestParams["page_no"] = page;

    const response = yield call(getEvents, authToken, requestParams);
    resolve && resolve();
    yield put(
      EventsCreators.getEventsSuccess(response.data, requestParams),
    );
  } catch (error) {
    const { reject } = action;
    reject && reject();
    yield put(EventsCreators.getEventsFailure());
  }
}

export function* requestGetEvent(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const eventId = params["event_id"];

    const response = yield call(getEvent, authToken, eventId);
    yield put(EventsCreators.getEventSuccess(response.data));
  } catch (error) {
    yield put(EventsCreators.getEventFailure());
  }
}

export function* requestCreateEvent(action) {
  try {
    const { params, resolve } = action;
    const authToken = yield select(authTokenSelector);
    const partnerId = yield select(partnerIdSelector);

    const response = yield call(
      createEvent,
      authToken,
      partnerId,
      params,
    );
    resolve && resolve();
    yield put(EventsCreators.createEventSuccess(response.data.event));
  } catch (error) {
    const { reject } = action;
    reject && reject();
    yield put(EventsCreators.createEventFailure());
  }
}

export function* requestDownloadEvents(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);

    const response = yield call(getEvents, authToken, params);
    yield put(
      EventsCreators.downloadEventsSuccess(response.data.csv_url),
    );
  } catch (error) {
    yield put(EventsCreators.downloadEventsFailure());
  }
}

export function* requestRescheduleEvent(action) {
  try {
    const { params } = action;
    const userSelector = state => state.user;
    const {
      authToken,
      data: { institute_type },
    } = yield select(userSelector);
    let response = null;
    const eventId = yield select(eventIdSelector);

    if (institute_type === "School") {
      response = yield call(
        schoolRescheduleEvent,
        authToken,
        eventId,
        params,
      );
    } else {
      response = yield call(
        universityRescheduleEvent,
        authToken,
        eventId,
        params,
      );
    }

    yield put(EventsCreators.rescheduleEventSuccess(response.data));
  } catch (error) {
    yield put(EventsCreators.rescheduleEventFailure());
  }
}

export function* requestConfirmEvent(action) {
  try {
    const { params } = action;
    const userSelector = state => state.user;
    const {
      authToken,
      data: { institute_type },
    } = yield select(userSelector);
    let response = null;
    const eventId = yield select(eventIdSelector);

    if (institute_type === "School") {
      response = yield call(
        schoolUpdateEvent,
        authToken,
        eventId,
        params,
      );
    } else {
      response = yield call(
        universityConfirmEvent,
        authToken,
        eventId,
        params,
      );
    }

    yield put(EventsCreators.confirmEventSuccess(response.data));
  } catch (error) {
    yield put(EventsCreators.confirmEventFailure());
  }
}

export function* requestCancelEvent(action) {
  try {
    const { params } = action;
    const userSelector = state => state.user;
    const { authToken } = yield select(userSelector);
    const eventId = yield select(eventIdSelector);
    const response = yield call(
      schoolUpdateEvent,
      authToken,
      eventId,
      params,
    );
    yield put(EventsCreators.cancelEventSuccess(response.data));
  } catch (error) {
    yield put(EventsCreators.cancelEventFailure());
  }
}

export function* requestUpdateLocation(action) {
  try {
    const { params } = action;
    const userSelector = state => state.user;
    const { authToken } = yield select(userSelector);
    const partnerId = yield select(partnerIdSelector);

    const response = yield call(
      schoolUpdateEventLocation,
      authToken,
      partnerId,
      params,
    );
    yield put(EventsCreators.updateLocationSuccess(response.data));
  } catch (error) {
    yield put(EventsCreators.updateLocationFailure());
  }
}
