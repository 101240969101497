import english from "./en.json";
import mySessions from "./webinars/my-sessions.json";
import webinarAbout from "./webinars/about.json";
import webinarFaq from "./webinars/faq.json";
import directApply from "./direct-apply";

const en = {
  ...english,
  ...directApply,
  ...mySessions,
  ...webinarAbout,
  ...webinarFaq,
};

export { en };
