import { API } from "../helpers";
import { SERVER_URL } from "../config";

export function sendMessage(authToken, data) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/institutes/send_message_request`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function sendInvitation(authToken, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/invitations`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export const getPartner = authToken => {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/users/user_partner`,
    headers: {
      authentication_token: authToken,
    },
  });
};

export const getPartnerWeeklyGlance = (authToken) => {
  return API.request({
    method: 'get',
    url: `${SERVER_URL}/api/v1/partners/dashboards/glance`,
    headers: {
      'authentication_token': authToken
    }
  });
}

export const getPartnerConnections = (authToken) => {
  return API.request({
    method: 'get',
    url: `${SERVER_URL}/api/v1/partners/connections/partner_connections`,
    headers: {
      'authentication_token': authToken
    }
  });
}

export const updatePartnerTimezone = (authToken, data) => {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/timezones/set_timezone`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
};

export function setCalendarToken(authToken, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/institutes/1/google_sync`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function syncCalendars(authToken, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/institutes/1/calendars`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getSyncedCalendars(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/institutes/1/calendars`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getGoogleAuthorizationUrl(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/institutes/1/google_authorization`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getAvailableCalendars(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/institutes/1/fetch_calendars`,
    headers: {
      authentication_token: authToken,
    },
  });
}
