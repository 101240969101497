import React, { Component } from "react";
import PropTypes from "prop-types";

class InputField extends Component {
  render() {
    const {
      input,
      meta: { error, touched },
      error: oldError,
      ...rest
    } = this.props;
    const showError = touched && error;

    return (
      <div className="flex flex-col">
        <input
          {...input}
          {...rest}
          className={`input-field ${
            showError || oldError ? "input-field-error" : ""
          } focus:outline-none focus:border-blue-700`}
        />
        {showError || oldError ? (
          <span className="input-field-error-text">
            {error || oldError}
          </span>
        ) : null}
      </div>
    );
  }
}

InputField.defaultProps = {
  meta: {},
};

InputField.propTypes = {
  meta: PropTypes.object,
  input: PropTypes.object,
};

export default InputField;
