/* eslint-disable import/order */
import React from "react";
import ReactDOM from "react-dom";
import "./css/app.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Notification } from "./components/notification";
import configureStore from "./redux/store";
import { Provider } from "react-redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import Alert from "react-s-alert";
import "./i18n";
import "react-s-alert/dist/s-alert-default.css";
import "react-s-alert/dist/s-alert-css-effects/stackslide.css";

const store = configureStore();
const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
      <Alert contentTemplate={Notification} stack={{ limit: 3 }} />
    </PersistGate>
  </Provider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
