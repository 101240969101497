import { createReducer } from "reduxsauce";
import { ConnectionsTypes, UserTypes } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.connections;

export const clearConnections = state => {
  return {
    ...state,
    data: [],
  };
};

export const getConnectionsRequest = (state, action) => {
  if (action.params.user_type !== state.params.user_type) {
    return {
      ...state,
      loadingConnection: true,
      data: [],
    };
  }
  return {
    ...state,
    loadingConnection: true,
  };
};

export const getConnectionsSuccess = (state, action) => {
  const {
    connectionsData: { connections, matched, total },
    params,
  } = action;
  return {
    ...state,
    data:
      params.page_no === 1
        ? connections
        : [...state.data, ...connections],
    matched,
    total,
    params: { ...state.params, ...params },
    loadingConnection: false,
  };
};

export const getConnectionsFailure = (state, action) => ({
  ...state,
  loadingConnection: false,
});

export const getConnectionDetailsFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getConnectionDetailsRequest = (state, action) => ({
  ...state,
  selectedConnectionDetails: {},
  loading: true,
});
export const getConnectionDetailsSuccess = (state, action) => {
  const { response } = action;

  return {
    ...state,
    selectedConnectionDetails: response,
    loading: false,
  };
};

export const getEngagementDataFailure = state => ({
  ...state,
  loadingEngagement: false,
});
export const getEngagementDataRequest = state => ({
  ...state,
  loadingEngagement: true,
});
export const getEngagementDataSuccess = (state, action) => {
  const { params } = action;
  const { response } = action;

  if (params.page_no > 1) {
    const res = JSON.parse(JSON.stringify(response));

    return {
      ...state,
      selectedActivities: {
        engagements: [
          ...state.selectedActivities.engagements,
          ...res.engagements,
        ],
      },
      totalActivities: response.total_activities,
      loadingEngagement: false,
      activitiesParams: { ...state.activitiesParams, ...params },
    };
  }

  return {
    ...state,
    selectedActivities: response,
    totalActivities: response.total_activities,
    loadingEngagement: false,
    activitiesParams: { ...state.activitiesParams, ...params },
  };
};

export const getConnectionCountriesFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getConnectionCountriesRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getConnectionCountriesSuccess = (state, action) => {
  const { response } = action;

  return {
    ...state,
    countries: response,
    loading: false,
  };
};

export const getConnectionWebinarsFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getConnectionWebinarsRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getConnectionWebinarsSuccess = (state, action) => {
  const { response } = action;
  return {
    ...state,
    webinars: response,
    loading: false,
  };
};

export const getConnectionSourcesFailure = state => ({
  ...state,
  loading: false,
});
export const getConnectionSourcesRequest = state => ({
  ...state,
  loading: true,
});
export const getConnectionSourcesSuccess = (state, action) => {
  const { response } = action;
  return {
    ...state,
    sources: response,
    loading: false,
  };
};

export const getConnectionActivitiesFailure = state => ({
  ...state,
  loading: false,
});
export const getConnectionActivitiesRequest = state => ({
  ...state,
  loading: true,
});
export const getConnectionActivitiesSuccess = (state, action) => {
  const { response } = action;
  return {
    ...state,
    activities: response,
    loading: false,
  };
};

export const getConnectionStatusesFailure = state => ({
  ...state,
  loading: false,
});
export const getConnectionStatusesRequest = state => ({
  ...state,
  loading: true,
});
export const getConnectionStatusesSuccess = (state, action) => {
  const { response } = action;
  return {
    ...state,
    statuses: response,
    loading: false,
  };
};

export const getConnectionCitizenshipFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getConnectionCitizenshipRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getConnectionCitizenshipSuccess = (state, action) => {
  const { response } = action;
  return {
    ...state,
    citizenship: response,
    loading: false,
  };
};

export const getConnectionGraduationYearFailure = (
  state,
  action,
) => ({ ...state, loading: false });
export const getConnectionGraduationYearRequest = (
  state,
  action,
) => ({ ...state, loading: true });
export const getConnectionGraduationYearSuccess = (state, action) => {
  const { response } = action;
  return {
    ...state,
    graduationYear: response,
    loading: false,
  };
};

export const downloadConnectionsSuccess = (state, action) => ({
  ...state,
  loading: false,
});
export const downloadConnectionsRequest = (state, action) => ({
  ...state,
  loading: false,
});
export const downloadConnectionsFailure = (state, action) => ({
  ...state,
  loading: false,
});

export const resetEngagementData = state => ({
  ...state,
  activitiesParams: { ...state.activitiesParams, page_no: 0 },
  selectedActivities: { engagements: [] },
});

export const hideBanner = state => ({
  ...state,
  showBanner: false,
});

export const hideCountsSuccess = (state, action) => {
  const { response } = action;
  return {
    ...state,
    loading: false,
    connectionsCount: response.connections_count,
    counselorConnectionsCount: response.counselor_connections_count,
    guardianConnectionsCount: response.guardian_connections_count,
    studentConnectionsCount: response.student_connections_count,
  };
};
export const hideCountsRequest = state => ({
  ...state,
  loading: false,
});
export const hideCountsFailure = state => ({
  ...state,
  loading: false,
});

export const getConnectionSchoolStatesFailure = state => ({
  ...state,
  loading: false,
});
export const getConnectionSchoolStatesRequest = state => ({
  ...state,
  loading: true,
});
export const getConnectionSchoolStatesSuccess = (state, action) => {
  const { response } = action;
  return {
    ...state,
    schoolStates: response,
    loading: false,
  };
};

export const getConnectionSchoolNamesFailure = state => ({
  ...state,
  loading: false,
});
export const getConnectionSchoolNamesRequest = state => ({
  ...state,
  loading: true,
});
export const getConnectionSchoolNamesSuccess = (state, action) => {
  const { response } = action;
  return {
    ...state,
    schoolNames: response,
    loading: false,
  };
};

export const getConnectionSchoolCityFailure = state => ({
  ...state,
  loading: false,
});
export const getConnectionSchoolCityRequest = state => ({
  ...state,
  loading: true,
});
export const getConnectionSchoolCitySuccess = (state, action) => {
  const { response } = action;
  return {
    ...state,
    schoolCity: response,
    loading: false,
  };
};

export const getConnectionCourseInterestFailure = state => ({
  ...state,
  loading: false,
});
export const getConnectionCourseInterestRequest = state => ({
  ...state,
  loading: true,
});
export const getConnectionCourseInterestSuccess = (state, action) => {
  const { response } = action;
  return {
    ...state,
    courseInterest: response,
    loading: false,
  };
};

export const getConnectionAcademicInterestFailure = state => ({
  ...state,
  loading: false,
});
export const getConnectionAcademicInterestRequest = state => ({
  ...state,
  loading: true,
});
export const getConnectionAcademicInterestSuccess = (
  state,
  action,
) => {
  const { response } = action;
  return {
    ...state,
    academicInterest: response,
    loading: false,
  };
};

export const getConnectionEventTitleFailure = state => ({
  ...state,
  loading: false,
});
export const getConnectionEventTitleRequest = state => ({
  ...state,
  loading: true,
});
export const getConnectionEventTitleSuccess = (state, action) => {
  const { response } = action;
  return {
    ...state,
    eventTitle: response,
    loading: false,
  };
};

const logout = (state, action) => INITIAL_STATE;

export const HANDLERS = {
  [ConnectionsTypes.GET_CONNECTIONS_SUCCESS]: getConnectionsSuccess,
  [ConnectionsTypes.GET_CONNECTIONS_REQUEST]: getConnectionsRequest,

  [ConnectionsTypes.DOWNLOAD_CONNECTIONS_SUCCESS]:
    downloadConnectionsSuccess,
  [ConnectionsTypes.DOWNLOAD_CONNECTIONS_REQUEST]:
    downloadConnectionsRequest,
  [ConnectionsTypes.DOWNLOAD_CONNECTIONS_FAILURE]:
    downloadConnectionsFailure,

  [ConnectionsTypes.GET_CONNECTION_DETAILS_SUCCESS]:
    getConnectionDetailsSuccess,
  [ConnectionsTypes.GET_CONNECTION_DETAILS_REQUEST]:
    getConnectionDetailsRequest,
  [ConnectionsTypes.GET_CONNECTION_DETAILS_FAILURE]:
    getConnectionDetailsFailure,

  [ConnectionsTypes.GET_ENGAGEMENT_DATA_SUCCESS]:
    getEngagementDataSuccess,
  [ConnectionsTypes.GET_ENGAGEMENT_DATA_REQUEST]:
    getEngagementDataRequest,
  [ConnectionsTypes.GET_ENGAGEMENT_DATA_FAILURE]:
    getEngagementDataFailure,

  [ConnectionsTypes.GET_CONNECTION_COUNTRIES_REQUEST]:
    getConnectionCountriesRequest,
  [ConnectionsTypes.GET_CONNECTION_COUNTRIES_SUCCESS]:
    getConnectionCountriesSuccess,
  [ConnectionsTypes.GET_CONNECTION_COUNTRIES_FAILURE]:
    getConnectionCountriesFailure,

  [ConnectionsTypes.GET_CONNECTION_CITIZENSHIP_REQUEST]:
    getConnectionCitizenshipRequest,
  [ConnectionsTypes.GET_CONNECTION_CITIZENSHIP_SUCCESS]:
    getConnectionCitizenshipSuccess,
  [ConnectionsTypes.GET_CONNECTION_CITIZENSHIP_FAILURE]:
    getConnectionCitizenshipFailure,

  [ConnectionsTypes.GET_CONNECTION_WEBINARS_REQUEST]:
    getConnectionWebinarsRequest,
  [ConnectionsTypes.GET_CONNECTION_WEBINARS_SUCCESS]:
    getConnectionWebinarsSuccess,
  [ConnectionsTypes.GET_CONNECTION_WEBINARS_FAILURE]:
    getConnectionWebinarsFailure,

  [ConnectionsTypes.GET_CONNECTION_SOURCES_REQUEST]:
    getConnectionSourcesRequest,
  [ConnectionsTypes.GET_CONNECTION_SOURCES_SUCCESS]:
    getConnectionSourcesSuccess,
  [ConnectionsTypes.GET_CONNECTION_SOURCES_FAILURE]:
    getConnectionSourcesFailure,

  [ConnectionsTypes.GET_CONNECTION_ACTIVITIES_REQUEST]:
    getConnectionActivitiesRequest,
  [ConnectionsTypes.GET_CONNECTION_ACTIVITIES_SUCCESS]:
    getConnectionActivitiesSuccess,
  [ConnectionsTypes.GET_CONNECTION_ACTIVITIES_FAILURE]:
    getConnectionActivitiesFailure,

  [ConnectionsTypes.GET_CONNECTION_STATUSES_REQUEST]:
    getConnectionStatusesRequest,
  [ConnectionsTypes.GET_CONNECTION_STATUSES_SUCCESS]:
    getConnectionStatusesSuccess,
  [ConnectionsTypes.GET_CONNECTION_STATUSES_FAILURE]:
    getConnectionStatusesFailure,

  [ConnectionsTypes.GET_CONNECTION_SCHOOL_STATES_REQUEST]:
    getConnectionSchoolStatesRequest,
  [ConnectionsTypes.GET_CONNECTION_SCHOOL_STATES_SUCCESS]:
    getConnectionSchoolStatesSuccess,
  [ConnectionsTypes.GET_CONNECTION_SCHOOL_STATES_FAILURE]:
    getConnectionSchoolStatesFailure,

  [ConnectionsTypes.GET_CONNECTION_SCHOOL_NAMES_REQUEST]:
    getConnectionSchoolNamesRequest,
  [ConnectionsTypes.GET_CONNECTION_SCHOOL_NAMES_SUCCESS]:
    getConnectionSchoolNamesSuccess,
  [ConnectionsTypes.GET_CONNECTION_SCHOOL_NAMES_FAILURE]:
    getConnectionSchoolNamesFailure,

  [ConnectionsTypes.GET_CONNECTION_SCHOOL_CITY_REQUEST]:
    getConnectionSchoolCityRequest,
  [ConnectionsTypes.GET_CONNECTION_SCHOOL_CITY_SUCCESS]:
    getConnectionSchoolCitySuccess,
  [ConnectionsTypes.GET_CONNECTION_SCHOOL_CITY_FAILURE]:
    getConnectionSchoolCityFailure,

  [ConnectionsTypes.GET_CONNECTION_COURSE_INTEREST_REQUEST]:
    getConnectionCourseInterestRequest,
  [ConnectionsTypes.GET_CONNECTION_COURSE_INTEREST_SUCCESS]:
    getConnectionCourseInterestSuccess,
  [ConnectionsTypes.GET_CONNECTION_COURSE_INTEREST_FAILURE]:
    getConnectionCourseInterestFailure,

  [ConnectionsTypes.GET_CONNECTION_ACADEMIC_INTEREST_REQUEST]:
    getConnectionAcademicInterestRequest,
  [ConnectionsTypes.GET_CONNECTION_ACADEMIC_INTEREST_SUCCESS]:
    getConnectionAcademicInterestSuccess,
  [ConnectionsTypes.GET_CONNECTION_ACADEMIC_INTEREST_FAILURE]:
    getConnectionAcademicInterestFailure,

  [ConnectionsTypes.GET_CONNECTION_EVENT_TITLE_REQUEST]:
    getConnectionEventTitleRequest,
  [ConnectionsTypes.GET_CONNECTION_EVENT_TITLE_SUCCESS]:
    getConnectionEventTitleSuccess,
  [ConnectionsTypes.GET_CONNECTION_EVENT_TITLE_FAILURE]:
    getConnectionEventTitleFailure,

  [ConnectionsTypes.GET_CONNECTION_GRADUATION_YEAR_REQUEST]:
    getConnectionGraduationYearRequest,
  [ConnectionsTypes.GET_CONNECTION_GRADUATION_YEAR_SUCCESS]:
    getConnectionGraduationYearSuccess,
  [ConnectionsTypes.GET_CONNECTION_GRADUATION_YEAR_FAILURE]:
    getConnectionGraduationYearFailure,

  [ConnectionsTypes.RESET_ENGAGEMENT_DATA]: resetEngagementData,

  [ConnectionsTypes.HIDE_BANNER]: hideBanner,

  [ConnectionsTypes.HIDE_COUNTS_REQUEST]: hideCountsRequest,
  [ConnectionsTypes.HIDE_COUNTS_SUCCESS]: hideCountsSuccess,
  [ConnectionsTypes.HIDE_COUNTS_FAILURE]: hideCountsFailure,

  [ConnectionsTypes.CLEAR_CONNECTIONS]: clearConnections,

  [UserTypes.LOGOUT]: logout,
};

export default createReducer(INITIAL_STATE, HANDLERS);
