import { createReducer } from "reduxsauce";
import { SessionsTypes as Types, UserTypes } from "../actions";
import storeInitialState from "../store/initial-state";

const INITIAL_STATE = storeInitialState.sessions;

const getSessionsFailure = (state, action) => ({
  ...state,
  isFetchingList: false,
  listFetchSuccess: false,
});
const getSessionsRequest = (state, action) => ({
  ...state,
  isFetchingList: true,
  listFetchSuccess: false,
});
const getSessionsSuccess = (state, action) => ({
  ...state,
  list: action.sessions,
  isFetchingList: false,
  listFetchSuccess: true,
});

const getSessionFailure = (state, action) => ({
  ...state,
  isFetchingSession: false,
  fetchSessionFailed: true,
});
const getSessionRequest = (state, action) => ({
  ...state,
  session: null,
  isFetchingSession: true,
  fetchSessionSuccess: false,
  fetchSessionFailed: false,
});
const getSessionSuccess = (state, action) => ({
  ...state,
  session: action.session,
  isFetchingSession: false,
  fetchSessionSuccess: true,
});

const updateSessionFailure = (state, action) => ({
  ...state,
  isUpdatingSession: false,
  updateSessionFailed: true,
});
const updateSessionRequest = (state, action) => ({
  ...state,
  isUpdatingSession: true,
  updateSessionSuccess: false,
  updateSessionFailed: false,
});
const updateSessionSuccess = (state, action) => ({
  ...state,
  session: action.session,
  isUpdatingSession: false,
  updateSessionSuccess: true,
});

const logout = (state, action) => INITIAL_STATE;

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.GET_SESSIONS_FAILURE]: getSessionsFailure,
  [Types.GET_SESSIONS_REQUEST]: getSessionsRequest,
  [Types.GET_SESSIONS_SUCCESS]: getSessionsSuccess,

  [Types.GET_SESSION_FAILURE]: getSessionFailure,
  [Types.GET_SESSION_REQUEST]: getSessionRequest,
  [Types.GET_SESSION_SUCCESS]: getSessionSuccess,

  [Types.UPDATE_SESSION_FAILURE]: updateSessionFailure,
  [Types.UPDATE_SESSION_REQUEST]: updateSessionRequest,
  [Types.UPDATE_SESSION_SUCCESS]: updateSessionSuccess,

  [UserTypes.LOGOUT]: logout,
};

export default createReducer(INITIAL_STATE, HANDLERS);
