/* eslint-disable no-else-return */
/* eslint-disable camelcase */
/* eslint-disable global-require */
import moment from "moment";
import { ROUTES } from "src/config/user-authorization";
import { Utility } from "./utility";

const isNonEmptyString = Utility.isNonEmptyString;

const IMAGE_NAMES = {
  visit_reschedule:
    require(`src/assets/notifications/visit_reschedule.svg`).default,
  college_fair: require(`src/assets/notifications/college_fair.svg`)
    .default,
  visit_pending: require(`src/assets/notifications/visit_pending.svg`)
    .default,
  visit_cancel: require(`src/assets/notifications/visit_cancel.svg`)
    .default,
  visit_confirm: require(`src/assets/notifications/visit_confirm.svg`)
    .default,
  program_active:
    require(`src/assets/notifications/program_active.svg`).default,
  program_declined:
    require(`src/assets/notifications/program_declined.svg`).default,
  application_new_application:
    require(`src/assets/notifications/application_new_application.svg`)
      .default,
  application_update_application:
    require(`src/assets/notifications/application_update_application.svg`)
      .default,
  application_withdraw_application:
    require(`src/assets/notifications/application_withdraw_application.svg`)
      .default,
  connection: require(`src/assets/notifications/connection.svg`)
    .default,
};

export class AppNotification {
  static getLink(notification = {}) {
    const { application_id, event_id, notification_type } =
      notification;

    if (notification_type === "Event") {
      return `/events/${event_id}`;
    } else if (notification_type === "StudentApplication") {
      return `/direct-apply/applications/${application_id}`;
    } else if (notification_type === "PartnerProgram") {
      return `/direct-apply/programs`;
    } else if (notification_type === "WebinarCategory") {
      return `/event/cialfo-events`;
    }
    if (notification_type === "Connection") {
      return ROUTES.CONNECTIONS;
    } else {
      return "/notifications";
    }
  }

  static getHeader(notification = {}) {
    const { message, fair_name, notification_type } = notification;

    if (
      notification_type === "Event" ||
      notification_type === "StudentApplication" ||
      notification_type === "PartnerProgram" ||
      notification_type === "Connection"
    ) {
      return isNonEmptyString(message) ? message : "";
    } else if (notification_type === "WebinarCategory") {
      return isNonEmptyString(fair_name) ? fair_name : "";
    } else {
      return "";
    }
  }

  static getBody(notification = {}, isSchool = false) {
    const {
      institute_name,
      program_name,
      notification_type,
      partner_university,
      partner_school,
      participate_by,
      sub_message,
    } = notification;

    if (notification_type === "StudentApplication") {
      return isNonEmptyString(institute_name) ? institute_name : "";
    } else if (notification_type === "PartnerProgram") {
      return isNonEmptyString(program_name) ? program_name : "";
    } else if (notification_type === "Event") {
      return isSchool ? partner_university : partner_school;
    } else if (notification_type === "WebinarCategory") {
      return `Participate by ${moment(participate_by).format(
        "DD MMM, YYYY",
      )}.`;
    } else if (notification_type === "Connection") {
      return isNonEmptyString(sub_message) ? sub_message : "";
    } else {
      return "";
    }
  }

  static hasParticipationDateExpired(date = undefined) {
    return moment().format(date) < moment().format();
  }

  static getImageName(notification = {}) {
    const { image_name } = notification;

    if (IMAGE_NAMES[image_name]) {
      return IMAGE_NAMES[image_name];
    }

    return "application_new_application"; // need to have a default image in case image name is not found/incorrect.
  }
}
