import { createActions } from "reduxsauce";

const { Types, Creators } = createActions({
  clearNotifications: null,

  getNotificationsFailure: null,
  getNotificationsRequest: ["loadMore", "resolve", "reject"],
  getNotificationsSuccess: [
    "notifications",
    "read_count",
    "unread_count",
    "total",
    "page_no",
  ],

  markNotificationsReadFailure: null,
  markNotificationsReadRequest: ["notificationIds"],
  markNotificationsReadSuccess: ["notificationIds"],

  markAllNotificationsReadFailure: null,
  markAllNotificationsReadRequest: null,
  markAllNotificationsReadSuccess: null,

  updateNotification: ["data"],

  updateNotificationBadgeCounter: ["count"],
});

export const NotificationsTypes = Types;
export const NotificationsCreators = Creators;
