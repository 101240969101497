import { createReducer } from "reduxsauce";
import { MiscTypes as Types, UserTypes } from "../actions";
import storeInitialState from "../store/initial-state";

export const INITIAL_STATE = storeInitialState.misc;

export const getCountriesFailure = (state, action) => ({
  ...state,
  loadingCountries: false,
});
export const getCountriesRequest = (state, action) => ({
  ...state,
  loadingCountries: true,
});
export const getCountriesSuccess = (state, action) => ({
  ...state,
  countries: action.countries,
  loadingCountries: false,
});

export const getEventsCountriesFailure = (state, action) => ({
  ...state,
});
export const getEventsCountriesRequest = (state, action) => ({
  ...state,
});
export const getEventsCountriesSuccess = (state, action) => ({
  ...state,
  eventsCountries: action.countries,
});

export const getTeamCountriesFailure = (state, action) => ({
  ...state,
});
export const getTeamCountriesRequest = (state, action) => ({
  ...state,
});
export const getTeamCountriesSuccess = (state, action) => ({
  ...state,
  teamCountries: action.countries,
});

export const getTimezonesFailure = (state, action) => ({
  ...state,
  loadingTimezones: false,
});
export const getTimezonesRequest = (state, action) => ({
  ...state,
  loadingTimezones: true,
});
export const getTimezonesSuccess = (state, action) => ({
  ...state,
  timezones: action.timezones,
  loadingTimezones: false,
});

export const getRegionsFailure = (state, action) => ({
  ...state,
  loadingRegions: false,
});
export const getRegionsRequest = (state, action) => ({
  ...state,
  loadingRegions: true,
});
export const getRegionsSuccess = (state, action) => ({
  ...state,
  regions: action.regions,
  loadingRegions: false,
});

export const getSchoolSizesFailure = (state, action) => ({
  ...state,
  loadingSchoolSizes: false,
});
export const getSchoolSizesRequest = (state, action) => ({
  ...state,
  loadingSchoolSizes: true,
});
export const getSchoolSizesSuccess = (state, action) => ({
  ...state,
  schoolSizes: action.schoolSizes,
  loadingSchoolSizes: false,
});

export const getManagementToolsFailure = (state, action) => ({
  ...state,
  loadingManagementTools: false,
});
export const getManagementToolsRequest = (state, action) => ({
  ...state,
  loadingManagementTools: true,
});
export const getManagementToolsSuccess = (state, action) => ({
  ...state,
  managementTools: action.managementTools,
  loadingManagementTools: false,
});

export const getMembersFailure = (state, action) => ({
  ...state,
  loadingMembers: false,
});
export const getMembersRequest = (state, action) => ({
  ...state,
  loadingMembers: true,
});
export const getMembersSuccess = (state, action) => ({
  ...state,
  members: action.members,
  loadingMembers: false,
});

export const getAdminsFailure = (state, action) => ({
  ...state,
  loadingAdmins: false,
});
export const getAdminsRequest = (state, action) => ({
  ...state,
  loadingAdmins: true,
});
export const getAdminsSuccess = (state, action) => ({
  ...state,
  admins: action.admins,
  loadingAdmins: false,
});

export const getEventModificationReasonsFailure = (
  state,
  action,
) => ({ ...state, loadingEventModificationReasons: false });
export const getEventModificationReasonsRequest = (
  state,
  action,
) => ({ ...state, loadingEventModificationReasons: true });
export const getEventModificationReasonsSuccess = (
  state,
  action,
) => ({
  ...state,
  eventModificationReasons: action.reasons,
  loadingEventModificationReasons: false,
});

export const getEventDeclineReasonsFailure = state => ({
  ...state,
  loadingEventModificationReasons: false,
});
export const getEventDeclineReasonsRequest = state => ({
  ...state,
  loadingEventDeclineReasons: true,
});
export const getEventDeclineReasonsSuccess = (state, action) => ({
  ...state,
  eventDeclineReasons: action.declineReasons,
  loadingEventDeclineReasons: false,
});

export const getMiscDataFailure = (state, action) => ({
  ...state,
  loading: false,
});
export const getMiscDataRequest = (state, action) => ({
  ...state,
  loading: true,
});
export const getMiscDataSuccess = (state, action) => {
  const { countries, timezones, regions, schoolSizes, reasons } =
    action;

  return {
    ...state,
    countries,
    timezones,
    regions,
    schoolSizes,
    eventModificationReasons: reasons,
    loading: false,
  };
};

export const getPaymentInfoFailure = (state, action) => ({
  ...state,
});
export const getPaymentInfoRequest = (state, action) => ({
  ...state,
  paymentInfo: null,
});
export const getPaymentInfoSuccess = (state, action) => {
  const { clientId, orderId } = action;

  return {
    ...state,
    paymentInfo: {
      clientId,
      orderId,
    },
  };
};

export const sendSlackNotificationFailure = (state, action) => ({
  ...state,
  isSendingSlackNotifications: false,
  sendSlackNotificationSuccess: false,
});
export const sendSlackNotificationRequest = (state, action) => ({
  ...state,
  isSendingSlackNotifications: true,
  sendSlackNotificationSuccess: false,
});
export const sendSlackNotificationSuccess = (state, action) => ({
  ...state,
  isSendingSlackNotifications: false,
  sendSlackNotificationSuccess: true,
});
export const resetSlackNotificationState = (state, action) => ({
  ...state,
  isSendingSlackNotifications: false,
  sendSlackNotificationSuccess: false,
});

export const hideCalendarExpiryMessage = (state, action) => ({
  ...state,
  isCalendarExpireMessageVisible: false,
});
export const showCalendarExpiryMessage = (state, action) => ({
  ...state,
  isCalendarExpireMessageVisible: true,
});

export const setHasPageLoadTransactionEnded = (
  state,
  { hasPageLoadTransactionEnded },
) => ({
  ...state,
  hasPageLoadTransactionEnded,
});

const logout = (state, action) => INITIAL_STATE;

// map our action types to our reducer functions
export const HANDLERS = {
  [Types.GET_COUNTRIES_FAILURE]: getCountriesFailure,
  [Types.GET_COUNTRIES_REQUEST]: getCountriesRequest,
  [Types.GET_COUNTRIES_SUCCESS]: getCountriesSuccess,

  [Types.GET_EVENTS_COUNTRIES_FAILURE]: getEventsCountriesFailure,
  [Types.GET_EVENTS_COUNTRIES_REQUEST]: getEventsCountriesRequest,
  [Types.GET_EVENTS_COUNTRIES_SUCCESS]: getEventsCountriesSuccess,

  [Types.GET_TEAM_COUNTRIES_FAILURE]: getTeamCountriesFailure,
  [Types.GET_TEAM_COUNTRIES_REQUEST]: getTeamCountriesRequest,
  [Types.GET_TEAM_COUNTRIES_SUCCESS]: getTeamCountriesSuccess,

  [Types.GET_TIMEZONES_FAILURE]: getTimezonesFailure,
  [Types.GET_TIMEZONES_REQUEST]: getTimezonesRequest,
  [Types.GET_TIMEZONES_SUCCESS]: getTimezonesSuccess,

  [Types.GET_REGIONS_FAILURE]: getRegionsFailure,
  [Types.GET_REGIONS_REQUEST]: getRegionsRequest,
  [Types.GET_REGIONS_SUCCESS]: getRegionsSuccess,

  [Types.GET_SCHOOL_SIZES_FAILURE]: getSchoolSizesFailure,
  [Types.GET_SCHOOL_SIZES_REQUEST]: getSchoolSizesRequest,
  [Types.GET_SCHOOL_SIZES_SUCCESS]: getSchoolSizesSuccess,

  [Types.GET_MANAGEMENT_TOOLS_FAILURE]: getManagementToolsFailure,
  [Types.GET_MANAGEMENT_TOOLS_REQUEST]: getManagementToolsRequest,
  [Types.GET_MANAGEMENT_TOOLS_SUCCESS]: getManagementToolsSuccess,

  [Types.GET_MEMBERS_FAILURE]: getMembersFailure,
  [Types.GET_MEMBERS_REQUEST]: getMembersRequest,
  [Types.GET_MEMBERS_SUCCESS]: getMembersSuccess,

  [Types.GET_ADMINS_FAILURE]: getAdminsFailure,
  [Types.GET_ADMINS_REQUEST]: getAdminsRequest,
  [Types.GET_ADMINS_SUCCESS]: getAdminsSuccess,

  [Types.GET_EVENT_MODIFICATION_REASONS_FAILURE]:
    getEventModificationReasonsFailure,
  [Types.GET_EVENT_MODIFICATION_REASONS_REQUEST]:
    getEventModificationReasonsRequest,
  [Types.GET_EVENT_MODIFICATION_REASONS_SUCCESS]:
    getEventModificationReasonsSuccess,

  [Types.GET_EVENT_DECLINE_REASONS_FAILURE]:
    getEventDeclineReasonsFailure,
  [Types.GET_EVENT_DECLINE_REASONS_REQUEST]:
    getEventDeclineReasonsRequest,
  [Types.GET_EVENT_DECLINE_REASONS_SUCCESS]:
    getEventDeclineReasonsSuccess,

  [Types.GET_MISC_DATA_FAILURE]: getMiscDataFailure,
  [Types.GET_MISC_DATA_REQUEST]: getMiscDataRequest,
  [Types.GET_MISC_DATA_SUCCESS]: getMiscDataSuccess,

  [Types.GET_PAYMENT_INFO_FAILURE]: getPaymentInfoFailure,
  [Types.GET_PAYMENT_INFO_REQUEST]: getPaymentInfoRequest,
  [Types.GET_PAYMENT_INFO_SUCCESS]: getPaymentInfoSuccess,

  [Types.SEND_SLACK_NOTIFICATION_FAILURE]:
    sendSlackNotificationFailure,
  [Types.SEND_SLACK_NOTIFICATION_REQUEST]:
    sendSlackNotificationRequest,
  [Types.SEND_SLACK_NOTIFICATION_SUCCESS]:
    sendSlackNotificationSuccess,
  [Types.RESET_SLACK_NOTIFICATION_STATE]: resetSlackNotificationState,

  [Types.HIDE_CALENDAR_EXPIRY_MESSAGE]: hideCalendarExpiryMessage,
  [Types.SHOW_CALENDAR_EXPIRY_MESSAGE]: showCalendarExpiryMessage,

  [Types.SET_HAS_PAGE_LOAD_TRANSACTION_ENDED]:
    setHasPageLoadTransactionEnded,

  [UserTypes.LOGOUT]: logout,
};

export default createReducer(INITIAL_STATE, HANDLERS);
