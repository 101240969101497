import { call, put, takeEvery, select } from "redux-saga/effects";
import { AvailabilityCreators, AvailabilityTypes } from "../actions";
import {
  checkDateAvailabilityPrecedence,
  getAvailability,
  setAvailability,
  deleteAvailability,
  updateAvailability,
  getAvailabilitySettings,
  updatePrimarySettings,
  updateEventPublishSettings,
  updateMessageSettings,
  updateEventLocationSettings,
  updateAutoAcceptEvent,
  removeAvailability,
} from "src/web-services";
import { SubmissionError } from "redux-form";

const authTokenSelector = state => state.user.authToken;
const partnerIdSelector = state => state.user.data.partner_id;

export function* requestCheckDateAvailabilityPrecedence(action) {
  try {
    const { date } = action;
    const authToken = yield select(authTokenSelector);
    const partnerId = yield select(partnerIdSelector);

    const response = yield call(
      checkDateAvailabilityPrecedence,
      authToken,
      partnerId,
      date,
    );
    yield put(
      AvailabilityCreators.checkDateAvailabilityPrecedenceSuccess(
        date,
        response.data.is_available,
      ),
    );
  } catch (error) {
    yield put(
      AvailabilityCreators.checkDateAvailabilityPrecedenceFailure(),
    );
  }
}

const addMissingSlots = object => {
  const newAvailability = {};
  const keys = Object.keys(object);
  for (const key of keys) {
    newAvailability[key] = object[key];
    if (!newAvailability[key].slots) {
      newAvailability[key].slots = [];
    }
  }
  return newAvailability;
};

export function* requestGetAvailability(action) {
  try {
    const { startDate, endDate, universityPartnerId } = action;
    const stateSelector = state => state;
    const {
      user: { authToken },
      availability: {
        startDate: prevStartDate,
        endDate: prevEndDate,
      },
    } = yield select(stateSelector);
    const partnerId = yield select(partnerIdSelector);

    const params = {
      start_date: startDate || prevStartDate,
      end_date: endDate || prevEndDate,
    };

    if (universityPartnerId)
      params["university_partner_id"] = universityPartnerId;

    const response = yield call(
      getAvailability,
      authToken,
      partnerId,
      params,
    );
    yield put(
      AvailabilityCreators.getAvailabilitySuccess(
        addMissingSlots(response.data.availabilities),
        params.start_date,
        params.end_date,
      ),
    );
  } catch (error) {
    yield put(AvailabilityCreators.getAvailabilityFailure());
  }
}

const getConflictingError = (obj = {}) => {
  const values = Object.values(obj);
  if (values[0] && values[0].time_from) {
    return { time_from: values[0].time_from };
  }
  return null;
};

export function* requestSetAvailability(action) {
  try {
    const { params, resolve } = action;
    const authToken = yield select(authTokenSelector);
    const partnerId = yield select(partnerIdSelector);

    yield call(setAvailability, authToken, partnerId, params);
    resolve();

    yield put(AvailabilityCreators.getAvailabilityRequest());
    yield put(AvailabilityCreators.setAvailabilitySuccess());
  } catch (error) {
    const { reject } = action;

    if (
      error.response &&
      error.response.status === 409 &&
      error.response.data &&
      error.response.data.conflict_slots &&
      getConflictingError(error.response.data.conflict_slots)
    ) {
      reject(
        new SubmissionError(
          getConflictingError(error.response.data.conflict_slots),
        ),
      );
    } else {
      reject(new SubmissionError({}));
    }
    yield put(AvailabilityCreators.setAvailabilityFailure());
  }
}

export function* requestRemoveAvailability(action) {
  try {
    const { params, resolve } = action;
    const authToken = yield select(authTokenSelector);

    yield call(removeAvailability, authToken, params);
    resolve();

    yield put(AvailabilityCreators.getAvailabilityRequest());
    yield put(AvailabilityCreators.removeAvailabilitySuccess());
  } catch (error) {
    const { reject } = action;

    if (
      error.response &&
      error.response.status === 409 &&
      error.response.data &&
      error.response.data.conflict_slots &&
      getConflictingError(error.response.data.conflict_slots)
    ) {
      reject(
        new SubmissionError(
          getConflictingError(error.response.data.conflict_slots),
        ),
      );
    } else {
      reject(new SubmissionError({}));
    }
    yield put(AvailabilityCreators.removeAvailabilityFailure());
  }
}

export function* requestDeleteAvailability(action) {
  try {
    const { params, resolve } = action;
    const authToken = yield select(authTokenSelector);
    const partnerId = yield select(partnerIdSelector);

    yield call(deleteAvailability, authToken, partnerId, params);
    resolve();

    yield put(AvailabilityCreators.getAvailabilityRequest());
    yield put(
      AvailabilityCreators.deleteAvailabilitySuccess(
        params.availability_start_date,
        params.slot_id,
        params.availability_id,
      ),
    );
  } catch (error) {
    yield put(AvailabilityCreators.deleteAvailabilityFailure());
  }
}

export function* requestUpdateAvailability(action) {
  try {
    const { params, resolve } = action;
    const authToken = yield select(authTokenSelector);
    const partnerId = yield select(partnerIdSelector);

    yield call(updateAvailability, authToken, partnerId, params);
    resolve();

    yield put(AvailabilityCreators.getAvailabilityRequest());
    yield put(AvailabilityCreators.updateAvailabilitySuccess());
  } catch (error) {
    const { reject } = action;

    if (
      error.response &&
      error.response.status === 409 &&
      error.response.data &&
      error.response.data.conflict_slots &&
      getConflictingError(error.response.data.conflict_slots)
    ) {
      reject(
        new SubmissionError(
          getConflictingError(error.response.data.conflict_slots),
        ),
      );
    } else {
      reject(new SubmissionError({}));
    }
    yield put(AvailabilityCreators.updateAvailabilityFailure());
  }
}

export function* requestCreateBulkAvailability(action) {
  try {
    const { params, resolve } = action;
    const authToken = yield select(authTokenSelector);
    const partnerId = yield select(partnerIdSelector);

    const paramsData = {
      availability: {
        availability_start_date: params.availability_start_date,
        availability_end_date: params.availability_end_date,
        is_bulk_upload: true,
        slots: {
          days: params.days,
        },
      },
    };

    yield call(setAvailability, authToken, partnerId, paramsData);
    resolve();

    yield put(AvailabilityCreators.getAvailabilityRequest());
    yield put(AvailabilityCreators.createBulkAvailabilitySuccess());
  } catch (error) {
    const { reject } = action;
    if (error.response && error.response.status === 409) {
      const errors = { ...error.response.data.conflict_slots };
      reject(new SubmissionError(errors));
    } else {
      reject(new SubmissionError({}));
    }
    yield put(AvailabilityCreators.createBulkAvailabilityFailure());
  }
}

export function* requestGetAvailabilitySettings() {
  try {
    const authToken = yield select(authTokenSelector);
    const partnerId = yield select(partnerIdSelector);

    const response = yield call(
      getAvailabilitySettings,
      authToken,
      partnerId,
    );
    const { school, primary_contact, member_contacts } =
      response.data;
    yield put(
      AvailabilityCreators.getAvailabilitySettingsSuccess(
        school,
        primary_contact,
        member_contacts,
      ),
    );
  } catch (error) {
    yield put(AvailabilityCreators.getAvailabilitySettingsFailure());
  }
}

export function* requestUpdatePrimarySettings(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const partnerId = yield select(partnerIdSelector);

    const response = yield call(
      updatePrimarySettings,
      authToken,
      partnerId,
      params,
    );
    const { school, primary_contact, member_contacts } =
      response.data;
    yield put(
      AvailabilityCreators.updatePrimarySettingsSuccess(
        school,
        primary_contact,
        member_contacts,
      ),
    );
  } catch (error) {
    yield put(AvailabilityCreators.updatePrimarySettingsFailure());
  }
}

export function* requestUpdateEventPublishSettings(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const partnerId = yield select(partnerIdSelector);

    const response = yield call(
      updateEventPublishSettings,
      authToken,
      partnerId,
      params,
    );
    const { school } = response.data;
    yield put(
      AvailabilityCreators.updateEventPublishSettingsSuccess(school),
    );
  } catch (error) {
    yield put(
      AvailabilityCreators.updateEventPublishSettingsFailure(),
    );
  }
}

export function* requestUpdateMessageSettings(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const partnerId = yield select(partnerIdSelector);

    const response = yield call(
      updateMessageSettings,
      authToken,
      partnerId,
      params,
    );
    const { school } = response.data;
    yield put(
      AvailabilityCreators.updateMessageSettingsSuccess(school),
    );
  } catch (error) {
    yield put(AvailabilityCreators.updateMessageSettingsFailure());
  }
}

export function* requestUpdateEventLocationSettings(action) {
  try {
    const { params } = action;
    const authToken = yield select(authTokenSelector);
    const partnerId = yield select(partnerIdSelector);

    const response = yield call(
      updateEventLocationSettings,
      authToken,
      partnerId,
      params,
    );
    const { school } = response.data;
    yield put(
      AvailabilityCreators.updateEventLocationSettingsSuccess(school),
    );
  } catch (error) {
    yield put(
      AvailabilityCreators.updateEventLocationSettingsFailure(),
    );
  }
}

export function* requestUpdateAutoAcceptEvent(action) {
  try {
    const { autoAccept } = action;
    const authToken = yield select(authTokenSelector);
    const partnerId = yield select(partnerIdSelector);

    const response = yield call(
      updateAutoAcceptEvent,
      authToken,
      partnerId,
      autoAccept,
    );
    const { school } = response.data;
    yield put(
      AvailabilityCreators.updateAutoAcceptEventSuccess(school),
    );
  } catch (error) {
    yield put(AvailabilityCreators.updateAutoAcceptEventFailure());
  }
}

export function* watchAvailabilityRequests() {
  yield takeEvery(
    AvailabilityTypes.CHECK_DATE_AVAILABILITY_PRECEDENCE_REQUEST,
    requestCheckDateAvailabilityPrecedence,
  );
  yield takeEvery(
    AvailabilityTypes.GET_AVAILABILITY_REQUEST,
    requestGetAvailability,
  );
  yield takeEvery(
    AvailabilityTypes.SET_AVAILABILITY_REQUEST,
    requestSetAvailability,
  );
  yield takeEvery(
    AvailabilityTypes.CREATE_BULK_AVAILABILITY_REQUEST,
    requestCreateBulkAvailability,
  );
  yield takeEvery(
    AvailabilityTypes.GET_AVAILABILITY_SETTINGS_REQUEST,
    requestGetAvailabilitySettings,
  );
  yield takeEvery(
    AvailabilityTypes.UPDATE_PRIMARY_SETTINGS_REQUEST,
    requestUpdatePrimarySettings,
  );
  yield takeEvery(
    AvailabilityTypes.UPDATE_EVENT_PUBLISH_SETTINGS_REQUEST,
    requestUpdateEventPublishSettings,
  );
  yield takeEvery(
    AvailabilityTypes.UPDATE_MESSAGE_SETTINGS_REQUEST,
    requestUpdateMessageSettings,
  );
  yield takeEvery(
    AvailabilityTypes.UPDATE_EVENT_LOCATION_SETTINGS_REQUEST,
    requestUpdateEventLocationSettings,
  );
  yield takeEvery(
    AvailabilityTypes.UPDATE_AUTO_ACCEPT_EVENT_REQUEST,
    requestUpdateAutoAcceptEvent,
  );
  yield takeEvery(
    AvailabilityTypes.DELETE_AVAILABILITY_REQUEST,
    requestDeleteAvailability,
  );
  yield takeEvery(
    AvailabilityTypes.UPDATE_AVAILABILITY_REQUEST,
    requestUpdateAvailability,
  );
  yield takeEvery(
    AvailabilityTypes.REMOVE_AVAILABILITY_REQUEST,
    requestRemoveAvailability,
  );
}
