import zhCN from "./zh.json";
import mySessions from "./webinars/my-sessions.json";
import webinarAbout from "./webinars/about.json";
import webinarFaq from "./webinars/faq.json";
import directApply from "./direct-apply";

const zh = {
  ...zhCN,
  ...directApply,
  ...mySessions,
  ...webinarAbout,
  ...webinarFaq,
};

export { zh };
