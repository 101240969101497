import { API } from "../helpers";
import { SERVER_URL } from "../config";

export function getApplicationFunnelSummary(authToken, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/dashboards/summary`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getSplitsConnectionInsights(authToken, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/dashboards/connection_insights`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getSplitsListing(authToken, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/dashboards/listing`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getSplitsProfile(authToken, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/dashboards/university_profile_views`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getSplitsEvents(authToken, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/dashboards/event_connections`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getSplitsVisits(authToken, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/dashboards/country_wise_visits`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getRecommendedSchoolsToVisit(authToken, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/dashboards/recommended_schools`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getDashboardEvents(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/dashboards/events`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getNewConnections(authToken, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/dashboards/new_connections`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getNewActivities(authToken, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/dashboards/new_activities`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export const getDashboardRegions = authToken => {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/dashboards/regions`,
    headers: {
      authentication_token: authToken,
    },
  });
};

export const getDashboardTrendChartSummary = (authToken, data) => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/dashboards/chart_summary`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
};

export const getAddedTrendChartSummary = (authToken, data) => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/dashboards/added_list_chart_summary`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
};

export const getApplicantsTrendChartSummary = (authToken, data) => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/dashboards/applicant_chart_summary`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
};

export const getDashboardTrendChart = (authToken, data) => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/dashboards/trend_chart`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
};

export const getAddedTrendChart = (authToken, data) => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/dashboards/added_list_trend_chart`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
};

export const getInsightsTrendChart = (authToken, data) => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/dashboards/added_list_dimentions`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
};

export const getApplicantsTrendChart = (authToken, data) => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/dashboards/applicant_trend_chart`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
};

export const getDashboardCountryWiseConnections = (
  authToken,
  data,
) => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/dashboards/country_wise_connections`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
};

export const contactUem = (authToken, id, data) => {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/users/${id}/contact_uem`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
};

export const getUserConfiguartion = authToken => {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/user_configurations`,
    headers: {
      authentication_token: authToken,
    },
  });
};

export const updateUserConfiguartion = (
  authToken,
  isDashboardOnboardingComplete,
) => {
  const data = {
    is_dashboard_onboarding_complete: isDashboardOnboardingComplete,
  };
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/user_configurations`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
};
