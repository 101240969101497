const getApplicationsFilterDetails = (
  countries,
  schools,
  programs,
  courses,
  status,
  feeStatuses,
  results,
  t,
) => {
  const filterOptions = [
    {
      id: "location",
      title: t(
        "direct_apply.applications.table.filters.student_location",
      ),
      icon: ["fas", "location-arrow"],
      filterTypes: [
        {
          id: "country",
          text: t("direct_apply.applications.table.filters.country"),
          isMulti: true,
          isSearchable: true,
          options: countries.map(country => ({
            id: country.id,
            text: country.name,
          })),
        },
        // { id: "city", text: "City", isMulti: true, isSearchable: true, options: [] },
      ],
    },
    {
      id: "school",
      title: t(
        "direct_apply.applications.table.filters.school_education",
      ),
      filterTypes: [
        {
          id: "fee_status",
          text: "Fee Status",
          isMulti: true,
          icon: ["fas", "hands-holding-dollar"],
          options: feeStatuses.map(s => ({ id: s.id, text: s.name })),
        },
        {
          id: "school",
          text: t(
            "direct_apply.applications.table.filters.school_name",
          ),
          icon: ["fas", "school"],
          isMulti: true,
          options: schools.map(school => ({
            id: school.id,
            text: school.name,
          })),
        },
        {
          id: "program",
          text: t("direct_apply.applications.table.filters.program"),
          icon: ["fas", "file-alt"],
          isMulti: true,
          options: programs.map(program => ({
            id: program.id,
            text: program.name,
          })),
        },
        {
          id: "course",
          text: t(
            "direct_apply.applications.table.filters.preferred_course_major",
          ),
          icon: ["fas", "file"],
          isMulti: true,
          options: courses.map(course => ({
            id: course.id,
            text: course.name,
          })),
        },
      ],
    },
    {
      id: "application_attributes",
      title: t(
        "direct_apply.applications.table.filters.application_attributes",
      ),
      filterTypes: [
        {
          id: "application_date",
          text: t(
            "direct_apply.applications.table.filters.application_date",
          ),
          icon: ["fas", "calendar-check"],
          isMulti: true,
          options: [],
          type: "date-range",
        },
        {
          id: "document_upload_date",
          text: t(
            "direct_apply.applications.table.filters.document_upload_date",
          ),
          icon: ["fas", "calendar-check"],
          isMulti: true,
          options: [],
          type: "date-range",
        },
        {
          id: "status",
          text: t("direct_apply.applications.table.filters.status"),
          icon: ["fas", "certificate"],
          isMulti: true,
          options: status.map(s => ({ id: s.id, text: s.name })),
        },
        {
          id: "results",
          text: t("direct_apply.applications.table.filters.results"),
          icon: ["fas", "user-check"],
          isMulti: true,
          options: results.map(result => ({
            id: result.id,
            text: result.name,
          })),
        },
      ],
    },
  ];

  return filterOptions;
};

const applicationsSortableColumns = {
  students: "first_name",
  program: "program_name",
  submitted_at: "submitted_at",
  start_date: "partner_programs.start_date",
  fee_waived: "fee_waived",
  status: "status",
  result: "result",
  choice: "choice",
  document_upload_date: "student_application_documents.created_at",
  fee_status: "fee_status",
};

export { getApplicationsFilterDetails, applicationsSortableColumns };
