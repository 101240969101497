import { API } from "../helpers";
import { SERVER_URL } from "../config";

export function getAvailability(authToken, schoolId, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/schools/${schoolId}/availability`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function setAvailability(authToken, schoolId, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/schools/${schoolId}/set_availability`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function removeAvailability(authToken, data) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/availabilities/bulk_delete`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function deleteAvailability(authToken, schoolId, data) {
  return API.request({
    method: "delete",
    url: `${SERVER_URL}/api/v1/partners/availabilities/${schoolId}`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function updateAvailability(authToken, schoolId, data) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/availabilities/${schoolId}`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function setDummyAvailability(authToken, schoolId, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/schools/${schoolId}/dummy_set_availability`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getAvailabilitySettings(authToken, schoolId) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/schools/${schoolId}/availability_settings`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function updatePrimarySettings(authToken, schoolId, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/schools/${schoolId}/primary_contact_settings`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function updateEventPublishSettings(
  authToken,
  schoolId,
  data,
) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/schools/${schoolId}/event_publish_settings`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function updateMessageSettings(authToken, schoolId, data) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/schools/${schoolId}/message_settings`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function updateEventLocationSettings(
  authToken,
  schoolId,
  data,
) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/schools/${schoolId}/event_location`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function updateAutoAcceptEvent(
  authToken,
  schoolId,
  autoAccept,
) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/schools/${schoolId}/auto_event_accept?is_event_auto_accept=${autoAccept}`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function checkDateAvailabilityPrecedence(
  authToken,
  schoolId,
  date,
) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/schools/${schoolId}/slot_precedence?date=${date}`,
    headers: {
      authentication_token: authToken,
    },
  });
}
