import { API } from "../helpers";
import { SERVER_URL } from "../config";
import qs from "qs";

export function getAppointmentAvailabilities(params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/availabilities/unregistered_availabilities`,
    params,
    paramsSerializer: params => {
      return qs.stringify(params, { encode: false });
    },
  });
}

export function getZoomMeetingLink(authToken, params) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/zoom/meeting`,
    headers: {
      authentication_token: authToken,
    },
    data: params
  });
}

export function connectZoom(authToken, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/zoom/connect`,
    headers: {
      authentication_token: authToken,
    }
  });
}

export function deleteZoomLink(authToken, params) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/zoom/delete_meeting`,
    headers: {
      authentication_token: authToken,
    },
    data: params
  });
}
