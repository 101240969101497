import { API } from "../helpers";
import { SERVER_URL } from "../config";

export function getSchools(authToken, universityId, data) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/universities/${universityId}/schools_list`,
    headers: {
      authentication_token: authToken,
    },
    data,
  });
}

export function getSchool(authToken, partnerId) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/schools/${partnerId}/detail_info`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function getAvailabilities(authToken, universityId, params) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/availabilities`,
    headers: {
      authentication_token: authToken,
    },
    params,
  });
}

export function setStateSchool(authToken, userId, data) {
  return API.request({
    method: "put",
    url: `${SERVER_URL}/api/v1/partners/users/${userId}`,
    headers: {
      "Content-Type": "multipart/form-data",
      authentication_token: authToken,
    },
    data,
  });
}

export function getPendingFeedbacks(authToken) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/events/pending_feedbacks`,
    headers: {
      authentication_token: authToken,
    },
  });
}

export function addFeedback(authToken, data, eventId) {
  return API.request({
    method: "post",
    url: `${SERVER_URL}/api/v1/partners/events/${eventId}/create_feedback`,
    headers: {
      "Content-Type": "multipart/form-data",
      authentication_token: authToken,
    },
    data,
  });
}

export function getFeedbacks(authToken, schoolId, page) {
  return API.request({
    method: "get",
    url: `${SERVER_URL}/api/v1/partners/schools/${schoolId}/feedbacks?page_no=${page}&per_page=10`,
    headers: {
      authentication_token: authToken,
    },
  });
}
